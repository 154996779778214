import React, { createRef, useEffect } from "react";
import styled from "styled-components";
import TextareaAutosize from "react-textarea-autosize";
import PlayButton from "../song/PlayButton";
import { useProjectPhrase, useSong } from "../context/project/ProjectState";
import { useSongAudio } from "../context/SongAudio";
import AudioSamplePlayer, { usePhrasePlayer } from "../context/AudioSamplePlayer";
import useSound from "use-sound";

const S = {
    Page: styled.div`
        width: 100%;
        background: linear-gradient(156.44deg, #2725AA 6.53%, #8149E8 80.2%);
        position: relative;
        flex-grow: 1;

        display: flex;
        justify-content: start;
        align-items: stretch;
    `,
    PhraseList: styled.div`
        //width: 45px;
        //height: 45px;
        //flex-grow: 1;

        //background: white;
        //border-radius: 30px;

        display: flex;
        flex-direction: column;

        align-items: stretch;
        justify-content: stretch;

        user-select: unset;

        .section-title {

        }

    `,
    PhraseRow: styled.div`
        min-height: 58px;
        flex-grow: 1;
        //background: orange;
        margin-bottom: 18px;

        display: flex;
        flex-direction: row;
        //align-items: center;
        justify-content: stretch;

        .play-button {
            height: 36px;
            width: 36px;
            margin: auto 10px auto auto;
        }
    `,

    PhraseTextInputContainer: styled.div`
        //height: 58px;
        //width: 486px;
        //left: 71px;
        //top: 66px;
        flex-grow: 1;
        border-radius: 15px;
        background: #EDEDED;
        display: flex;
        justify-content: stretch;
        align-items: stretch;
        border: 3px solid ${props => props.isActive ? props.color : "none"};
        border-left: 6px solid ${props => props.color};

        &:focus-within {
            border: 2px solid ${(props) => props.color};
            border-left: 6px solid ${(props) => props.color};
        }

        .phrase-text {

            width: 100%;
            margin: 16px 0 0 14px;
            border: none;
            background: none;

            /* Dark Navy */
            color: #06093A;

            font-family: Avenir, serif;
            font-style: normal;
            font-weight: 800;
            font-size: 18px;
            line-height: 25px;
            /* identical to box height, or 139% */

            &:focus {
                border: none;
                outline: none;
            }
        }
    `,

    PhraseTextInput: styled.input`
        flex-grow: 1;
    `

};


const PhraseRow = (props) => {
    // get this phrase - or the master phrase if syncChorus has been enabled
    const sectionIndex = props.syncChorus ? props.masterChorusSectionIndex : props.section_index;
    const [phrase, setPhrase] = useProjectPhrase(sectionIndex, props.phrase_index);
    const { togglePlayPause, playing: isPlaying } = usePhrasePlayer(props.section_index, props.phrase_index);

    // get the audio of the phrase
    const song = useSong();
    const songURL = `${song.media_url}${props.section_index + 1}_${props.phrase_index + 1}.mp3`;
    // console.log("Phrase song:", songURL)
    const [playSound, { stop: stopSound, isPlaying: soundIsPlaying }] = useSound(songURL);
    const disabledTextArea = props.editMode === "record" ? true : false;
    const isActive = props.activeSection[1] === (props.phrase_index) && props.activeSection[0] === props.section_index && props.editMode == "record";
    const ref = React.createRef(null);
    // toggle the playback when the state determines this is the only phrase playing
    useEffect(() => {
        if (isActive)
            ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
        if (isPlaying) {
            playSound();
        } else {
            stopSound();
        }
    }, [isPlaying, isActive]);

    // stop any playing sounds on exit
    useEffect(() => {
        return () => {
            stopSound();
        };
    }, []);

    // get the color for this phrase
    const color = props.colors ? props.colors[props.phrase_index % props.colors.length] : "0xffffff";

    return (
        <S.PhraseRow className={`phrase-row ${props.className}`} ref={ref}>
            {((!props.isRecording) && (!disabledTextArea)) && <PlayButton className={"play-button"} onClick={togglePlayPause} playing={isPlaying && soundIsPlaying} />}

            <S.PhraseTextInputContainer
                className={"phrase-edit-container"}
                color={color}
                isActive={isActive}
            >

                <TextareaAutosize
                    placeholder={(!disabledTextArea) && `Type here to write phrase ${props.phrase_index + 1}`}
                    minRows={1}
                    // maxRows={6}
                    // defaultValue=""
                    className={"phrase-text"}
                    value={phrase}
                    readOnly={props.syncChorus}
                    onChange={(event) => {
                        if (!props.syncChorus)
                            setPhrase(event.target.value);
                    }}
                    style={{ resize: "none" }}
                    disabled={disabledTextArea}
                />

            </S.PhraseTextInputContainer>

        </S.PhraseRow>
    );
};

const PhraseList = (props) => {
    if (!props.section) return null;

    return (
        <S.PhraseList className={"phrase-list-container"}>
            {new Array(props.section.phrase_count).fill(undefined).map((value, i) => {
                return (
                    <PhraseRow
                        key={i}
                        phrase_index={i}
                        section_index={props.section_index}
                        color={props.colors[i]}
                        colors={props.colors}
                        syncChorus={props.syncChorus}
                        masterChorusSectionIndex={props.masterChorusSectionIndex}
                        activeSection={props.activeSection}
                        isRecording={props.isRecording}
                        editMode={props.editMode}
                    />
                );
            })}
        </S.PhraseList>
    );
};

export default PhraseList;