import React, {useEffect, useState} from "react";
import {useProject, useProjectSection, useSong} from "./project/ProjectState";
import useSound from "use-sound";
import {useAudioPlayer, useAudioPosition} from "react-use-audio-player";
import {mediaURL} from "../../api";

// export function useSong() {
//     console.log("GET FILES");
//     console.log(song);
// }
//
// useSongMediaFiles(project.base_song);
const Context = React.createContext();

const SongAudioProvider = (props) => {
    let context = {}
    return (
        <Context.Provider value={context}>
            {props.children}
        </Context.Provider>
    );
};

const songWaveFile = (song) => {
    if (song) {
        return song.wav;
    }
    return null;
}

export const beatsToSecs = (bpm, beats) => {
    const beatsPerSecond = (bpm / 60.0);
    return beats * beatsPerSecond;
}

export const getSongFileURL = (song, file) => {
    const data = song ? song.data || {} : {};
    const base = mediaURL("songs/")
    // const base = "https://muzology-media.s3.amazonaws.com/media.muzology.com/ace/songs/"
    // const base = "https://media.muzology.com/ace/songs/"
    const full = file ? `${song ? song.media_url : base}${file}` : file
    // console.log("URL:", file, full)
    return full;
}

// return audio playback for the current project.base_song
export function useSongAudio(sectionIndex = null, onEnd = null, isLooping= true) {
    const song = useSong();
    // const section = useProjectSection(sectionIndex);
    const [performLooping, setPerformLooping] = useState(isLooping);

    // const file = "drake_one_dance_vocal_ref.wav.mp3"
    const project = useProject();
    const song_data = song ? song.data || {} : {};
    // const audioFileMusic = song_data.wav ? `${song ? song.media_url : base}${song_data.wav}` : song_data.wav
    // const audioFileVoice = song_data.reference_wav ? `${song ? song.media_url : base}${song_data.reference_wav}` : null;
    const audioFileMusic = getSongFileURL(song, song_data.wav);
    // const [play, { stop }] = useSound(full);

    const {togglePlayPause, ready, loading, playing, play, pause, stop, ended} = useAudioPlayer({
        src: audioFileMusic,
        format: "mp3",
        autoplay: false
    });

    const {seek, duration, position} = useAudioPosition({highRefreshRate: true})

    const [loop, setLoop] = useState(null);

    React.useEffect( () => {
        if( ended ) {
            if( onEnd )
                onEnd();
        }
    }, [ended]);


    // stop audio when this goes away
    useEffect(() => {
        return () => stop();
    }, [])

    // loop audio when a section is requested
    React.useEffect(() => {
        // console.log("Looping with section", sectionIndex);
        if( performLooping ) {
            if (sectionIndex === null) {
                setLoop(null);
            } else if (song_data) {
                const sections = song_data.song_sections || {};
                const section = sections[sectionIndex] || null;
                // console.log(sections)
                if (section) {
                    // console.log("Looping: ", section)

                    // get the start and length
                    const beatsPerMeasure = 4.0;
                    const measure_start = section['song_section_measure_start'];
                    const beats_long = section['song_section_length'] * beatsPerMeasure;
                    if (!measure_start || !beats_long) {
                        console.log("Looping section cant find start and length:", section);
                        return;
                    }


                    // if (song && songSections && props.musicSection < songSections.length) {
                    // const section = songSections.filter(props.musicSection];
                    // console.log('loop song', song);

                    // get the song bpm
                    const bpm = song_data['BPM'] || 100
                    const seconds_per_beat = 1.0 / (bpm / 60.0);
                    // console.log("BPM:", bpm, seconds_per_beat);
                    // calculate the start and time in seconds
                    const beat_start = (measure_start - 1) * beatsPerMeasure; // we start at 0
                    const start = beat_start * seconds_per_beat;
                    const length = beats_long * seconds_per_beat;
                    // set the looping
                    setLoop({ start: start, end: start + length });
                    // seek(start);
                    // console.log("Looping section from ", start, " length: ", length, "beats:", beat_start, beats_long, "bpm:", bpm);
                    // console.log("playing section", section);
                } else {
                    setLoop(null);
                }
            }
        } else {
            setLoop(null);
        }

    }, [sectionIndex, song_data, performLooping])

    // perform loop logic
    React.useEffect(() => {
        if (performLooping && playing && loop && (position > loop.end || position < loop.start)) {
            // console.log("Loop start: ", loop.start, position, loop.end);
            seek(loop.start);
        }
    }, [position, loop, playing, performLooping]);

    return {
        togglePlayPause,
        duration,
        play,
        pause,
        isPlaying: playing
    }
}

export default SongAudioProvider;
