import React, {useState} from 'react';
import useSWR from "swr/esm";
import { apiFetcher, apiFetcherAnonymous, apiURL, mediaURL, onErrorRetry } from "../../api";
import Banner from "../common/Banner";
import useAxios from "axios-hooks";
import { useDevTool } from "../../lib/DevTool/DevTool";

const CatalogContext = React.createContext();


// "https://muzology-platform-media.s3.amazonaws.com/"

// const ARTIST_CATALOG_URL = "https://muzology-media.s3.amazonaws.com/media.muzology.com/ace/artists/catalog_new.json";
// const ARTIST_CATALOG_URL = "https://media.muzology.com/ace/artists/catalog_new.json";
// const ARTIST_CATALOG_URL = mediaURL("artists/catalog_new.json")
const ARTIST_CATALOG_URL = apiURL("/api/ace/artists/")

// get a song by id
export function useSong(songId) {
    console.log("Use song: ", mediaURL(`songs/${songId}/song.json`));
    const [{data, loading, error}] = useAxios({
        // url: `https://muzology-media.s3.amazonaws.com/media.muzology.com/ace/songs/${songId}/song.json`,
        url: mediaURL(`songs/${songId}/song.json`),
        method: 'GET'
    }, {manual: false, withCredentials: false})
    return {song: data, loading, error}
}


const ArtistCatalogProvider = (props) => {
    const [catalog, setCatalog] = useState(null);
    const {data, error} = useSWR(ARTIST_CATALOG_URL);
    useDevTool('Artist Catalog', data);
    // console.log(`ArtistCatalogProvider '${ARTIST_CATALOG_URL}'`);
    // console.log(`ArtistCatalogProvider '${process.env.REACT_APP_MUZOLOGY_MEDIA_URL}'`);

    // console.log("use artists", artistId, artists);
    React.useEffect(() => {
        // console.log("ARTISTS CATALOG FETCHED", data);
        if (data) {
            setCatalog(data);
        }
    }, [data]);

    let errorMessage = null;
    if (error) {
        console.log("Error downloading catalog: ", error.message)
        console.error(error);
        errorMessage = error.message
    }

    return (
        <CatalogContext.Provider value={catalog}>
            {errorMessage && <Banner>There was an error loading the artist catalog: {errorMessage}</Banner>}
            {props.children}
        </CatalogContext.Provider>
    );
};

export function useArtistCatalog() {
    return React.useContext(CatalogContext);
}

export function useArtist(artistId, key = 'id') {
    const artists = useArtistCatalog();
    const [artist, setArtist] = useState(null);
    // console.log("artist", key, artistId, artists);

    // console.log("use artists", artistId, artists);
    React.useEffect(() => {
        // console.log("Looking for", key, artistId);
        const art = artists ? artists.find(data => data[key] === artistId) : null;
        setArtist(art);
        // console.log("ARTIST", art);
    }, [artistId, key, artists]);

    // console.log("Using artist: ", artist);
    return artist;
}


export default ArtistCatalogProvider;

