import React from "react";
import tw, { css } from "twin.macro";
import styled from "styled-components/macro";
import { useDefinitions } from "../../context/TopicCatalog";

const DefinitionContainer = styled.div`
    ul {
        list-style: outside;
        //list-style-position: inside;
        margin-left: 20px;
        //background: red;
    }

`;


const TopicView = ({ topic, ...props }) => {
    const definitions = useDefinitions(topic?.id ?? null);

    return (
        <div tw="bg-white border-radius[0 0 1.5rem 1.5rem] px-5 pb-3 pt-5" {...props}>

            {topic?.heading && (
                <div tw="text-xl mb-6 font-extrabold text-center">{topic?.heading}</div>
            )}

            {topic?.goal && (
                <div tw="text-xl font-extrabold mb-6 text-left">{topic?.goal}</div>
            )}

            {definitions && <div tw="text-xl font-extrabold mb-1 text-left">Definitions:</div>}

            {definitions?.map((definition, index) => (
                <div tw="">
                    <DefinitionContainer tw="text-justify ">
                        {/*<span css={TextGradient}>{definition.title}</span>*/}
                        <span>{definition.title}</span>
                        &nbsp;-&nbsp;
                        <span tw={"whiteSpace[pre-line]"} dangerouslySetInnerHTML={{ __html: definition.text }} />
                    </DefinitionContainer>
                    {/*{(index !== definitions.length - 1) && <div tw="background[#D5D5D5] mt-2.5 mb-5 h-0.5" />}*/}
                </div>
            ))}

            {topic?.subtitle && (
                <div tw="text-xl mb-2 mt-4 font-extrabold text-left">{topic?.subtitle}</div>
            )}

            {topic?.text && (
                <DefinitionContainer tw="text-left" dangerouslySetInnerHTML={{ __html: topic?.text }} />
            )}

        </div>
    );
};

export default TopicView;
