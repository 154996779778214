// A custom hook that builds on useLocation to parse the query string for you.
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import queryString from 'query-string';

export function useQuery() {
    const location = useLocation();
    // console.log('query params = ', location.search);
    // return new URLSearchParams(location.search);
    return useMemo(() => {
        return queryString.parse(location.search);
        // return new URLSearchParams(location.search);
    }, [location]);
}

export default useQuery;
