import React, { useState } from "react";
import tw, { css } from "twin.macro";
import styled from "styled-components/macro";
import AlienIcon from "./alien-icon.png";
import PopIcon from "./pop-icon.png";
import RobotIcon from "./robot-icon.png";
import RockIcon from "./rock-icon.png";

const AudioMenuItem = (props) => {
    return (
        <div
            onClick={props.onClick}
            tw="w-full height[104px] background[#D2CBF7] borderBottom[1px solid #8149E8] cursor-pointer flex flex-col items-center justify-center"
            css={[
                props.selected && css`background-color: white;`,
                css`
                    font-family: 'Avenir', serif;
                    font-style: normal;
                    font-weight: 800;
                `
            ]}
        >
            {props.icon && <img src={props.icon} />}
            {props.title}
        </div>
    );
};

const AudioFilterMenu = (props) => {
    const [selected, setSelected] = useState(0);
    const items = [
        {
            title: "Pop",
            icon: PopIcon
        },
        {
            title: "Rock",
            icon: RockIcon
        },
        {
            title: "Robot",
            icon: RobotIcon
        },
        {
            title: "Alien",
            icon: AlienIcon
        }
    ];

    return (
        <div
            tw={"width[120px] height[100%] background[#D2CBF7] overflow[scroll]"}
        >
            <div tw="fontSize[12px] fontWeight[800] w-full height[40px] background[#E2DDFF] fontSize[12px] textAlign[center] lineHeight[16px]  borderBottom[1px solid #8149E8] flex flex-col items-center justify-center">
                Voice Filters
            </div>
            {items.map((item, index) => (
                <AudioMenuItem
                    key={index}
                    icon={item.icon}
                    title={item.title}
                    selected={index === selected}
                    onClick={() => setSelected(index)}
                />
            ))}

        </div>
    );
};


export default AudioFilterMenu;
