import React, { useEffect, useRef, useState } from "react";
import tw, { css } from "twin.macro";
import "styled-components/macro";
import { Player } from "@remotion/player";
import { LyricsComposition, useProjectPhraseList } from "../../../remotion/LyricsVideo/LyricsComposition";
import { useProject, useProjectPhrase, useSong } from "../../context/project/ProjectState";
import { getSongFileURL } from "../../context/SongAudio";
import DecalEditor from "./DecalEditor";

const VideoEditor = (props) => {
    const project = useProject();

    // get the video style
    const video_style = project.data?.draft?.video_style ?? {};
    const background = video_style?.color || "#E9961A";
    const font = video_style?.font;
    const decals = video_style.decals || [];

    const song = useSong();
    const audioTrackURL = getSongFileURL(song, song.data?.wav);
    const phrases = useProjectPhraseList();
    const songDuration = song.duration;
    const [hideDragging, setHideDragging] = useState(null);
    const playerRef = useRef(null);
    useEffect(() => {
        if (playerRef.current) {
            // setup player

            // seek to first phrase
            playerRef.current.seekTo(20);
            playerRef.current.setVolume(0);

            // attach events
            /*
            // See below for difference between `seeked` and `timeupdate`
            playerRef.current.addEventListener("seeked", (e) => {
                console.log("seeked to " + e.detail.frame);
            });
            playerRef.current.addEventListener("ended", (e) => {
                console.log("ended");
            });
            playerRef.current.addEventListener("play", () => {
                console.log("playing");
            });
            playerRef.current.addEventListener("ratechange", () => {
                console.log("ratechange");
            });
            playerRef.current.addEventListener("pause", () => {
                console.log("pausing");
            });
            playerRef.current.addEventListener("timeupdate", (e) => {
                console.log("time has updated to " + e.detail.frame);
            });
            playerRef.current.addEventListener("error", (e) => {
                console.log("error", e.detail.error);
            });
            */
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div tw="flex flex-grow relative">
            <Player
                ref={playerRef}
                id="video-player"
                component={LyricsComposition}
                durationInFrames={Math.ceil(30 * songDuration)}
                fps={30}
                compositionWidth={1920}
                compositionHeight={1080}
                inputProps={{ background, font, decals, audioTrackURL, phrases, songDuration, hideDragging, setHideDragging }}
                controls={true}
                style={{
                    // background: "black",
                    width: "100%"
                }}
            />

            <DecalEditor decals={decals} setHideDragging={setHideDragging} hideDragging={hideDragging}/>
        </div>
    );
};

export default VideoEditor;
