import React from "react";
import tw from "twin.macro";
import styled from "styled-components/macro";
import { SectionEditorList } from "./SectionEditor";
import { AudioPlayerProvider } from "react-use-audio-player";
import AudioSamplePlayer from "../context/AudioSamplePlayer";

const S = {
    Container: styled.div`
        position: relative;
        background: #ffffff;
        //border-bottom-left-radius: 30px;
        //border-bottom-right-radius: 30px;
        width: 100%;
        flex-grow: 1;
        overflow: hidden;

        display: flex;
        align-items: stretch;
        justify-content: stretch;
        //position: absolute;
        //left: 0;
        //top: 0;
        //bottom: 0;
        //right: 0;

    `
};

const LyricSheet = (props) => {
    return (
        <AudioPlayerProvider>
            <AudioSamplePlayer>
                <S.Container>
                    <SectionEditorList
                        activeSection={props.activeSection}
                        editMode={props.editMode}
                        isRecording={props.isRecording}
                        song={props.song}
                        musicSection={props.musicSection}
                        project={props.project}
                        saveProject={props.saveProject}
                    />
                </S.Container>
                {props.children}
            </AudioSamplePlayer>
        </AudioPlayerProvider>
    );
};

export default LyricSheet;
