import React from "react";
import ReactDOM from 'react-dom';
import {
    BrowserRouter as Router,
    // HashRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
//
// export const exitConfirmation = (message, callback) => {
//     console.log('Confirm exit: ', message);
//     const modal = document.getElementById('modal-wrapper');
//     console.log('modal node', modal);
//
//     const handleClose = (answer) => {
//         ReactDOM.unmountComponentAtNode(modal);
//         callback(answer);
//     };
//
//     ReactDOM.render(<ConfirmExit show={true} text={message} onClose={handleClose} />, modal);
// };
// getUserConfirmation={exitConfirmation}

const RouterProvider = (props) => {
    // // return <AuthContext.Provider value={context}>{props.children}</AuthContext.Provider>;
    const onConfirmation = (message, callback) => {
        console.log("on confirmation", message);
    //     const handleClose = (answer) => {
    //         // ReactDOM.unmountComponentAtNode(modal);
    //         // callback(answer);
    //     };
    //     ReactDOM.render(<ConfirmExit show={true} text={message} onClose={handleClose} />, modal);
    }
    console.log("router provider");
    return (
        <Router getUserConfirmation={onConfirmation}>
            {props.children}
        </Router>
    );
}

export default RouterProvider;