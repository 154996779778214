// recordings
import { useProjectFileUpload } from "./Upload";
import { useContext } from "react";
import { ADD_RECORDING, DispatchContext, getDraftData, REMOVE_RECORDING, REPLACE_RECORDING, StoreContext } from "./ProjectState";

export function useProjectRecordings() {
    const { uploadFile, uploadError, isUploading } = useProjectFileUpload();
    const dispatch = useContext(DispatchContext);
    const store = useContext(StoreContext);
    const draft = getDraftData(store);

    const recordings = draft["recordings"] || [];
    // const store = useContext(StoreContext);

    const addRecording = (recording) => {
        console.log("Add recording:", recording);
        // add the recording to the draft data
        dispatch({ type: ADD_RECORDING, payload: recording });

        // upload the recording to the server (async)
        const { blob } = recording;

        console.log("Uploading song: ", blob);

        uploadFile(blob).then((response) => {
            console.log("upload response: ", response);
            // now replace the existing recording url with this one
            // dispatch({ type: REPLACE_RECORDING, payload: { blob: blob, recording: { ...recording, url: response.url } } });
            return response;
        });
    };

    return [recordings, addRecording];
    // return [recordings, addRecording, (index) => dispatch({ type: REMOVE_RECORDING, payload: index })];

}