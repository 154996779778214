import React from 'react';
import styled from 'styled-components'
import SongTimeline from "../lyrics/timeline/SongTimeline";
import {Songs} from "../fixtures/Songs";
import TimelineSection from "../lyrics/TimelineSection";
import SectionEditor from "../lyrics/SectionEditor";
import MediaProvider from "../context/MediaContext";

const S = {
    Container: styled.div`
      position: absolute;
      width: 100%;
      height: 100%;
      background: black;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: stretch;
    `,
}

export const SampleSong = {
    "id": "A6C14B44-55A7-4D90-A68C-0A3620C42C29",
    "song_name_meta": "abc_song_01",
    "song_display_name": "ABCs Remix",
    "artist": "Muzology",
    "genre": "Hip-Hop",
    "BPM": 100,
    "key": "BbMaj",
    "wav": "muzology_abc_2mx.wav",
    "reference_wav": "muzology_abc_2mx.wav",
    "song_sections": [
        {
            "section_number": 1,
            "song_section_name": "VERSE 1",
            "song_section_sub_text": "Write math lyrics for each phrase in Verse 1.",
            "section_short_name": "V1",
            "isChorus": false,
            "song_section_measure_start": 1,
            "song_section_length": 8,
            "phrase_count": 4
        },
        {
            "section_number": 2,
            "song_section_name": "CHORUS",
            "song_section_sub_text": "Write math lyrics for each phrase in the Chorus.",
            "section_short_name": "CH",
            "isChorus": true,
            "song_section_measure_start": 9,
            "song_section_length": 8,
            "phrase_count": 4
        },
        {
            "section_number": 3,
            "song_section_name": "VERSE 2",
            "song_section_sub_text": "Write math lyrics for each phrase in Verse 2.",

            "section_short_name": "V2",
            "isChorus": false,
            "song_section_measure_start": 17,
            "song_section_length": 8,
            "phrase_count": 4
        },
        {
            "section_number": 4,
            "song_section_name": "CHORUS",
            "song_section_sub_text": "Your chorus is automatically repeated unless you turn the “Same Chorus” switch off.",
            "section_short_name": "CH",
            "isChorus": true,
            "song_section_measure_start": 25,
            "song_section_length": 8,
            "phrase_count": 4
        },
        {
            "section_number": 100,
            "song_section_name": "FULL SONG",
            "section_short_name": "FS",
            "isChorus": false,
        }
    ],
    "track_config": [
        {
            "track_number": 1,
            "sample_instrument_name": "abc_song_02_2mx",
            "MIDI_file": "abc_song_02_2mx.mid",
            "interactive": false
        }
    ]
}


const SongEditorContainer = (props) => {
    return (
            <S.Container>
                <SectionEditor className={"section-lyrics-editor"} song={SampleSong}/>
                <TimelineSection className={"section-timeline"} song={SampleSong}/>
            </S.Container>
    );
};

export default SongEditorContainer;