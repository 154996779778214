import React from "react";
import styled from "styled-components";

const S = {
    IconContainer: styled.span`
        color: white;
    `
};

export const MakeSVGIcon = (svg) => {
    return ({ color, style }) => {
        return <S.IconContainer style={{ ...style, color: color }}>{svg}</S.IconContainer>;
    };
};

export const MusicalNote = (props) => {
    return (
        <S.IconContainer style={{ ...props.style, color: props.color }}>
            <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M5.0225 3.53898V15.1062C4.25044 15.0622 3.47833 15.1918 2.76302 15.4857C0.739327 16.304 -0.43076 18.0907 0.147979 19.4726C0.734283 20.8583 2.84498 21.3122 4.86741 20.4926C6.42207 19.8622 7.3362 18.6644 7.47363 17.5145L7.46481 6.31163L16.3691 4.59811V12.6399C15.5337 12.5635 14.6923 12.692 13.9179 13.0144C11.8942 13.834 10.7254 15.6206 11.3054 17.0025C11.8892 18.387 13.9999 18.8422 16.0236 18.0226C17.5782 17.3909 18.6588 16.1931 18.7988 15.0444L18.8126 0.782715L5.0225 3.53898Z"
                    fill="currentColor"
                />
            </svg>
        </S.IconContainer>
    );
};

export const XIcon = (props) => {
    return (
        <S.IconContainer style={{ ...props.style, color: props.color }}>
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="24" cy="24" r="24" fill="url(#paint0_linear)" />
                <path
                    d="M33.7072 31.0988L16.8839 13.3642C16.1692 12.6108 15.0105 12.6108 14.2958 13.3642C13.581 14.1176 13.581 15.3392 14.2958 16.0926L31.119 33.8272C31.8337 34.5806 32.9924 34.5806 33.7072 33.8272C34.4219 33.0737 34.4219 31.8522 33.7072 31.0988Z"
                    fill="white"
                />
                <path
                    d="M31.1173 13.3641L14.2941 31.0987C13.5794 31.8521 13.5794 33.0737 14.2941 33.8271C15.0088 34.5805 16.1676 34.5805 16.8823 33.8271L33.7055 16.0925C34.4202 15.3391 34.4202 14.1175 33.7055 13.3641C32.9908 12.6107 31.832 12.6107 31.1173 13.3641Z"
                    fill="white"
                />
                <defs>
                    <linearGradient id="paint0_linear" x1="24" y1="0" x2="24" y2="48" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#1D215F" />
                        <stop offset="1" stopColor="#06093A" />
                    </linearGradient>
                </defs>
            </svg>
        </S.IconContainer>
    );
};
export const PlayIcon = (props) => {
    return (
        <S.IconContainer style={{ ...props.style, color: props.color }}>
            <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M21.3699 42.1011C32.8993 42.1011 42.2457 32.7547 42.2457 21.2254C42.2457 9.69601 32.8993 0.349609 21.3699 0.349609C9.84054 0.349609 0.494141 9.69601 0.494141 21.2254C0.494141 32.7547 9.84054 42.1011 21.3699 42.1011Z"
                    fill="white"
                />
                <path
                    d="M27.5324 19.1576C27.8686 19.3876 28.1436 19.6961 28.3337 20.0564C28.5237 20.4166 28.623 20.8178 28.623 21.2251C28.623 21.6325 28.5237 22.0336 28.3337 22.3939C28.1436 22.7542 27.8686 23.0627 27.5324 23.2927L18.6085 29.3984C18.2321 29.6559 17.7927 29.8058 17.3374 29.8319C16.8822 29.858 16.4285 29.7594 16.0252 29.5466C15.6219 29.3339 15.2843 29.0151 15.0488 28.6247C14.8133 28.2342 14.6888 27.7869 14.6888 27.3309L14.6888 15.1194C14.6888 14.6634 14.8133 14.2161 15.0488 13.8256C15.2843 13.4351 15.6219 13.1164 16.0252 12.9036C16.4285 12.6909 16.8822 12.5923 17.3374 12.6184C17.7927 12.6445 18.2321 12.7944 18.6085 13.0519L27.5324 19.1576Z"
                    fill="#06093A"
                />
            </svg>
        </S.IconContainer>
    );
};

export const PauseIcon = (props) => {
    return (
        <S.IconContainer style={{ ...props.style, color: props.color }}>
            <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21 42C32.598 42 42 32.598 42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42Z" fill="white" />
                <path
                    d="M15.662 11.8462H15.5703C14.1087 11.8462 12.9238 13.0297 12.9238 14.4895V28.5875C12.9238 30.0473 14.1087 31.2308 15.5703 31.2308H15.662C17.1236 31.2308 18.3084 30.0473 18.3084 28.5875V14.4895C18.3084 13.0297 17.1236 11.8462 15.662 11.8462Z"
                    fill="#06093A"
                />
                <path
                    d="M26.4296 11.8462H26.3378C24.8763 11.8462 23.6914 13.0297 23.6914 14.4895V28.5875C23.6914 30.0473 24.8763 31.2308 26.3378 31.2308H26.4296C27.8912 31.2308 29.076 30.0473 29.076 28.5875V14.4895C29.076 13.0297 27.8912 11.8462 26.4296 11.8462Z"
                    fill="#06093A"
                />
            </svg>
        </S.IconContainer>
    );
};

export const CloseIcon = MakeSVGIcon(
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M24.5357 22.235L4.94996 1.58821C4.11789 0.711068 2.76883 0.711068 1.93676 1.58821C1.10469 2.46536 1.10469 3.8875 1.93676 4.76464L21.5225 25.4114C22.3546 26.2886 23.7037 26.2886 24.5357 25.4114C25.3678 24.5343 25.3678 23.1121 24.5357 22.235Z"
            fill="currentColor"
        />
        <path
            d="M21.522 1.58825L1.93628 22.235C1.10421 23.1122 1.10421 24.5343 1.93628 25.4115C2.76836 26.2886 4.11741 26.2886 4.94948 25.4115L24.5352 4.76468C25.3673 3.88753 25.3673 2.46539 24.5352 1.58825C23.7032 0.711101 22.3541 0.711101 21.522 1.58825Z"
            fill="currentColor"
        />
    </svg>
);

export const WhiteChevronDown = (props) => {
    return (
        <S.IconContainer style={{ ...props.style, color: props.color }}>
            <svg width="27" height="13" viewBox="0 0 27 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1.93121 6.6944e-07C2.38165 -0.000846154 2.81817 0.150118 3.16501 0.426687L13.4981 8.7378L23.8505 0.723515C24.0477 0.569415 24.2746 0.454336 24.5181 0.384894C24.7617 0.315452 25.0171 0.293016 25.2697 0.318876C25.5223 0.344735 25.7671 0.41838 25.99 0.535577C26.2129 0.652775 26.4096 0.811214 26.5687 1.00179C26.7288 1.19155 26.8484 1.40989 26.9206 1.64427C26.9927 1.87865 27.0161 2.12444 26.9892 2.36752C26.9623 2.6106 26.8858 2.84617 26.764 3.0607C26.6422 3.27522 26.4776 3.46447 26.2795 3.61756L14.7126 12.578C14.3677 12.8508 13.935 13 13.4885 13C13.0419 13 12.6093 12.8508 12.2643 12.578L0.69741 3.30218C0.502202 3.14644 0.340838 2.95518 0.222564 2.73933C0.104289 2.52349 0.0314226 2.28731 0.00814152 2.04433C-0.0151376 1.80135 0.0116263 1.55633 0.086896 1.32332C0.162168 1.09031 0.284465 0.873869 0.446792 0.68641C0.626182 0.473598 0.852338 0.301673 1.109 0.182989C1.36566 0.0643059 1.64646 0.00181263 1.93121 6.6944e-07Z"
                    fill="white"
                />
            </svg>
            
        </S.IconContainer>
    );
};

export const WhiteChevronUp = (props) => {
    return (
        <S.IconContainer style={{ ...props.style, color: props.color }}>
            <svg width="27" height="13" viewBox="0 0 27 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M25.0688 13C24.6184 13.0008 24.1818 12.8499 23.835 12.5733L13.5019 4.2622L3.14952 12.2765C2.95233 12.4306 2.72544 12.5457 2.48188 12.6151C2.23832 12.6845 1.9829 12.707 1.7303 12.6811C1.4777 12.6553 1.2329 12.5816 1.00998 12.4644C0.787051 12.3472 0.590392 12.1888 0.431303 11.9982C0.271168 11.8085 0.151583 11.5901 0.0794212 11.3557C0.00725973 11.1213 -0.016055 10.8756 0.0108173 10.6325C0.0376895 10.3894 0.114218 10.1538 0.236006 9.9393C0.357794 9.72478 0.522438 9.53553 0.720476 9.38244L12.2874 0.422026C12.6323 0.149166 13.065 0 13.5115 0C13.9581 0 14.3907 0.149166 14.7357 0.422026L26.3026 9.69782C26.4978 9.85355 26.6592 10.0448 26.7774 10.2607C26.8957 10.4765 26.9686 10.7127 26.9919 10.9557C27.0151 11.1986 26.9884 11.4437 26.9131 11.6767C26.8378 11.9097 26.7155 12.1261 26.5532 12.3136C26.3738 12.5264 26.1477 12.6983 25.891 12.817C25.6343 12.9357 25.3535 12.9982 25.0688 13Z"
                    fill="white"
                />
            </svg>
            
        </S.IconContainer>
    );
};
