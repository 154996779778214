import React from "react";
import styled from "styled-components/macro";
import { interpolate, useCurrentFrame, useVideoConfig } from "remotion";


const Text = (props) => {

    const videoConfig = useVideoConfig();
    const frame = useCurrentFrame();
    // console.log(videoConfig, frame);

    const { durationInFrames, fps } = videoConfig;

    // Fade out the animation at the end
    const opacity = interpolate(
        frame,
        [0, 10, durationInFrames - 10, durationInFrames],
        [0, 1, 1, 0],
        {
            extrapolateLeft: "clamp",
            extrapolateRight: "clamp"
        }
    );

    return (
        <div style={{ opacity, color: "white", fontFamily: props.font, fontSize: props.fontSize ?? "130px" }}>
            {props.children}
        </div>
    );
};

export default Text;