import React from "react";
import tw, { css } from "twin.macro";
import styled from "styled-components/macro";
import { useProject, useProjectActions } from "../../context/project/ProjectState";

const ColorsPalette = (props) => {
    const project = useProject();
    const { setVideoColor } = useProjectActions();
    let background = project.video_style?.color;

    var colorArray = [
        "#FF6633",
        "#FFB399",
        "#FF33FF",
        "#FFFF99",
        "#00B3E6",
        "#E6B333",
        "#3366E6",
        "#999966",
        "#99FF99",
        "#B34D4D",
        "#6680B3",
        "#66991A",
        "#FF99E6",
        "#CCFF1A",
        "#FF1A66",
        "#CC80CC",
        "#66664D",
        "#991AFF"
    ];

    return (
        <div tw="p-4  h-full grid gap-x-2 gap-y-1 grid-cols-3">
            {colorArray.map((color) => {
                return <div key={color} tw=" shadow-lg rounded cursor-pointer" style={{ backgroundColor: color }} css={[color === background && tw`border-2 border-black`]}onClick={() => setVideoColor(color)} />;
            })}
        </div>
    );
};

export default ColorsPalette;
