import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Background from "../../common/Background";
import magGlass from "./mag-glass.png";
import xButton from "./xButton.png";
import bW from "./bannedWords";
import mathWords from "./MathWordsThatCanBeSearched";
import { css } from "twin.macro";
import "styled-components/macro";

function getRhymes(word) {
    return fetch(`https://api.datamuse.com/words?rel_rhy=${word}`).then((data) => data.json());
}

function getIdeas(word) {
    return fetch(`https://api.datamuse.com/words?rel_jjb=${word}&topics=math`).then((data) => data.json());
}

function getDefinitions(word) {
    return fetch(`https://wordsapiv1.p.rapidapi.com/words/${word}/definitions`, {
        method: "GET",
        headers: { "x-rapidapi-host": "wordsapiv1.p.rapidapi.com", "x-rapidapi-key": "7e5a9402c5mshad5d3a3808d33f2p16dbdajsn8e7e5fcd6b7c" }
    }).then((data) => data.json());
}

const S = {
    Container: styled.div`
        width: 100%;

        color: white;
        font-size: 60px;
        line-height: 70px;
        text-align: center;
        margin-top: 23px;
        display: flex;
        /* flex-direction: row; */
        align-items: center;
        justify-content: center;
    `,

    LogoImage: styled.div`
        color: white;
        font-size: 60px;
        text-align: center;
    `
};

const IdeasBar = (props) => {
    return (
        <S.Container>
            <Tabs mode={props.mode} changeMode={props.changeMode} />
        </S.Container>
    );
};

const B = {
    Container: styled(Background)`
        height: 100%;
        max-height: 500px;
        background: white;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        padding: 3px;
    `
};

const BorderGradient = css`
    color: #313149;
    /* display: inline-block; */
    position: relative;
    z-index: 0;

    &:before {
        content: "";
        position: absolute;
        z-index: -1;
        inset: 0;
        padding: 3px;
        border-radius: 30px 0px 5px 5px;
        background: linear-gradient(93.49deg, #8149e8 3.49%, #887be3 100%);
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
    }
`;

const modes = {
    IDEAS: "ideas",
    RHYMES: "rhymes",
    DEFINE: "define"
};

const isWordValid = (check) => {
    // check if the word is in the whitelist
    const word = check.toLowerCase();
    if (word && word.length > 0) {
        console.log("checking:", word);
        if (mathWords.includes(word)) return true;
        else if (word.charAt(word.length - 1) === "s") {
            const newWord = word.slice(0, -1);
            console.log("checking:", newWord);
            if (mathWords.includes(newWord)) return true;
        }
    }
    return false;
};

const IdeasOverlay = (props) => {
    const [wordToSearch, setWordToSearch] = useState("");
    const [mode, setMode] = useState(modes.IDEAS);
    const [list, setList] = useState([]);
    const [searchClicked, setSearchClicked] = useState(false);
    const [wordDefinitions, setWordDefinitions] = useState([]);
    const [noResults, setNoResults] = useState(false);

    const [resultsMessageIndex, setResultsMessageIndex] = useState(0);

    const resultsMessages = ["No results for that word, try again.", "No results. Search again using a math word. "];

    const searchPlaceHolders = ["Enter A Math Related Word", "Enter a word to find a rhyme", "Enter a word to get a definition"];

    function checkIfBanned(word) {
        let bool = bW.includes(word);

        console.log(word);

        if (!bool) {
            for (let i = 0; i < bW.length; i++) {
                if (word.includes(bW[i].toLowerCase())) {
                    bool = true;
                    break;
                }
            }
        }

        return !bool;
    }

    useEffect(() => {
        setNoResults(false);
        setResultsMessageIndex(0);
        setSearchBarActive(false);

        if ((searchClicked && mode == modes.IDEAS) || (mode == modes.IDEAS && wordToSearch !== "")) {
            console.log(wordToSearch);

            if (isWordValid(wordToSearch)) {
                if (bW.includes(wordToSearch.toLowerCase())) {
                    setWordDefinitions([]);
                    setNoResults(true);
                } else {
                    getIdeas(wordToSearch).then((items) => {
                        let words = items.map((item) => item.word).filter(checkIfBanned);
                        if (words.length != 0) {
                            setList(words);
                        } else {
                            setNoResults(true);
                        }
                    });
                }
            } else {
                setResultsMessageIndex(1);
                setNoResults(true);
            }

            setSearchClicked(false);
        } else if ((searchClicked && mode == modes.RHYMES) || (mode == modes.RHYMES && wordToSearch !== "")) {
            console.log(wordToSearch);

            if (bW.includes(wordToSearch.toLowerCase())) {
                setWordDefinitions([]);
                setNoResults(true);
            } else {
                getRhymes(wordToSearch).then((items) => {
                    let words = items.map((item) => item.word).filter(checkIfBanned);
                    if (words.length != 0) {
                        setList(words);
                    } else {
                        setNoResults(true);
                    }
                });
            }
            setSearchClicked(false);
        } else if ((setSearchClicked && mode == modes.DEFINE) || mode == modes.DEFINE) {
            console.log(wordToSearch.toLowerCase());
            if (bW.includes(wordToSearch.toLowerCase())) {
                setWordDefinitions([]);
                setNoResults(true);
            } else {
                getDefinitions(wordToSearch).then((items) => {
                    console.log(items);
                    if (items.success !== false) {
                        let defs = items.definitions;
                        let COUNT = 0;
                        defs = items.definitions.map((def) => {
                            COUNT += 1;
                            return {
                                count: COUNT,
                                def: def.definition,
                                pos: def.partOfSpeech
                            };
                        });

                        setWordDefinitions(defs);
                    } else {
                        setWordDefinitions([]);
                        setNoResults(true);
                    }
                });
            }

            setSearchClicked(false);
        }
    }, [searchClicked, mode]);

    let handleChange = (event) => {
        setWordToSearch(event.target.value.toLowerCase());
        setWordDefinitions([]);
        setList([]);
        setNoResults(false);
        setSearchBarActive(true);
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            setSearchClicked(true);
        }
    };

    let changeMode = (mode) => {
        setMode(mode);
        setList([]);
    };

    const [searchBarActive, setSearchBarActive] = useState(false);

    return (
        <B.Container className={"container"} css={BorderGradient}>
            <div tw="background[#EDEDED] border-radius[30px 0 0 0]">
                <SearchBarContainter>
                    <TextInput
                        active={searchBarActive}
                        type="text"
                        value={wordToSearch}
                        onKeyDown={handleKeyDown}
                        onChange={handleChange}
                        onClick={() => {
                            setSearchBarActive(true);
                        }}
                        placeholder={mode == modes.IDEAS ? searchPlaceHolders[0] : mode == modes.RHYMES ? searchPlaceHolders[1] : searchPlaceHolders[2]}
                    />
                    <MagGlass
                        active={searchBarActive}
                        onClick={() => {
                            setSearchClicked(true);
                        }}
                    >
                        <img src={magGlass} alt="search" />
                    </MagGlass>
                </SearchBarContainter>
                <IdeasBar id="bar" toggleIdeas={props.toggleIdeas} mode={mode} changeMode={changeMode} setSearchBarActive={setSearchBarActive} />
            </div>

            {mode === modes.DEFINE ? <Define word={wordToSearch} defs={wordDefinitions}></Define> : list.length !== 0 && <WordGrid words={list}></WordGrid>}
            {noResults && <NoResults>{resultsMessages[resultsMessageIndex]}</NoResults>}
        </B.Container>
    );
};

const NoResults = styled.p`
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    margin-top: 20px;
    text-align: center;
    text-transform: uppercase;

    color: black;
`;

const DefineWordDisplay = styled.div`
    background: white;
    width: 90%;
    margin-top: 30px;
    color: black;
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
`;


const Define = (props) => {
    return (
        <div>
            {props.defs.length !== 0 && props.word != "" && (
                <div>
                    <DefineWordDisplay>
                       1.{props.defs[0].def}
                    </DefineWordDisplay>
                </div>
            )}
        </div>
    );
};

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));

    padding: 10px;
    overflow-y: auto;
    max-height: 400px;
`;

const GridItem = styled.div`
    padding: 20px;
    padding-bottom: 40px;
    font-size: 18px;
    text-align: center;
    color: black;
`;

const MagGlass = styled.button`
    & {
        width: 51px;
        height: 40px;
        background: #e9961a;
        border-radius: 0px 999px 999px 0px;
        border: 0;
        color: white;
        box-shadow: none;
        outline: none;
        padding-top: 3px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: ${(props) => (props.active ? "0px 0px 0px 2px #E9961A" : "")};
    }

    &:hover {
        background: linear-gradient(127.87deg, #e9961a 16.09%, #dd6725 83.8%);
        opacity: 75%;
    }

    &:active {
        box-shadow: 0 3px #666;
        width: 50px;
        height: 39px;
    }
`;

const Tab = styled.button`
    width: 33.3%;
    height: 45px;
    left: 479.34px;
    top: 110px;

    border: 0;

    box-shadow: none;
    outline: none;

    background: ${(props) => (props.active ? "#fff" : "none")};
    text-decoration: ${(props) => (props.active ? "underline" : "none")};

    color: #06093a;

    border-radius: 15px 15px 0px 0px;
    margin-right: 12px;
    font-family: Avenir;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
        background: linear-gradient(127.87deg, #e9961a 16.09%, #dd6725 83.8%);
        color: white;
        opacity: 75%;
    }

    &:active {
        /* box-shadow: 0 3px #666; */
        width: 156.66px;
        height: 44px;
        /* padding: 4px; */
    }
`;

const TabContainer = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    width: 100%;
`;

const Tabs = (props) => {
    return (
        <TabContainer>
            <Tab
                active={props.mode == modes.IDEAS}
                onClick={() => {
                    props.changeMode(modes.IDEAS);
                }}
            >
                IDEAS
            </Tab>
            <Tab
                active={props.mode == modes.RHYMES}
                onClick={() => {
                    props.changeMode(modes.RHYMES);
                }}
            >
                RHYMES
            </Tab>
            <Tab
                active={props.mode == modes.DEFINE}
                onClick={() => {
                    props.changeMode(modes.DEFINE);
                }}
            >
                DEFINE
            </Tab>
        </TabContainer>
    );
};

const WordGrid = (props) => {
    return (
        <GridContainer>
            {props.words.map((word) => (
                <GridItem>{word}</GridItem>
            ))}
        </GridContainer>
    );
};

const TextInput = styled.input`
    height: 40px;
    width: 60%;
    border-radius: 999px 0 0 999px;
    box-shadow: none;
    outline: none;
    text-align: center;
    border: none;
    margin-top: 0;
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: black;
    box-shadow: ${(props) => (props.active ? "0px 0px 0px 2px #E9961A" : "")};

    text-transform: uppercase;
`;

const SearchBarContainter = styled.div`
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
`;

export default IdeasOverlay;
