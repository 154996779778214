import React from 'react';
import useAxios from 'axios-hooks'
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {useSong} from "../context/ArtistCatalogProvider";
import {apiURL, parse_cookies} from "../../api";
import ActivityIndicator from "../common/ActivityIndicator";

export function useNewProject(songId, name, topicId) {
    const history = useHistory();

    const options = {manual: true, withCredentials: true, baseURL: process.env.REACT_APP_MUZOLOGY_API_URL + "/"};
    // console.log("useNewProject ", options);

    const [{data, loading, error}, createProject] = useAxios({
        url: '/api/ace/projects/',
        method: 'POST',
        withCredentials: true,
        baseURL: process.env.REACT_APP_MUZOLOGY_API_URL,
        headers: {'X-CSRFToken': parse_cookies().csrftoken}
    }, options);

    // create the project
    React.useEffect(() => {
        createProject({
            data: {
                name: name,
                song_id: songId,
                topic_id: topicId,
                client_date: new Date().toISOString()
            }
        })
    }, []);

    // after the project is created create the editor
    React.useEffect(() => {
        if (data) {
            // console.log("new project data", data);
            if (data && data.id) {
                history.replace(`/edit/${data.id}/`);
            }
        }
    }, [data]);

    // return the loading & error state
    return {project: data, loading, error}
    // return {project:null, loading:null, error:null}
}

// this just creates a new project then redirects to the editor
const CreateProject = () => {
    const {songId, name, topicId} = useParams()
    const {loading, error} = useNewProject(songId, name, topicId);
    if (error)
        return (<ActivityIndicator text={error} style={{background: 'red'}}/>);
    if (loading)
        return (<ActivityIndicator text={"Please wait, setting up project..."}/>);
    return (<ActivityIndicator text={"Please wait..."}/>);
};

export default CreateProject;