export const VERSE_COLOR = '#00B1C9';
export const VERSE_HOVER = 'linear-gradient(127.87deg, #00B1C9 16.09%, #8149E8 83.8%)';
export const CHORUS_COLOR = '#E637A8';
export const CHORUS_HOVER = 'linear-gradient(127.87deg, #E637A8 16.09%, #8149E8 83.8%)';
export const INACTIVE_COLOR = '#AFAFAF';

export const SECTION_COLORS = [
    '#00B1C9',
    '#E637A8',
];
export const PHRASE_COLORS_1 = [
    '#93E1EC',
    '#51CCDD',
    '#00B1C9',
    '#0591A4'
];
export const PHRASE_COLORS_2 = [
    '#F4B4DE',
    '#F168C0',
    '#E637A8',
    '#B7127C'
];


export const INACTIVE_COLORS = [
    '#D5D5D5',
    '#CCCCCC',
    '#AFAFAF',
    '#8B8B8B'
];