import React, { useState, useEffect, useRef, useMemo } from "react";
import tw, { css } from "twin.macro";
import "styled-components/macro";
import { useArtist, useArtistCatalog } from "../../../context/ArtistCatalogProvider";
import ContentPills from "./ContentPills";
import { Button } from "semantic-ui-react";
import CreateProjectModal from "../CreateProjectModal";

const ScrollBarStyle = css`
    ::-webkit-scrollbar {
        display: none;
    }
`;


const SelectionUI = ({ topic, goBackToTopic }) => {
    const artistCatalog = useArtistCatalog();

    // sort the artists
    const artists = useMemo(() => {
        if (artistCatalog)
            return [...artistCatalog].sort((a, b) => a.name.localeCompare(b.name));
        return [];
    }, [artistCatalog]);

    // select the first one
    useEffect(() => {
        if (artists?.length) setItem(artists[0].id);
    }, [artists]);

    // handle selected artist
    const [selectedItem, setItem] = useState(null);
    const [resortedArtist, setResortedArtist] = useState([]);
    const selectedArtist = useMemo(() => {
        return artists?.find(item => item.id === selectedItem);
    }, [selectedItem]);

    //
    // const innerScrollRef = useRef();
    // const [innerScrolling, setInnerScrolling] = useState(false);
    // const onScroll = () => {
    //     if (innerScrollRef.current) {
    //         const { scrollTop } = innerScrollRef.current;
    //         if (scrollTop > 0) {
    //             setInnerScrolling(true);
    //         } else setInnerScrolling(false);
    //     }
    // };

    const artistItems = useMemo(() => {
        return (
            <>
                {artists && artists.map((item) => {
                    let active = item.id === selectedItem;
                    return (
                        <div tw="background[rgba(42, 38, 77, 0.5)] rounded-lg  mb-4 flex items-center height[65px] cursor-pointer hover:background[#632ACC]"
                             onClick={() => {
                                 setItem(item.id);
                                 setSelectedSongId(null);
                             }}
                             css={active && [tw`background[#1D215F] mt-0 relative`]}
                        >
                            <div tw="text-white font-extrabold text-2xl ml-3.5">{item.name}</div>
                        </div>
                    );
                })}
            </>
        );
    }, [artists, selectedItem]);

    const [isPlaying, setIsPlaying] = useState(null);
    const [selectedSongId, setSelectedSongId] = useState(false);
    // const [selectedSong, setSelectedSong] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [songName, setSongName] = useState("");

    const selectedSong = useMemo(() => {
        return selectedArtist?.songs?.find((s) => s.id === selectedSongId);
        // return artists?.find(item => item.id === selectedItem )
    }, [selectedArtist, selectedSongId]);

    // console.log("selected song", selectedSongId, selectedSong);


    const songItems = useMemo(() => {
        const toggleSelection = (songId) => {
            // SHOW: isPlaying === songId ? setIsPlaying(false) : setIsPlaying(songId);
            setIsPlaying(isPlaying === songId ? false : songId);
            setSelectedSongId(songId);
            // if (selectedArtist) {
            //     console.log("Find song from artist", selectedArtist);
            //     const song = selectedArtist?.songs?.find((s) => s.id === songId);
            //     setSelectedSong(song);
            // }
        };

        let items = [];
        if (selectedItem)
            items = artists.find(a => a.id === selectedItem).songs;
        return items.map(item => {
            // console.log("🚀 ~ file: SelectionUI.js ~ line 58 ~ createContent ~ item", item);
            return (
                <ContentPills
                    item={item}
                    isPlaying={isPlaying === item.id}
                    setIsPlaying={setIsPlaying}
                    toggleSelection={toggleSelection}
                    isSelected={selectedSongId === item.id}
                    setIsSelected={() => setSelectedSongId(item.id)}
                    selectSong={() => setIsModalOpen(true)}
                />);
        });
    }, [artists, selectedItem, selectedSongId, isPlaying, setIsPlaying]);

    return (
        <div tw="flex">

            <div
                tw="maxHeight[calc( 100vh - 170px)] overflow-y-auto w-2/5 "
                css={[ScrollBarStyle]}
                // onScroll={onScroll}
                // ref={innerScrollRef}
            >
                {artistItems}
            </div>

            <div tw="w-2/3 relative">
                <div tw="background[#1D215F] height[350px] ml-6 px-9 rounded-xl  pt-5">
                    {songItems}
                </div>
                {/*
                {!innerScrolling && <div tw="absolute left--4 background[#1D215F] top-0 width[50px] height[65px]" />}
                */}
                <div tw="mt-6 mb-3 flex justify-center">
                    <Button
                        tw="hover:opacity[75%] active:opacity[50%] py-2 px-8 bg-none rounded-full uppercase text-sm font-extrabold leading-normal border-2 border-white text-white mr-2"
                        onClick={goBackToTopic}
                    >
                        go back to topic
                    </Button>

                    <Button
                        tw="py-2 px-8 bg-PinkCrayola rounded-full uppercase text-sm font-extrabold leading-normal text-white ml-2"
                        onClick={() => {
                            if (selectedSongId)
                                setIsModalOpen(true);
                        }}
                        css={[
                            (!selectedSongId) && css`opacity: 50%;
                                color: #dddddd;
                                cursor: auto;`
                        ]}
                    >
                        select song
                    </Button>
                </div>
            </div>
            <CreateProjectModal
                topic={topic}
                song={selectedSong}
                show={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            />
        </div>
    );
};

export default SelectionUI;
