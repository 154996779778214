import React from 'react';
import styled from 'styled-components'

const S = {
    Container: styled.div`
        background: linear-gradient(164.79deg, rgba(20, 126, 223, 0.85) 0%, rgba(125, 38, 179, 0.85) 100%); 
    `,

    Text: styled.div`
        font-family: Avenir;
        font-style: normal;
        font-weight: 800;
        font-size: 31px;
        line-height: 42px;
        text-align: center;
        
        color: #FFFFFF;
    `
}

const Placeholder = (props) => {
    return (
        <S.Container>
            <S.Text>
                {props.text}
            </S.Text>
        </S.Container>
    );
};


export default Placeholder;
