import React from 'react';
import styled from 'styled-components'
import Background from "./Background";

const S = {
    Container: styled.div`
      width: 100%;
      height: 100%;
      //position: relative;
      position: absolute;
    `,
    Background: styled.div`
      width: 100%;
      height: 100%;
      //position: relative;
      position: absolute;
      //background: none;
      display: flex;
      justify-content: center;
      align-items: center;

      background: linear-gradient(164.79deg, rgba(20, 126, 223, 0.85) 0%, rgba(125, 38, 179, 0.85) 100%);

      font-size: 30px;
      line-height: 40px;

      font-family: Avenir, serif;
      font-style: normal;
      font-weight: 400;
      color: #FFFFFF;

    `,
}

const ActivityIndicator = (props) => {
    return (
        <S.Container>
            <S.Background style={{...props.style}}>
                {props.children || props.text || "Loading..."}
            </S.Background>
        </S.Container>
    );
};


export default ActivityIndicator;
