import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';

import AudioTimeline from './AudioTimeline';
import VideoTimeline from './VideoTimeline';
import SectionTrack from './SectionTrack';
import {toMMSS} from "../../../lib/utils/time";

const S = {
    Container: styled.div`
      width: 100%;
      position: relative;
      //height: 233px;

      //padding-top: 25px;
      //padding-bottom: 25px;

      flex-basis: 100%;
      flex-grow: 1;

      background: #1f2365;

      display: flex;
      flex-direction: column;
      //align-items: stretch;
      //justify-content: stretch;
    `,
    ControlsContainer: styled.div`
      //margin-top: 20px;
      //margin-bottom: 20px;

      height: 89px;

      //background: red;

      //margin-left:100px;
      //margin-top:25px;
      //margin-right: 50px;

      display: flex;
      flex-direction: column;

      align-items: stretch;
      justify-content: stretch;
    `,

    TrackContainer: styled.div`
      height: 90px;
      margin-top: 10px;

      display: flex;
      flex-grow: 1;
      flex-direction: column;
      align-content: stretch;
      justify-content: center;
    `,

    PositionIndicator: styled.div`
      height: 71px;
      width: 18px;
      //background: red;
      position: absolute;
      margin-left: -9px;
      z-index: 1;
      left: ${props => props.percentageDone};

      display: flex;
      justify-content: center;
      align-items: stretch;

      &:hover {
        background: red;
        background: rgba(255, 255, 255, 0.05);
      }

      &:active {
        background: white;
        background: rgba(255, 255, 255, 0.5);
      }
    `,

    PositionIndicatorLine: styled.div`
      height: 100%;
      width: 4px;
      position: absolute;
      background: white;
    `,
    PositionIndicatorArrow: styled.div`
      width: 0;
      height: 0;
      border-top: 14px solid white;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
    `,
    TextContainer: styled.div`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      color: white;
    `
};

const PositionIndicator = (props) => {
    // const [{ isDragging }, drag] = useDrag({
    //     item: { name: 'Any custom name', type: 'Irrelevant, for now' },
    //     collect: (monitor) => ({
    //         isDragging: monitor.isDragging()
    //     })
    // });

    return (
        <S.PositionIndicator
         percentageDone={props.percentageDone}
            id="test"
            // ref={drag}
            // draggable
            onMouseDown={props.onMouseDown}
            // className='movable-item'
        >
            <S.PositionIndicatorLine/>
            <S.PositionIndicatorArrow/>
        </S.PositionIndicator>
    );
};

export const formatPercent = (value) => {
    return `${value * 100}%`;
    // const num = value * 100;
    // return `${num.toFixed(2)}%`;
}

const SongTimeline = (props) => {
    // const {percentComplete, setPosition, play, pause, isPlaying, song, musicSection, position, duration} = props;
    const [songPosition, setSongPosition] = useState('0%');
    const [mouseIsDown, setMouseIsDown] = useState(false);
    const [wasPlaying, setWasPlaying] = useState(false);
    const parentRef = useRef(null);

    // store the song position when it changes
    useEffect(() => {
        setSongPosition(formatPercent(props.percentComplete));
    }, [props.percentComplete])

    // begin capture mouse events on mouse down, and release them on mouse up
    React.useEffect(() => {
        const EventListenerMode = {capture: true};

        const mouseMoveListener = (e) => {
            if (parentRef.current) {
                // get the parent position and check for width < 0 which causes div by zero error
                const {offsetLeft, offsetWidth} = parentRef.current;
                if (offsetWidth <= 0)
                    return;

                // get the mouse position & clamp it [0..width]
                const mousePosition = Math.min(Math.max(e.clientX - offsetLeft, 0.0), offsetWidth);

                // scale the position against the width and clamp it [0..1]
                const scale = mousePosition / offsetWidth;

                // move the song position
                props.setPosition(scale)

                // setSongPosition(formatPercent(scale));
                // console.log("  mouse moved: ", e.clientX, scale);
            }
        }

        const mouseUpListener = () => {
            // console.log("  mouse up");
            setMouseIsDown(false);
            if (wasPlaying) {
                setWasPlaying(false)
                props.play();
            }
        }

        if( !props.disableSeek ) {
            if (mouseIsDown) {
                // capture mouse events
                // console.log("capture mouse events")
                document.addEventListener('mouseup', mouseUpListener, EventListenerMode);
                document.addEventListener('mousemove', mouseMoveListener, EventListenerMode);
            } else {
                // release mouse events
                // console.log("release mouse events")
                document.removeEventListener('mouseup', mouseUpListener, EventListenerMode);
                document.removeEventListener('mousemove', mouseMoveListener, EventListenerMode);
            }
        }
        return () => {
            // console.log("release mouse events on exit")
            document.removeEventListener('mouseup', mouseUpListener, EventListenerMode);
            document.removeEventListener('mousemove', mouseMoveListener, EventListenerMode);
        }
    }, [mouseIsDown, parentRef, props.disableSeek]);

    return (
        <S.Container ref={parentRef} className={"song-timeline-container"}>

            <S.ControlsContainer
                className={"song-timeline-controls-container"}
            >
                <PositionIndicator
                    percentageDone={songPosition}
                    onMouseDown={(e) => {
                        if( !props.disableSeek ) {
                            // console.log("indicator mouse down");
                            setMouseIsDown(true);
                            setWasPlaying(props.isPlaying)
                            props.pause();
                        }
                    }}
                />

                <S.TrackContainer>

                    <AudioTimeline
                        isRecording={props.isRecording}
                        recordings={props.recordings}
                        songDuration={props.duration}
                        isPlaying={props.isPlaying}
                        songPosition={props.position}
                    />

                    {/* <VideoTimeline/> */}
                    <SectionTrack song={props.song} musicSection={props.musicSection} duration={props.duration} percentComplete={props.percentComplete}/>
                </S.TrackContainer>

                <S.TextContainer>
                    <div>{toMMSS(props.position)}</div>
                    <div>{toMMSS(props.duration)}</div>
                </S.TextContainer>

            </S.ControlsContainer>
        </S.Container>
    );
};

export default SongTimeline;
