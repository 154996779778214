import React, { useState } from 'react';
import styled from 'styled-components';
import PopUp from './PopUp';
import ReactModal from 'react-modal';
import { Link, Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useProject, useProjectCatalog, useProjectEditor, useProjectExitActions } from '../context/project/ProjectState';
import { LoadingAnimation } from '../home/MySongs';

const S = {
    Container: styled.div`
      width: 670px;
      height: 279px;

      background: #1F2365;
      border-radius: 30px;

      color: white;
      font-family: Avenir, serif;
      font-size: 23px;
      font-style: normal;
      font-weight: 800;
      line-height: 31px;
      letter-spacing: 0em;
      text-align: center;
      text-transform: uppercase;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
    `,
    Text: styled.div``,
    Buttons: styled.div``,
    ActionsContainer: styled.form`
      display: flex;
      flex-direction: column;
    `,
    CancelButton: styled.button`
      width: 250px;
      height: 40px;
      border: none;
      //background: white;
      //color: #bbbbbb;
      font-size: 16px;
      font-weight: 800;
      text-transform: uppercase;
      border-radius: 50px;
      cursor: pointer;
      //margin-right: 16px;
      margin: 0 0 10px 0;
      color: #fff;
      background: #00B1C9;

      &:hover {
        background: linear-gradient(127.87deg, #00B1C9 16.09%, #8149E8 83.8%);
      }
    `,
    SaveButton: styled.button`
      width: 250px;
      height: 40px;
      border: none;
      background: #e637a8;
      color: white;
      font-size: 16px;
      font-weight: 800;
      text-transform: uppercase;
      border-radius: 50px;
      //margin-left: 16px;
      cursor: pointer;
      margin: 10px 0;

      &:hover {
        background: linear-gradient(127.87deg, #E637A8 16.09%, #8149E8 83.8%);
      }
    `,
    QuitButton: styled.button`
      width: 250px;
      height: 40px;
      border: none;
      background: #E9961A;
      color: white;
      font-size: 16px;
      font-weight: 800;
      text-transform: uppercase;
      border-radius: 50px;
      //margin-left: 16px;
      cursor: pointer;
      margin: 10px 0 0 0;

      &:hover {
        background: linear-gradient(127.87deg, #E9961A 16.09%, #DD6725 83.8%);
      }
    `

};

const ExitModal = ({ onClose, isModalOpen, hideCancelButton, hideSaveAndQuitButton }) => {
    const project = useProject();
    const [isSaving, setIsSaving] = useState(false);
    const { isProjectModified, isProjectTemporary } = useProjectEditor();
    const { commitDraft, destroyDraft } = useProjectExitActions();
    const [projects, deleteProject, loading, error, addProject] = useProjectCatalog();
    const history = useHistory();

    const saveAndQuit = () => {
        console.log('save and quit');
        setIsSaving(true);
        commitDraft().then(() => {
            addProject(project);
            history.push('/my-songs');
        });
    };

    const quitWithoutSaving = () => {
        console.log('Destroy draft and exit');
        // if( props.onQuitWithoutSaving )
        //     props.onQuitWithoutSaving();
        setIsSaving(true);

        if (project.draft) {
            // this is only a draft - destroy the project
            deleteProject(project.id).then((response) => {
                history.push('/home');
            });
        } else {
            destroyDraft().then(response => {
                history.push('/my-songs');
            });
        }
    };

    const hideQuitWithoutSaving = !isProjectModified();
    return (
        <PopUp onClose={onClose} isModalOpen={isModalOpen}>
            <S.Container>
                {isSaving ?
                    <LoadingAnimation text={'Please wait....'} />
                    :
                    <>
                        <S.Text>Are you sure you want to quit?</S.Text>
                        <S.Buttons>
                            <S.ActionsContainer>
                                {(!hideCancelButton) && <S.CancelButton onClick={onClose}>Keep Creating</S.CancelButton>}
                                {(!hideSaveAndQuitButton) && <S.SaveButton onClick={saveAndQuit}>SAVE & QUIT</S.SaveButton>}
                                {(!hideQuitWithoutSaving) && <S.QuitButton onClick={quitWithoutSaving}>QUIT WITHOUT SAVING</S.QuitButton>}
                            </S.ActionsContainer>
                        </S.Buttons>
                    </>
                }
            </S.Container>
        </PopUp>
    );
};


export default ExitModal;
