import React, { useState, useEffect, useRef } from "react";
import { useLocalStorage } from "react-use";

import styled from "styled-components";
import PlayButton from "../song/PlayButton";
import SongTimeline from "./timeline/SongTimeline";
import { useSongAudio } from "../context/SongAudio";
import { useAudioPosition } from "react-use-audio-player";
import { useProjectSongSections } from "../context/project/ProjectState";
import { useProjectRecordings } from "../context/project/Recordings";

const S = {
    Container: styled.div`
        width: 100%;
        //height: 233px;

        padding-top: 25px;
        padding-bottom: 25px;

        //flex-basis: 100%;
        //flex-basis: 233px;
        //flex-grow: 1;

        background: #1f2365;

        display: flex;
        flex-direction: column;
        //align-items: stretch;
        //justify-content: stretch;
    `,
    ControlsContainer: styled.div`
        height: 90px;
        //margin-top:25px;
        margin-left: 50px;
        margin-right: 50px;

        display: flex;
        flex-direction: row;

        align-items: center;
        justify-content: stretch;
    `,

    TrackContainer: styled.div`
        height: 100px;
        background: #1f2365;

        display: flex;
        flex-grow: 1;
        flex-direction: column;
        align-content: stretch;
        justify-content: space-evenly;
    `,
    CounterContainer: styled.div`
        position: absolute;
        top: 50%;
        left: 50%;
        right: auto;
        bottom: auto;
        margin-right: -50%;
        transform: translate(-50%, -75%);
    `
};

const getBPMAsSecond = (BPM) => {
    return (60 / BPM) * 1000.0;
};

// const CountdownProvider = (props) => {
//     return (
//     <Metronome
//         tempo={bpm}
//
//         render={({
//                      tempo,
//                      beatsPerMeasure,
//                      playing,
//                      beat,
//                      subdivision,
//                      onPlay,
//                      onBpmChange
//                  }) => (
//
//             <S.Countdown>
//                 <S.CounterContainer>
//                     <S.CountdownNumber>{beat}</S.CountdownNumber>
//                 </S.CounterContainer>
//             </S.Countdown>
//         )} />
//     );
// }



const TimelineSection = (props) => {
    const { seek, duration, position } = useAudioPosition({ highRefreshRate: false });
    const { isPlaying, play, pause, togglePlayPause } = useSongAudio(props.musicSection === 100 ? null : props.musicSection - 1, null, true);
    const [recordings] = useProjectRecordings();

    // create a callback that takes a percentage and sets song position
    const setPosition = React.useCallback((percentage) => seek(duration * percentage), [duration, seek]);

    const song_sections = useProjectSongSections();
    const [splitLists, setSplitLists] = useState([]);
    const [timeStamps, setTimeStamps] = useState([]);

    // seek to the beginning when the full song tab is selected
    useEffect(() => {
        if (props.musicSection === 100) {
            setPosition(0);
        }
    }, [props.musicSection]);

    // reduce phrase timestamps to a single array
    useEffect(() => {
        const phrase_timestamps = song_sections?.reduce((timestamps, section, section_index) => {

            const phrases = section?.phrase_timestamps?.map( (ts, phrase_index) => {
                return {
                    timestamp: ts,
                    phrase: phrase_index,
                    section: section_index
                }
            }) ?? [];
            return [...timestamps, ...phrases];
        }, []);

        // add the final duration at the end
        // const allTimestamps = [...phrase_timestamps, duration];
        // allTimestamps.reverse();
        phrase_timestamps.reverse();
        setTimeStamps(phrase_timestamps);
        // console.log("created timestamps:", phrase_timestamps);
    }, [song_sections]);

    // set active phrase when position moves
    React.useEffect( () => {
        const phrase = timeStamps.find( (phrase) => position >= phrase.timestamp);
        if( phrase ) {
            // console.log(`${position} = `, phrase.timestamp, phrase.section, phrase.phrase)
            props.setActiveSection([phrase.section, phrase.phrase]);
        }
    }, [position, timeStamps]);

    return (
        <S.Container>
            <S.ControlsContainer>
                <PlayButton
                    className={"white"}
                    onClick={togglePlayPause}
                    playing={isPlaying}
                    style={{opacity:(props.isRecording ? 0 : 1) }}
                />

                <SongTimeline
                    song={props.song}
                    position={position}
                    setPosition={setPosition}
                    musicSection={props.editMode === "record" ? 100 : props.musicSection}
                    duration={duration}
                    percentComplete={position / duration}
                    pause={pause}
                    play={play}
                    isPlaying={isPlaying}
                    isRecording={props.isRecording}
                    recordings={recordings}
                    showRecordButton={props.showRecordButton}
                    disableSeek = {false}
                />
            </S.ControlsContainer>

        </S.Container>
    );
};

export default TimelineSection;
