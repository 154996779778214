import React from 'react';
import {useParams} from "react-router-dom";
import ProjectProvider, {useProjectSong} from "../context/project/ProjectState";
import MediaProvider from "../context/MediaContext";
import LyricsContainer from "../lyrics/LyricsContainer";
import ActivityIndicator from "../common/ActivityIndicator";

const LyricsEditorContainer = (props) => {
    const song = useProjectSong();
    if (!song) {
        return (<ActivityIndicator text={"Please wait, loading song..."}/>);
    }

    return (
        <MediaProvider>
            <LyricsContainer projectId={props.projectId}/>
        </MediaProvider>
    );
};

const ProjectEditorContainer = () => {
    const {projectId} = useParams();
    return (
        <ProjectProvider key={projectId} projectId={projectId}>
            <LyricsEditorContainer projectId={projectId}/>
        </ProjectProvider>
    );
};

export default ProjectEditorContainer;
