import React, { useState, useEffect, useRef } from "react";
import { Player } from "@remotion/player";
import { useProject, useSong } from "../../context/project/ProjectState";
import { getSongFileURL } from "../../context/SongAudio";
import { useProjectPhraseList, LyricsComposition } from "../../../remotion/LyricsVideo/LyricsComposition";
import { Button } from "semantic-ui-react";
import ReactModal from "react-modal";
import tw, { css } from "twin.macro";
import "styled-components/macro";

const ThumbnailSelectModal = ({ isOpen, setIsOpen }) => {
    const project = useProject();
    const video_style = project.data?.draft?.video_style ?? {};
    const background = video_style?.color || "#E9961A";
    const font = video_style?.font;
    const decals = video_style.decals || [];
    const song = useSong();
    const audioTrackURL = getSongFileURL(song, song.data?.wav);
    const phrases = useProjectPhraseList();
    const songDuration = song.duration;
    const [hideDragging, setHideDragging] = useState(null);
    const playerRef = useRef();

    useEffect(() => {
        if (playerRef.current) {
            let currentFrame = playerRef.current.getCurrentFrame();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playerRef]);

    return (
        <div>
            <ReactModal
                ariaHideApp={false}
                isOpen={isOpen}
                onRequestClose={() => setIsOpen(false)}
                shouldCloseOnOverlayClick={false}
                style={{
                    overlay: {
                        backgroundColor: "rgba(125, 38, 223, 0.5)",
                        overflow: "visible"
                    },
                    content: {
                        top: "50%",
                        left: "50%",
                        right: "auto",
                        bottom: "auto",
                        marginRight: "-50%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "#1F2365",
                        border: "none",
                        borderRadius: "30px",
                        padding: "42px 56px 22px",
                        overflow: "visible",
                        position: "relative",
                        maxWidth: "800px"
                    }
                }}
            >
                <div>
                    <div tw="flex flex-grow relative">
                        <Player
                            ref={playerRef}
                            id="video-player"
                            component={LyricsComposition}
                            durationInFrames={Math.ceil(30 * songDuration)}
                            fps={30}
                            compositionWidth={1920}
                            compositionHeight={1080}
                            inputProps={{ background, font, decals, audioTrackURL, phrases, songDuration, hideDragging, setHideDragging }}
                            controls={true}
                            style={{
                                // background: "black",
                                width: "100%"
                            }}
                        />
                    </div>
                    <div
                        tw="bg-white h-12 w-12 absolute top--4 right--4 rounded-full flex justify-center items-center shadow-md cursor-pointer z-20"
                        onClick={() => setIsOpen(false)}
                    >
                        <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M16.705 1.257c-.71-.711-1.773-.711-2.482 0l-5.2 5.211-5.201-5.211c-.71-.711-1.773-.711-2.482 0-.71.71-.71 1.776 0 2.487l5.2 5.212-5.2 5.211c-.71.71-.71 1.777 0 2.487.355.356.827.474 1.3.474.473 0 .946-.118 1.3-.474l5.2-5.211 5.2 5.211c.355.356.828.474 1.301.474s.946-.118 1.3-.474c.71-.71.71-1.776 0-2.487l-5.2-5.211 5.2-5.212c.473-.71.473-1.777-.236-2.487z"
                                fill="#6A7277"
                            />
                        </svg>
                    </div>
                </div>
                <div tw="w-full flex justify-end mt-5">
                    <Button tw="hover:opacity[75%] active:opacity[50%] py-2 px-8 background[#E637A8] rounded-full uppercase text-sm font-extrabold leading-normal text-white maxWidth[275px]">
                        Select Thumbnail
                    </Button>
                </div>
            </ReactModal>
        </div>
    );
};

export default ThumbnailSelectModal;
