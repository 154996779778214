let mathWordsThatCanBeSearched = [
    '2D',
    '3D',
    'ASA theorem',
    'Pythagorean Theorem',
    'Roman numeral',
    'SAS theorem',
    'SSS theorem',
    'absolute',
    'add',
    'addition',
    'adjacent',
    'algebra',
    'angle',
    'angles',
    'area',
    'average',
    'base',
    'binomial ',
    'bottom',
    'calculate',
    'calculator',
    'capacity',
    'cartesian',
    'center of dilation',
    'coefficient',
    'combine',
    'common',
    'compare',
    'complex shapes',
    'condition',
    'congruence',
    'congruent',
    'constant',
    'coordinate',
    'coordinate plain',
    'coordinate plane',
    'corresponding',
    'corresponding angle',
    'corresponding ratio',
    'corresponding side',
    'count',
    'cube',
    'decimals',
    'decompose',
    'denominator',
    'difference',
    'dilations',
    'direction',
    'distance',
    'divide',
    'division',
    'edges',
    'enclosed corresponding angle',
    'enclosed corresponding side',
    'equal',
    'equation',
    'estimate',
    'exponent',
    'exponents',
    'expressions',
    'faces',
    'factor',
    'false',
    'figure',
    'form',
    'formula',
    'fraction',
    'fractions',
    'geometric figure',
    'graph',
    'graphing',
    'greater',
    'height',
    'hexagon',
    'high',
    'highest',
    'horizontal',
    'hypotenuse',
    'identical',
    'improper',
    'infinite',
    'integers',
    'inverse',
    'invert',
    'irrational',
    'label',
    'largest',
    'least',
    'legs',
    'length',
    'less',
    'line of reflection',
    'low',
    'lowest',
    'mapped',
    'mean',
    'measurements',
    'measures',
    'median',
    'middle',
    'minus',
    'mixed number',
    'mode',
    'most',
    'multiplication',
    'multiply',
    'negative',
    'net',
    'nets',
    'non-rigid transformation',
    'number',
    'numerator',
    'operations',
    'ordered pair',
    'origin',
    'parallelogram ',
    'pattern',
    'pentagon',
    'perimeter',
    'perpendicular',
    'plane',
    'plus',
    'point',
    'polygons',
    'polynomial',
    'position',
    'positive',
    'power',
    'principle',
    'prism',
    'product',
    'products',
    'proper',
    'properties',
    'proportion',
    'quadrant',
    'quotient',
    'radical',
    'range',
    'ratio',
    'rational',
    'reciprocal',
    'rectangle',
    'reduce',
    'reflect',
    'relationship',
    'repeat',
    'rhombus',
    'right angle',
    'right triangle',
    'rigid transformation',
    'root',
    'rotations',
    'same',
    'scale factor',
    'sequence',
    'series',
    'shape',
    'side',
    'side lengths',
    'similar',
    'similar triangles',
    'similarities',
    'simplified',
    'simplify',
    'smallest',
    'solve',
    'square',
    'square ',
    'square root',
    'statement',
    'subtract',
    'subtraction',
    'sum',
    'surface area',
    'symbol',
    'term',
    'term ',
    'terms',
    'top',
    'transformation',
    'trapezoid',
    'triangle',
    'triangular',
    'true',
    'value',
    'variable',
    'verify',
    'vertex',
    'vertical',
    'vertices',
    'volume',
    'whole number',
    'width',
    'x axis',
    'y axis',
    'zero'
];

export default mathWordsThatCanBeSearched;