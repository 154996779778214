import React from 'react';
import styled from "styled-components";

const S = {
    Container: styled.div`
      width: 100%;
      height: 32px;
      background: #D5D5D5;
    `,
}

const VideoTimeline = (props) => {
    return (
        <S.Container>

        </S.Container>
    );
};

export default VideoTimeline;