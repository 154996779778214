import React from "react";

import {
    BrowserRouter as Router,
    // HashRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import HomeContainer from "./ace/home/HomeContainer";

// import logo from './logo.svg';
import "./App.css";
import ArtistCatalogProvider from "./ace/context/ArtistCatalogProvider";
import SongState from "./ace/context/SongContext";
import CreateProject from "./ace/project/CreateProject";
import styled from "styled-components";
import ProjectEditorContainer from "./ace/project/ProjectEditorContainer";
import RouterProvider from "./ace/context/RouterProvider";
import AuthProvider, { useAuth } from "./ace/context/AuthProvider";
import LoginContainer from "./ace/login/LoginContainer";
import { DevToolProvider } from "./lib/DevTool/DevTool";
import { SWRConfig } from "swr";
import { apiFetcher } from "./api";
import TopicCatalog from "./ace/context/TopicCatalog";

const S = {
    Container: styled.div`
        background: #ffffff;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: stretch;
        align-items: stretch;
    `
};

const LogoutContainer = (props) => {
    const { logout } = useAuth();
    React.useEffect(() => {
        logout();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <h1>Logging out</h1>
        </div>
    );
};

const AuthenticatedApp = (props) => {
    return (
        <ArtistCatalogProvider>
            <SongState>
                <TopicCatalog>
                    <S.Container>
                        <Switch>
                            <Route path={"/create/:songId/:name/:topicId"}>
                                <CreateProject />
                            </Route>
                            <Route path="/edit/:projectId">
                                <ProjectEditorContainer />
                            </Route>
                            <Route path="/logout">
                                <LogoutContainer />
                            </Route>
                            <Route path="/home">
                                <HomeContainer />
                            </Route>
                            <Route path="/">
                                <HomeContainer />
                            </Route>
                        </Switch>
                    </S.Container>
                </TopicCatalog>
            </SongState>
        </ArtistCatalogProvider>

    );
};

const SwitchApp = (props) => {
    const { isLoggedIn, isLoggingIn } = useAuth();
    if (isLoggedIn)
        return (<AuthenticatedApp />);
    else
        return (<LoginContainer />);
};

function App() {
    return (
        <div className="App">
            <RouterProvider>
                <SWRConfig
                    value={{
                        fetcher: apiFetcher,
                        revalidateOnFocus: false
                        // refreshInterval: 3000,
                    }}
                >
                    <DevToolProvider>
                        <AuthProvider>
                            <SwitchApp />
                        </AuthProvider>
                    </DevToolProvider>
                </SWRConfig>
            </RouterProvider>
        </div>
    );
}

export default App;
