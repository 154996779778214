import React, { useState } from 'react';
import styled from 'styled-components';

const S = {
    Tab: styled.div`
        padding: ${props => props.numberOfTabs >= 5 ? '11px 40px' : '11px 55px' };
        margin: 0px 6px;
        border-radius: 15px 15px 0px 0px;
        background: #fff;
        user-select: none;
        cursor: pointer;
        max-height: 40px;
        .tab-title {
            font-weight: 900;
            font-size: 18px;
            line-height: 25px;
            color: #bbb;
            text-transform: uppercase;
            text-align: center;
            white-space: nowrap;
            @media (max-width: 1250px) {
                font-size: 16px;
            }
            &.selected {
                color: #fff;
            }
        }
        &.selected {
            .tab-title {
                color: #fff;
            }
        }
        &:hover {
            background: ${(props) => props.hover};
            /* opacity: 75%; */
        }
        &:active {
            background: ${(props) => props.color};
            opacity: 50%;
            .tab-title {
                color: #fff;
            }
        }
        &.selected {
            background: ${(props) => props.color};
            opacity: 100%;
            .tab-title {
                color: #fff;
            }
        }
    `
};

const LyricsTab = (props) => {
    return (
        <S.Tab
            numberOfTabs={props.numberOfTabs}
            color={props.color}
            hover={props.hover}
            onClick={() => props.setMusicSection(props.section_id)}
            className={props.musicSection === props.section_id ? 'selected' : ''}
        >
            <div className={'tab-title'}>{props.tab_title}</div>
        </S.Tab>
    );
};

export default LyricsTab;