import React, { useState } from "react";
import styled from "styled-components/macro";
import { useForm } from "react-hook-form";
import { useAuth } from "../context/AuthProvider";

const S = {
    Container: styled.div`
        height: 100vh;
        width: 100vw;
        background: #05336d;
        
        display: flex;
        
    `,
    Dialog: styled.div`
        height: 400px;
        width: 300px;
        background: white;
        margin: auto;
        padding-top: 30px;
    `,

    FieldContainer: styled.div`
        //background: red;
        margin-bottom: 30px;
    `,

    Button: styled.button`
        //background: red;
        margin-bottom: 30px;
    `

};

const LoginContainer = (props) => {
    const { register, handleSubmit, errors } = useForm();
    const { login, isLoggingIn } = useAuth();
    const [loginError, setLoginError] = useState(null);

    const onSubmit = async ({ username, password }) => {
        setLoginError(null);
        try {
            await login(username, password);
        } catch (error) {
            console.log('login failed: ', error);
            setLoginError(error?.response?.data ?? 'Username or password is incorrect');
            // if (error?.response?.status === 400) {
            //     // error 400 is invalid login
            //     // setLoginFailed(true);
            // } else {
            //     handleNetworkError(error);
            // }
        }
    };

    return (
        <S.Container>
            <S.Dialog>
                <form onSubmit={handleSubmit(onSubmit)}>
                <h1>login</h1>
                    <S.FieldContainer style={{ marginTop: 40 }}>
                        <input
                            className='input'
                            type='text'
                            name='username'
                            ref={register({ required: true })}
                            data-cy='input-username'
                            placeholder='Email/Username *'
                        />
                        {errors.username && <span>This field is required</span>}
                    </S.FieldContainer>
                    <S.FieldContainer>
                        <input
                            className='input'
                            type='password'
                            name='password'
                            ref={register({ required: true })}
                            data-cy='input-password'
                            placeholder='Password *'
                        />

                        {errors.password && <span>This field is required</span>}
                    </S.FieldContainer>

                    <S.Button as='button' type='submit' data-cy='login-button'>
                        LOG IN
                    </S.Button>


                </form>
            </S.Dialog>
        </S.Container>
    );
};


export default LoginContainer;
