import React from 'react';
import styled from "styled-components";

const S = {
    Banner: styled.div`
      font-size: 18px;
      font-family: Avenir,serif;
      font-weight: bold;
      line-height: 20px;
      background: red;
      color: white;
      padding: 10px;

      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 2000000; // navbar = 1000000
    `
}

const Banner = (props) => {
    // return (<S.Banner {...props}/>)
    // return <div>hello</div>
    return (
        <S.Banner>
            {props.children}
        </S.Banner>
    );
};

export default Banner;