import React, {useState, useEffect, useContext} from "react";
import {useProject, useProjectSection, useSong} from "./project/ProjectState";
import {useAudioPlayer, useAudioPosition} from "react-use-audio-player";
import {getSongFileURL} from "./SongAudio";
import DebugData from "../../lib/debug/DebugData";

const Context = React.createContext();

const AudioSamplePlayer = (props) => {
    const [selected, setSelected] = useState({});
    const song = useSong();
    const song_data = song ? song.data || {} : {};
    const audioFileVoice = getSongFileURL(song, song_data.reference_wav);
    // const [play, { stop }] = useSound(full);

//    const {togglePlayPause, ready, loading, playing, play, pause, stop, load} = useAudioPlayer({
//        src: audioFileMusic,
//     console.log('useAudioPlayer', audioFileVoice)
//     console.log("playing: ", audioFileVoice);
    /*
    const {togglePlayPause, ready, loading, playing, play, pause, stop} = useAudioPlayer({
        src: audioFileVoice,
        format: "mp3",
        autoplay: false,
        onend: () => {
            setSelected({})
        }
    })
    const {seek, duration, position} = useAudioPosition({highRefreshRate: true})
    const [loop, setLoop] = useState(null);
    const [stopTime, setStopTime] = useState(null);
    */


    const playPhrase = (sectionIndex, phraseIndex) => {
        // console.log('playPhrase', sectionIndex, phraseIndex)
        // setSectionIndex(sectionIndex);
        // setPhraseIndex(phraseIndex);
        setSelected({sectionIndex, phraseIndex});
    }

    const stopPhrase = (sectionIndex, phraseIndex) => {
        if (selected && selected.sectionIndex === sectionIndex && selected.phraseIndex === phraseIndex) {
            setSelected({});
        }
    }
    /*
    useEffect(() => {
        // console.log("play", selected);

        if (selected && selected.sectionIndex !== undefined && selected.phraseIndex !== undefined) {
            const {sectionIndex, phraseIndex} = selected;

            if (playing) {
                // console.log("Stopping")
                stop()
            }


            // get the section & phrase
            const section = song_data.song_sections[sectionIndex];
            const timestamp = section.phrase_timestamps[phraseIndex]

            // seek
            seek(timestamp)

            // set the stop time
            if( phraseIndex + 1 < section.phrase_timestamps.length ) {
                const stopTime = section.phrase_timestamps[phraseIndex+1];
                setStopTime(stopTime);
            } else {
                setStopTime(timestamp + 5);
            }

            // begin playing
            // console.log(".Playing: ", timestamp, " Stop time: ", timestamp + 5  );
            play();
        } else {
            stop();
        }

        return () => {
            stop();
        }
    }, [selected])

    React.useEffect(() => {
        // console.log(". Position: ", position, " Stop time: ", stopTime  );
        if (position > stopTime) {
            stop();
            setStopTime(null);
            setSelected({});
        }
    }, [stopTime, setStopTime, position]);
    */


    // when a section and index is set, seek and play
    // useEffect(() => {
    //     // console.log('useEffect', sectionIndex, phraseIndex)
    //     // console.log(song_data);
    //     if (sectionIndex >= 0 && song_data && song_data.song_sections) {
    //         const section = song_data.song_sections[sectionIndex];
    //         if (section && section.phrase_timestamps && phraseIndex < section.phrase_timestamps.length) {
    //             const timestamp = section.phrase_timestamps[phraseIndex];
    //             // console.log('seek & play', timestamp)
    //             seek(timestamp);
    //             play();
    //         }
    //     }
    // }, [song_data, sectionIndex, phraseIndex])

    let context = {
        // playing,
        sectionIndex: selected ? selected.sectionIndex : undefined,
        phraseIndex: selected ? selected.phraseIndex : undefined,
        stopPhrase,
        playPhrase,
    }

    return (
        <Context.Provider value={context}>
            {props.children}
            {/*
            <DebugData data={context}>
                {props.children}
            </DebugData>
*/}
        </Context.Provider>
    );
};


export function usePhrasePlayer(s, p) {
    const {
        sectionIndex,
        phraseIndex,
        playPhrase,
        stopPhrase,
    } = useContext(Context)

    const playing = (sectionIndex === s && phraseIndex === p);

    const togglePlayPause = () => {
        // console.log('togglePlayPause');
        if (sectionIndex === s && phraseIndex === p)
            stopPhrase(s, p);
        else
            playPhrase(s, p);
    }

    React.useEffect(() => {
        return () => {
            // console.log("calling stop phrase", sectionIndex, phraseIndex);
            stopPhrase();
            // if (sectionIndex === s && phraseIndex === p) {
            //     stopPhrase();
            // }
        }
    }, []);

    return {
        play: () => playPhrase(s, p),
        pause: () => stopPhrase(s, p),
        togglePlayPause,
        playing
    }

}

export default AudioSamplePlayer;
