import React from "react";
import tw, { css } from "twin.macro";
import styled from "styled-components/macro";
import { useProjectActions } from "../../context/project/ProjectState";
import { v4 as uuidv4 } from "uuid";
function importAll(r) {
    let images = {};
    r.keys().map((item, index) => {
        images[item.replace("./", "")] = r(item);
    });
    return images;
}

const decals = importAll(require.context("../images/decals", false, /\.(png|jpe?g|svg)$/));

const DecalButton = ({ image }) => {
    const { setVideoDecal } = useProjectActions();

    return (
        <div
            css={[
                css`
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex: 1;
                    flex-direction: column;
                    //background: red;
                    &:hover {
                        opacity: 70%;
                    }

                    &:active {
                        opacity: 50%;
                    }
                `
            ]}
            onClick={() => setVideoDecal({ id: uuidv4(), url: image, x: 3, y: 3 })}
        >
            <img src={image} />
        </div>
    );
};

const DecalPalette = (props) => {
    return (
        <div
            css={[
                css`
                    display: grid;
                    grid-template-columns: repeat(5, 1fr);
                    grid-row: auto;
                    height: 100%;
                    min-width: 500px;
                `
            ]}
        >
            {Object.values(decals).map((decal, index) => {
                return <DecalButton key={index} image={decal.default} />;
            })}
        </div>
    );
};

export default DecalPalette;
