let bW = [
    '$#!+',
 '$1ut',
 '$h1t',
 '$hit',
 '$lut',
 "'ho",
 "'hobag",
 '( Y )',
 '(!)',
 '(. Y .)',
 '(@ Y @)',
 '@ss',
 '@sshole',
 'Aids ',
 'Analprobe',
 'Areola',
 'B@lls',
 'Babes',
 'Barf',
 'Beardedclam',
 'Beaver',
 'Bisexual',
 'Blew',
 'Bone',
 'Boobies',
 'Boobs',
 'Bookie',
 'Booky',
 'Booty',
 'Booze',
 'Brassiere',
 'Bullturds',
 'Bunghole',
 'Buttfucker',
 'CIRCUMCISE',
 'Carnal',
 'Carpetmuncher',
 'Cocain',
 'Cocksucker',
 'Coital',
 'Coven',
 'Crabs',
 'Crackwhore',
 'Cummin',
 'Cumshot',
 'Cunnilingus',
 'DOMINATRIX',
 'Dickweed',
 'Drunk',
 'Dummy',
 'EROTICA',
 'Ejaculate',
 'Extasy',
 'F*ck',
 'FOAD',
 'Fairy',
 'Fetish',
 'Fisty',
 'Fornicate',
 'Fornication',
 'Frankfort',
 'Fucking',
 'FvCk',
 'GTFO',
 'Goldenshower',
 'Handjob',
 'Hiv',
 'Holocaust',
 'Homey',
 'Homosexual ',
 'Honky',
 'Hooker',
 'Hootch',
 'INTERCOURSE',
 'Indian pipe',
 'JERK-OFF',
 'Jack off',
 'Jiz',
 'Jizm',
 'KU KLUX KLAN',
 'Kink',
 'Knockers',
 'LINGERIE',
 'LSD',
 'LUSTFUL',
 'Laparoscopic',
 'Lesbian',
 'Lez',
 'Lezbian',
 'Lezbo',
 'Lezbos',
 'Lezzie',
 'Lezzies',
 'Lezzy',
 'Lust',
 'MATE',
 'MATING',
 'MISSIONARY POSITION',
 'MOLESTER',
 'Massa',
 'Masterbation',
 'Masturbation',
 'Menses',
 'Menstruate',
 'Menstruation',
 'Motherfucka',
 'Motherfucker',
 'Muthafucker',
 'NECROPHILIA',
 'NIP',
 'Nappy',
 'Nipple',
 'Nudist',
 'Nudity',
 'Nympho',
 'ORAL SEX',
 'Opiate',
 'PCP',
 'PERVERT',
 'PERVERTED',
 'PHALLUS',
 'PROSTITUTION',
 'PUBIC HAIR',
 'Paddy',
 'Pastie',
 'Pecker',
 'Peepee',
 'Penetrate',
 'Penetration',
 'Phallic',
 'Phuck',
 'Pillowbiter',
 'Queef',
 'RACISM',
 'ROHYPNOL',
 'ROOFIES',
 'Racist',
 'Raper',
 'SALVIA',
 'SEXUALLY',
 'STD',
 'STRIPPER',
 'STRIPPING',
 'SUCCUBUS',
 'SUICIDE',
 'Screwed',
 'Scrot',
 'Scrote',
 'Sexual',
 'Sexy',
 'Shagging',
 'Shithole',
 'Shitter',
 'Spank',
 'Spanking',
 'Stab',
 'Stiffy',
 'Stroke',
 'TERRORISM',
 'TERRORIST',
 'TESTICLES',
 'THREESOME',
 'TRANSVESTITE',
 'Taliban',
 'Testis',
 'Titfuck',
 'VAGINAL',
 'VIBRATOR',
 'Wanker',
 'Whored',
 'Whorehouse',
 'Whoring',
 'ZOOPHILIA',
 'a$$',
 'a$$h0!e',
 'a$$h01e',
 'a$$h0le',
 'a$$hole',
 'a55',
 'a55hole',
 'abbo',
 'abo',
 'aboral',
 'abortion',
 'abuse',
 'accusative',
 'addict',
 'addicts',
 'adic',
 'adult',
 'aeolus',
 'africa',
 'african',
 'ahole',
 'alcohol',
 'aleutian',
 'alla',
 'allah',
 'alligatorbait',
 'amateur',
 'american',
 'anal',
 'analannie',
 'analsex',
 'andean',
 'angie',
 'angry',
 'anilingus',
 'anorexia',
 'anorexic',
 'anus',
 'arab',
 'arabs',
 'areola',
 'areole',
 'argie',
 'arian',
 'aroused',
 'arse',
 'arsehole',
 'aryan',
 'asexual',
 'asia',
 'asian',
 'asiatic',
 'ass',
 'ass hat',
 'ass munch',
 'assassin',
 'assassinate',
 'assassination',
 'assault',
 'assbagger',
 'assbang',
 'assbanged',
 'assbangs',
 'assblaster',
 'assclown',
 'asscowboy',
 'asses',
 'assfuck',
 'assfucker',
 'assh0le',
 'asshat',
 'assho!e',
 'assho1e',
 'asshole',
 'assholes',
 'asshore',
 'assjockey',
 'asskiss',
 'asskisser',
 'assklown',
 'asslick',
 'asslicker',
 'asslover',
 'assman',
 'assmaster',
 'assmonkey',
 'assmunch',
 'assmuncher',
 'asspacker',
 'asspirate',
 'asspuppies',
 'assranger',
 'asswhore',
 'asswipe',
 'asswipes',
 'assyria',
 'athletesfoot',
 'attack',
 'australian',
 'azazel',
 'azz',
 'b1tch',
 'baal',
 'babe',
 'babies',
 'backdoor',
 'backdoorman',
 'backseat',
 'badfuck',
 'ball',
 'balllicker',
 'balls',
 'ballsack',
 'baltic',
 'bang',
 'banger',
 'banging',
 'baptist',
 'barelylegal',
 'barf',
 'barface',
 'barfface',
 'bast',
 'bastard',
 'bastard ',
 'bastards',
 'bawdy',
 'bazongas',
 'bazooms',
 'beaner',
 'beast',
 'beastality',
 'beastial',
 'beastiality',
 'beat-off',
 'beatch',
 'beater',
 'beatoff',
 'beatyourmeat',
 'beaver',
 'beer',
 'beeyotch',
 'benzodiazepine',
 'beotch',
 'berk',
 'bestial',
 'bestiality',
 'bi',
 'bi-sexual',
 'biatch',
 'bible',
 'bicurious',
 'bigass',
 'bigbastard',
 'bigbutt',
 'bigger',
 'bigtits',
 'bimbo',
 'binet',
 'bisexual',
 'bitch',
 'bitched',
 'bitcher',
 'bitches',
 'bitchez',
 'bitchin',
 'bitching',
 'bitchslap',
 'bitchy',
 'biteme',
 'black',
 'blackman',
 'blackout',
 'blacks',
 'blessed',
 'blind',
 'blood',
 'blow',
 'blow job',
 'blow jobs',
 'blow me',
 'blowjob',
 'blowjobs',
 'blowup',
 'boang',
 'bod',
 'bodily',
 'body',
 'bogan',
 'bohunk',
 'boink',
 'bollick',
 'bollock',
 'bollocks',
 'bollok',
 'bomb',
 'bombers',
 'bombing',
 'bombs',
 'bomd',
 'bondage',
 'boned',
 'boner',
 'boners',
 'bong',
 'boob',
 'boobies',
 'boobs',
 'booby',
 'boody',
 'booger',
 'boom',
 'boong',
 'boonga',
 'boonie',
 'boot lip',
 'bootee',
 'bootie',
 'booty',
 'bootycall',
 'boozer',
 'boozy',
 'bosom',
 'bosomy',
 'bountybar',
 'bowel',
 'bowels',
 'bra',
 'brandenburg',
 'brea5t',
 'breast',
 'breastjob',
 'breastlover',
 'breastman',
 'breasts',
 'british',
 'brothel',
 'buccal',
 'bugger',
 'buggered',
 'buggery',
 'bukkake',
 'bulimia',
 'bulimiic',
 'bullcrap',
 'bulldike',
 'bulldyke',
 'bullshit',
 'bullshits',
 'bullshitted',
 'bumblefuck',
 'bumfuck',
 'bung',
 'bunga',
 'bunghole',
 'buried',
 'burma',
 'burn',
 'burp',
 'bush',
 'busty',
 'butchbabes',
 'butchdike',
 'butchdyke',
 'butt',
 'butt-bang',
 'butt-fuck',
 'butt-fucker',
 'butt-fuckers',
 'buttbang',
 'buttface',
 'buttfuck',
 'buttfucker',
 'buttfuckers',
 'butthead',
 'buttman',
 'buttmunch',
 'buttmuncher',
 'buttpirate',
 'buttplug',
 'buttstain',
 'byatch',
 'c-0-c-k',
 'c-o-c-k',
 'c-u-n-t',
 'c.0.c.k',
 'c.o.c.k.',
 'c.u.n.t',
 'c0ck',
 'caca',
 'cacker',
 'cahone',
 'camel toe',
 'camel toes',
 'cameljockey',
 'cameltoe',
 'canadian',
 'cancer',
 'canonical',
 'carlton',
 'carpet muncher',
 'carpetmuncher',
 'carruth',
 'catholic',
 'catholics',
 'cawk',
 'cdtrue',
 'celtic',
 'cemetery',
 'cerebellopontine',
 'cervical',
 'cervix',
 'chav',
 'cherrypopper',
 'chickslick',
 "children's",
 'chin',
 'china',
 'chinaman',
 'chinamen',
 'chinc',
 'chincs',
 'chinese',
 'chink',
 'chinky',
 'choad',
 'chode',
 'chodes',
 'cholo',
 'christ',
 'christian',
 'church',
 'cigarette',
 'cigs',
 'cl1t',
 'clamdigger',
 'clamdiver',
 'cleve',
 'climax',
 'clit',
 'clitoris',
 'clitorus',
 'clits',
 'clitty',
 'clogwog',
 'cocaine',
 'cock',
 'cockblock',
 'cockblocker',
 'cockcowboy',
 'cockfight',
 'cockhead',
 'cockholster',
 'cockknob',
 'cockknocker',
 'cocklicker',
 'cocklover',
 'cocknob',
 'cockqueen',
 'cockrider',
 'cocks',
 'cocksman',
 'cocksmith',
 'cocksmoker',
 'cocksucer',
 'cocksuck ',
 'cocksucked ',
 'cocksucker',
 'cocksucking',
 'cocktail',
 'cocktease',
 'cocky',
 'coercive',
 'cohee',
 'coitus',
 'coke',
 'color',
 'colored',
 'coloured',
 'commie',
 'communal',
 'communist',
 'condom',
 'confessional',
 'congenital',
 'conjugal',
 'conservative',
 'conspiracy',
 'consummate',
 'coolie',
 'cooly',
 'coon',
 'coondog',
 'coons',
 'copulate',
 'corksucker',
 'cornhole',
 'corpse',
 'corruption',
 'cra5h',
 'crabs',
 'crack',
 'crack-whore',
 'cracker',
 'crackpipe',
 'crackwhore',
 'crap',
 'crapola',
 'crapper',
 'crappy',
 'crash',
 'creamy',
 'crime',
 'crimes',
 'criminal',
 'criminals',
 'cross',
 'crotch',
 'crotchjockey',
 'crotchmonkey',
 'crotchrot',
 'crucify me',
 'crude',
 'cuervo',
 'cum',
 'cumbubble',
 'cumfest',
 'cumjockey',
 'cumm',
 'cummer',
 'cumming',
 'cumquat',
 'cumqueen',
 'cumshot',
 'cumshots',
 'cumstain',
 'cunilingus',
 'cunillingus',
 'cunn',
 'cunnilingus',
 'cunntt',
 'cunny',
 'cunt',
 'cunteyed',
 'cuntface',
 'cuntfuck',
 'cuntfucker',
 'cunthunter',
 'cuntlick',
 'cuntlick ',
 'cuntlicker',
 'cuntlicker ',
 'cuntlicking ',
 'cunts',
 'cuntsucker',
 'cybersex',
 'cyberslimer',
 'd0ng',
 'd0uch3',
 'd0uche',
 'd1ck',
 'd1ld0',
 'd1ldo',
 'daddy',
 'daft',
 'dago',
 'dagos',
 'dahmer',
 'dammit',
 'damn',
 'damnation',
 'damned',
 'damnit',
 'darkie',
 'darky',
 'datnigga',
 'dawgie-style',
 'dead',
 'deapthroat',
 'death',
 'decapitated',
 'deepthroat',
 'defecate',
 'degenerate',
 'dego',
 'demon',
 'denis',
 'denmark',
 'deposit',
 'derogatory',
 'desire',
 'destroy',
 'detestable',
 'deth',
 'deviant',
 'devil',
 'devilworshipper',
 'dic',
 'dick',
 'dick-ish',
 'dickbag',
 'dickbrain',
 'dickdipper',
 'dickflipper',
 'dickforbrains',
 'dickhead',
 'dickheads',
 'dickish',
 'dickless',
 'dicklick',
 'dicklicker',
 'dickman',
 'dickripper',
 'dicksipper',
 'dickwad',
 'dickweed',
 'dickwhipper',
 'dickzipper',
 'diddle',
 'die',
 'died',
 'dies',
 'dike',
 'dildo',
 'dildos',
 'diligaf',
 'dillweed',
 'dimwit',
 'dingle',
 'dingleberry',
 'dink',
 'dipshit',
 'dipstick',
 'dirac',
 'dirge',
 'dirty',
 'discharge',
 'disease',
 'diseases',
 'disturbed',
 'dive',
 'divine',
 'dix',
 'dixiedike',
 'dixiedyke',
 'doctrinaire',
 'doggie style',
 'doggie-style',
 'doggiestyle',
 'doggy style',
 'doggy-style',
 'doggystyle',
 'dominant',
 'dong',
 'doo-doo',
 'doodoo',
 'doofus',
 'doom',
 'doosh',
 'dope',
 'dopey',
 'douch3',
 'douche',
 'douche waffle',
 'douchebag',
 'douchebags',
 'douchey',
 'dragqueen',
 'dragqween',
 'dripdick',
 'drug',
 'drugs',
 'drunk',
 'drunken',
 'dsn',
 'dtrue',
 'dumass',
 'dumb',
 'dumb ass',
 'dumbass',
 'dumbasses',
 'dumbbitch',
 'dumbfuck',
 'dummy',
 'dutch',
 'dyadic',
 'dyefly',
 'dyke',
 'dykes',
 'dynamic',
 'dysentery',
 'easyslut',
 'eatballs',
 'eatme',
 'eatpussy',
 'ecstacy',
 'edible',
 'effeminate',
 'egyptian',
 'ejaculate',
 'ejaculated',
 'ejaculating ',
 'ejaculation',
 'elderly',
 'embryo',
 'endian',
 'endoscopic',
 'enema',
 'enemy',
 'english',
 'enlargement',
 'erect',
 'erection',
 'ero',
 'erotic',
 'erythematous',
 'escort',
 'esophageal',
 'essohbee',
 'ethical',
 'ethiopian',
 'ethnic',
 'ethnoracial',
 'euphoric',
 'european',
 'evil',
 'evl',
 'excrement',
 'excretory',
 'execute',
 'executed',
 'execution',
 'executioner',
 'exotic',
 'explicit',
 'explosion',
 'extacy',
 'f u c k',
 'f-u-c-k',
 'f.u.c.k',
 'f@g',
 'f@gg0t',
 'f@ggot',
 'facefucker',
 'fack',
 'faeces',
 'faerie',
 'faery',
 'fag',
 'fagg',
 'fagged',
 'fagging',
 'faggit',
 'faggot',
 'fagot',
 'fags',
 'faig',
 'faigt',
 'failed',
 'failure',
 'fairies',
 'fairy',
 'faith',
 'fanny bandit',
 'fannybandit',
 'fannyfucker',
 'fart',
 'farted ',
 'farting ',
 'fartknocker',
 'farty ',
 'fastfuck',
 'fat',
 'fatah',
 'fatass',
 'fatfuck',
 'fatfucker',
 'fatso',
 'fckcum',
 'fear',
 'feces',
 'felatio ',
 'felch',
 'felcher',
 'felching',
 'fellate',
 'fellatio',
 'feltch',
 'feltcher',
 'feltching',
 'female',
 'feminine',
 'feminist',
 'fetal',
 'fetish',
 'fight',
 'filipina',
 'filipino',
 'fingerfood',
 'fingerfuck ',
 'fingerfucked ',
 'fingerfucker ',
 'fingerfuckers',
 'fingerfucking ',
 'fire',
 'firing',
 'fisted',
 'fister',
 'fistfuck',
 'fistfucked ',
 'fistfucker ',
 'fistfucking ',
 'fisting',
 'flange',
 'flasher',
 'flatulence',
 'fleshy',
 'floo',
 'floozy',
 'flydie',
 'flydye',
 'fok',
 'fondle',
 'foobar',
 'footaction',
 'footfuck',
 'footfucker',
 'footlicker',
 'footstar',
 'fore',
 'foreign',
 'foreskin',
 'forni',
 'fornicate',
 'fortran',
 'foul',
 'foursome',
 'fourtwenty',
 'frack',
 'fraud',
 'freakfuck',
 'freakyfucker',
 'freefuck',
 'freex',
 'french',
 'frigg',
 'frigga',
 'frontal',
 'fu',
 'fu*ck',
 'fubar',
 'fuc',
 'fucck',
 'fuck',
 'fuck off',
 'fuck wad',
 'fuck-tard',
 'fucka',
 'fucka$$',
 'fuckable',
 'fuckass',
 'fuckbag',
 'fuckbuddy',
 'fucked',
 'fuckedup',
 'fucker',
 'fuckers',
 'fuckface',
 'fuckfest',
 'fuckfreak',
 'fuckfriend',
 'fuckhead',
 'fuckher',
 'fuckin',
 'fuckina',
 'fucking',
 'fuckingbitch',
 'fuckinnuts',
 'fuckinright',
 'fuckit',
 'fuckknob',
 'fuckme ',
 'fuckmehard',
 'fuckmonkey',
 'fucknugget',
 'fucknut',
 'fuckoff',
 'fuckpig',
 'fucks',
 'fucktard',
 'fuckup',
 'fuckwad',
 'fuckwhore',
 'fuckwit',
 'fuckyou',
 'fudge packer',
 'fudgepacker',
 'fugly',
 'fuk',
 'fuks',
 'funeral',
 'funfuck',
 'fungus',
 'fuuck',
 'fxck',
 'fyke',
 'g-spot',
 'g@y',
 'gae',
 'gai',
 'gangbang',
 'gangbanged ',
 'gangbanger',
 'gangsta',
 'ganja',
 'gastrointestinal',
 'gatorbait',
 'gay',
 'gaymuthafuckinwhore',
 'gays',
 'gaysex ',
 'geez',
 'geezer',
 'gender dysphoria',
 'gendered',
 'geni',
 'genital',
 'genocide',
 'german',
 'gerrish',
 'getiton',
 'gey',
 'gfy',
 'ghay',
 'ghey',
 'gigolo',
 'gin',
 'ginzo',
 'gipp',
 'girls',
 'givehead',
 'glans',
 'glazeddonut',
 'glory hole',
 'goatse',
 'gob',
 'god',
 'god damn',
 'godammit',
 'godamn',
 'godamnit',
 'goddam',
 'goddamit',
 'goddammit',
 'goddamn',
 'goddamned',
 'goddamnes',
 'goddamnit',
 'goddamnmuthafucker',
 'goldenshower',
 'golliwog',
 'gonad',
 'gonads',
 'gonorrehea',
 'gonzagas',
 'gook',
 'gooks',
 'gory',
 'gotohell',
 'goy',
 'goyim',
 'greaseball',
 'gringo',
 'groe',
 'grope',
 'groping',
 'gross',
 'grostulation',
 'grotesque',
 'gspot',
 'gubba',
 'guido',
 'guillotine',
 'gummer',
 'gun',
 'gupta',
 'gyp',
 'gypo',
 'gypp',
 'gyppie',
 'gyppo',
 'gyppy',
 'h0m0',
 'h0mo',
 'half-breed',
 'hamas',
 'hand job',
 'handjob',
 'handsome',
 'handy',
 'hapa',
 'harder',
 'hardon',
 'harem',
 'he11',
 'headfuck',
 'headlights',
 'hebe',
 'heeb',
 'hell',
 'hemp',
 'henhouse',
 'hermaphrodite',
 'heroin',
 'herp',
 'herpes',
 'herpy',
 'heterosexual',
 'hick',
 'hijack',
 'hijacker',
 'hijacking',
 'hillbillies',
 'hind',
 'hindoo',
 'hindostan',
 'hiscock',
 'hispanic',
 'hitler',
 'hitlerism',
 'hitlerist',
 'hiv',
 'hla',
 'ho',
 'hobag',
 'hobo',
 'hodgie',
 'hoes',
 'hole',
 'holestuffer',
 'holy',
 'hom0',
 'homicide',
 'homo',
 'homobangers',
 'homoey',
 'homosexual',
 'honger',
 'honk',
 'honkers',
 'honkey',
 'honky',
 'hooch',
 'hook',
 'hookah',
 'hooker',
 'hookers',
 'hoor',
 'hooter',
 'hooters',
 'hore',
 'hork',
 'horn',
 'horney',
 'horniest',
 'horny',
 'horseshit',
 'hosejob',
 'hoser',
 'hostage',
 'hotdamn',
 'hotpussy',
 'hottotrot',
 'hummer',
 'hump',
 'humped',
 'humping',
 'husky',
 'hussy',
 'hustler',
 'hymen',
 'hymie',
 'iblowu',
 'idiot',
 'ikey',
 'illegal',
 'inbred',
 'incest',
 'indian',
 'infant',
 'injun',
 'insest',
 'int',
 'intercourse',
 'intercoxal',
 'interracial',
 'intheass',
 'inthebuff',
 'intimate',
 'intravenous',
 'inv',
 'ipv4',
 'israel',
 "israel's",
 'israeli',
 'isvalid',
 'italian',
 'italiano',
 'itch',
 'ith',
 'j3rk0ff',
 'jackass',
 'jackhole',
 'jackoff',
 'jackshit',
 'jacktheripper',
 'jade',
 'jap',
 'japanese',
 'japcrap',
 'japs',
 'jebus',
 'jeez',
 'jerk',
 'jerk off',
 'jerk0ff',
 'jerked',
 'jerkoff',
 'jesus',
 'jesuschrist',
 'jew',
 'jewboy',
 'jewish',
 'jiga',
 'jigaboo',
 'jigg',
 'jigga',
 'jiggabo',
 'jigger ',
 'jiggy',
 'jihad',
 'jijjiboo',
 'jimfish',
 'jism',
 'jiz ',
 'jizim',
 'jizjuice',
 'jizm',
 'jizm ',
 'jizz',
 'jizz bag',
 'jizzed',
 'jizzim',
 'jizzum',
 'jock',
 'jock itch',
 'joint',
 'juggalo',
 'jugs',
 'junglebunny',
 'junkie',
 'junky',
 'kaffer',
 'kaffir',
 'kaffre',
 'kafir',
 'kanake',
 'kid',
 'kigger',
 'kike',
 'kikes',
 'kill',
 'killed',
 'killer',
 'killing',
 'kills',
 'kink',
 'kinky',
 'kissass',
 'kkk',
 'klan',
 'knife',
 'knob end',
 'knobend',
 'knockers',
 'kock',
 'kondum',
 'kooch',
 'kooches',
 'koon',
 'kootch',
 'kotex',
 'krap',
 'krappy',
 'kraut',
 'kum',
 'kumbubble',
 'kumbullbe',
 'kummer',
 'kumming',
 'kumquat',
 'kums',
 'kunilingus',
 'kunnilingus',
 'kunt',
 'ky',
 'kyke',
 'labia',
 'labial',
 'lactate',
 'laid',
 'lapdance',
 'latin',
 'lech',
 'leper',
 'lesbain',
 'lesbayn',
 'lesbian',
 'lesbians',
 'lesbin',
 'lesbo',
 'lesbos',
 'lez',
 'lezbe',
 'lezbefriends',
 'lezbians',
 'lezbo',
 'lezz',
 'lezzo',
 'liberal',
 'libidinous',
 'libido',
 'licker',
 'lickme',
 'lies',
 'limey',
 'limpdick',
 'limy',
 'lingerie',
 'liquor',
 'livesex',
 'lmao',
 'lmfao',
 'loadedgun',
 'loin',
 'loins',
 'lolita',
 'looser',
 'loser',
 'lotion',
 'lovebone',
 'lovegoo',
 'lovegun',
 'lovejuice',
 'lovemuscle',
 'lovepistol',
 'loverocket',
 'lowlife',
 'lsd',
 'lube',
 'lubejob',
 'lucifer',
 'luckycammeltoe',
 'lugan',
 'lusty',
 'lynch',
 'm-fucking',
 'macaca',
 'machine gun',
 'mad',
 'mafia',
 'magicwand',
 'male',
 'mammary',
 'mams',
 'mange',
 'manhater',
 'manpaste',
 'marijuana',
 'masculine',
 'mastabate',
 'mastabater',
 'masterbate',
 'masterbating',
 'masterblaster',
 'mastrabator',
 'masturbate',
 'masturbating',
 'maternal copulator',
 'mattressprincess',
 'maxi',
 'meatbeatter',
 'meatrack',
 'mental',
 'meth',
 'mexican',
 'mgger',
 'mggor',
 'mickeyfinn',
 'mideast',
 'milf',
 'minority',
 'mitotic',
 'mockey',
 'mockie',
 'mocky',
 'mofo',
 'moky',
 'moles',
 'molest',
 'molestation',
 'molester',
 'molestor',
 'moneyshot',
 'moolie',
 'mooncricket',
 'morbid',
 'mormon',
 'moron',
 'moslem',
 'mosshead',
 'mothafuck',
 'mothafucka',
 'mothafuckaz',
 'mothafucked ',
 'mothafucker',
 'mothafuckin',
 'mothafucking ',
 'mothafuckings',
 'mother fucker',
 'mother fuckers',
 'mother humper',
 'motherf*cker',
 'motherfuck',
 'motherfucked',
 'motherfucker',
 'motherfuckin',
 'motherfucking',
 'motherfuckings',
 'motherlovebone',
 'mtherfucker',
 'mthrf*cker',
 'mthrfucker',
 'mthrfucking',
 'muff',
 'muffdive',
 'muffdiver',
 'muffindiver',
 'mufflikcer',
 'mulatto',
 'muncher',
 'munt',
 'murder',
 'murderer',
 'muslim',
 'muthafuckaz',
 'mutherfucker',
 'mutherfucking',
 'muthrfucking',
 'nad',
 'nads',
 'naked',
 'napalm',
 'narcotic',
 'nasty',
 'nastybitch',
 'nastyho',
 'nastyslut',
 'nastywhore',
 'nazi',
 'nazism',
 'necro',
 'negro',
 "negro's",
 'negroes',
 'negroid',
 'nig',
 'niger',
 'nigerian',
 'nigerians',
 'nigg',
 'nigga',
 'niggah',
 'niggaracci',
 'niggard',
 "niggard's",
 'niggarded',
 'niggarding',
 'niggardliness',
 "niggardliness's",
 'niggardly',
 'niggards',
 'niggas',
 'niggaz',
 'nigger',
 "nigger's",
 'niggerhead',
 'niggerhole',
 'niggers',
 'niggle',
 'niggled',
 'niggles',
 'niggling',
 'nigglings',
 'niggor',
 'niggur',
 'niglet',
 'nignog',
 'nigr',
 'nigra',
 'nigre',
 'nimrod',
 'ninny',
 'nip',
 'nipple',
 'nipplering',
 'nittit',
 'nlgger',
 'nlggor',
 'nofuckingway',
 'nook',
 'nookey',
 'nookie',
 'nooky',
 'noonan',
 'nooner',
 'nucking futs',
 'nucleophilic',
 'nude',
 'nudger',
 'nuke',
 'nut',
 'nutfucker',
 'nutt',
 'nymph',
 'obscene',
 'ontherag',
 'opium',
 'oral',
 'orally',
 'orga',
 'organ',
 'orgasim ',
 'orgasm',
 'orgasmic',
 'orgies',
 'orgy',
 'osama',
 'ovary',
 'ovum',
 'ovums',
 'p*ssy',
 'p.u.s.s.y.',
 'paki',
 'palesimian',
 'palestinian',
 'pansies',
 'pansy',
 'panti',
 'pantie',
 'panties',
 'panty',
 'pasty',
 'payo',
 'pearlnecklace',
 'peck',
 'pecker',
 'peckerwood',
 'pedo',
 'pedophile',
 'pedophilia',
 'pedophiliac',
 'pee',
 'pee-pee',
 'peehole',
 'peepshow',
 'peepshpw',
 'pejorative',
 'pendy',
 'penetration',
 'peni5',
 'penial',
 'penile',
 'penis',
 'penises',
 'penthouse',
 'period',
 'persian',
 'perv',
 'perversion',
 'peyote',
 'phalli',
 'pharmaceutical',
 'pharmacokinetic',
 'pharmacological',
 'phonesex',
 'phuk',
 'phuked',
 'phuking',
 'phukked',
 'phukking',
 'phungky',
 'phuq',
 'pi55',
 'picaninny',
 'piccaninny',
 'pickaninny',
 'piker',
 'pikey',
 'piky',
 'pimp',
 'pimped',
 'pimper',
 'pimpjuic',
 'pimpjuice',
 'pimpsimp',
 'pindick',
 'pinko',
 'piss',
 'piss-off',
 'pissed',
 'pisser',
 'pisses ',
 'pisshead',
 'pissin ',
 'pissing',
 'pissoff',
 'pissoff ',
 'pistol',
 'pixie',
 'pixy',
 'playboy',
 'playgirl',
 'pms',
 'pocha',
 'pocho',
 'pocketpool',
 'pohm',
 'polack',
 'pole',
 'political',
 'pom',
 'pommie',
 'pommy',
 'poo',
 'poon',
 'poontang',
 'poop',
 'pooper',
 'pooperscooper',
 'pooping',
 'poor',
 'poorwhitetrash',
 'popimp',
 'porch monkey',
 'porch monkeys',
 'porchmonkey',
 'porn',
 'pornflick',
 'pornking',
 'porno',
 'pornography',
 'pornprincess',
 'pot',
 'potty',
 'poverty',
 'prat',
 'predator',
 'premature',
 'pric',
 'prick',
 'prickhead',
 'prig',
 'primetime',
 'privileged',
 'propaganda',
 'pros',
 'prostitute',
 'protestant',
 'provincial',
 'prude',
 'psychiatric',
 'pu$$y',
 'pu55i',
 'pu55y',
 'pube',
 'pubic',
 'pubiclice',
 'pubis',
 'pud',
 'pudboy',
 'pudd',
 'puddboy',
 'puke',
 'punkass',
 'punky',
 'puntang',
 'purinapricness',
 'puss',
 'pussie',
 'pussies',
 'pussy',
 'pussycat',
 'pussyeater',
 'pussyfucker',
 'pussylicker',
 'pussylips',
 'pussylover',
 'pussypounder',
 'pusy',
 'quashie',
 'queaf',
 'queef',
 'queer',
 'queero',
 'queers',
 'quickie',
 'quicky',
 'quim',
 'r-tard',
 'ra8s',
 'rabbi',
 'racial',
 'racist',
 'racy',
 'radical',
 'radicals',
 'raghead',
 'randy',
 'rape',
 'raped',
 'raper',
 'rapist',
 'rapturous',
 'rastus',
 'rat',
 'raunch',
 'raw',
 'rear',
 'rearend',
 'rearentry',
 'rectal',
 'rectum',
 'rectus',
 'redlight',
 'redneck',
 'redskin',
 'reefer',
 'reestie',
 'reetard',
 'refugee',
 'reich',
 'reject',
 'religious',
 'remains',
 'rentafuck',
 'repressive',
 'reproductive',
 'reptiles',
 'republican',
 'rere',
 'retard',
 'retarded',
 'revue',
 'rheological',
 'rheumatoid',
 'rhizome',
 'ribbed',
 'rich',
 'rigger',
 'rigid',
 'rim',
 'rim job',
 'rim jobs',
 'rimjob',
 'rimming',
 'risque',
 'ritard',
 'roach',
 'robber',
 'rodents',
 'roman',
 'romantic',
 'rostral',
 'rostrum',
 'roundeye',
 'rtard',
 'rum',
 'rump',
 'rumprammer',
 'rural',
 'russian',
 'russki',
 'russkie',
 's hit',
 's*o*b',
 's-h-1-t',
 's-h-i-t',
 's-o-b',
 's.h.i.t.',
 's.o.b.',
 's0b',
 'saccular',
 'saclike',
 'sacred',
 'sadis',
 'sadism',
 'sadist',
 'sadom',
 'sagittal',
 'saigon',
 'sambo',
 'samckdaddy',
 'sand nigger',
 'sand niggers',
 'sandm',
 'sandnigger',
 'sandy',
 'sanguine',
 'sanitary',
 'sanskrit',
 'sarcomere',
 'satan',
 'savage',
 'scag',
 'scalar',
 'scallywag',
 'scandinavian',
 'scantily',
 'scat',
 'schizo',
 'schlong',
 'scophthalmus',
 'screw',
 'screwyou',
 'scrog',
 'scrotum',
 'scrud',
 'scum',
 'seaman',
 'seamen',
 'seamy',
 'sectarian',
 'seduce',
 'seed',
 'semantic',
 'semen',
 'semiotic',
 'sententious',
 'seppo',
 'septal',
 'serosanguineous',
 'servant',
 'sex',
 'sex_story',
 'sexed',
 'sexfarm',
 'sexhound',
 'sexhouse',
 'sexing',
 'sexkitten',
 'sexpot',
 'sexslave',
 'sextogo',
 'sextoy',
 'sextoys',
 'sexual',
 'sexually',
 'sexwhore',
 'sexy',
 'sexy-slim',
 'sexymoma',
 'sh!t',
 'sh*t',
 'sh1t',
 'shag',
 'shaggin',
 'shagging',
 'shamedame',
 'shat',
 'shav',
 'shawtypimp',
 'sheeney',
 'sheeny',
 'shhit',
 'shinola',
 'shit',
 'shitcan',
 'shitdick',
 'shite',
 'shiteater',
 'shited',
 'shitface',
 'shitfaced',
 'shitfit',
 'shitforbrains',
 'shitfuck',
 'shitfucker',
 'shitfull',
 'shithapens',
 'shithappens',
 'shithead',
 'shithouse',
 'shiting',
 'shitlist',
 'shitola',
 'shitoutofluck',
 'shits',
 'shitstain',
 'shitt',
 'shitted',
 'shitter',
 'shitting',
 'shitty',
 'shitty ',
 'shiz',
 'shoot',
 'shooting',
 'shortfuck',
 'showtime',
 'shylock',
 'sick',
 'silken',
 'sissy',
 'situ',
 'sixsixsix',
 'sixtynine',
 'sixtyniner',
 'skag',
 'skank',
 'skankbitch',
 'skankfuck',
 'skankwhore',
 'skanky',
 'skankybitch',
 'skankywhore',
 'skinflute',
 'skinny',
 'skum',
 'skumbag',
 'slant',
 'slanteye',
 'slapper',
 'slaughter',
 'slav',
 'slave',
 'slavedriver',
 'sleaze',
 'sleazy',
 'sleeveless',
 'sleezebag',
 'sleezeball',
 'slender',
 'slideitin',
 'slim',
 'slime',
 'slimeball',
 'slimebucket',
 'slopehead',
 'slopey',
 'slopy',
 'slut',
 'slut devil',
 'slutdumper',
 'slutkiss',
 'sluts',
 'slutt',
 'slutting',
 'slutty',
 'slutwear',
 'slutwhore',
 'smack',
 'smackthemonkey',
 'smegma',
 'smut',
 'smutty',
 'snatch',
 'snatchpatch',
 'snigger',
 "snigger's",
 'sniggered',
 'sniggering',
 'sniggers',
 'sniper',
 'snot',
 'snowback',
 'snownigger',
 'snuff',
 'sob',
 'socialist',
 'sod',
 'sodom',
 'sodomise',
 'sodomite',
 'sodomize',
 'sodomy',
 'sonofabitch',
 'sonofbitch',
 'sooty',
 'sos',
 'souse',
 'soused',
 'southeast',
 'southeasterly',
 'southeastern',
 'southerly',
 'southern',
 'southwest',
 'southwesterly',
 'southwestern',
 'sovereign',
 'soviet',
 'spaceless',
 'spacious',
 'spaghettibender',
 'spaghettinigger',
 'spanish',
 'spank',
 'spankthemonkey',
 'spectroscopic',
 'sperm',
 'spermacide',
 'spermbag',
 'spermhearder',
 'spermherder',
 'spic',
 'spick',
 'spig',
 'spigotty',
 'spik',
 'spiks',
 'spirillum',
 'spiritual',
 'spit',
 'spitter',
 'splittail',
 'spooge',
 'spreadeagle',
 'spunk',
 'spunky',
 'squaw',
 'stagg',
 'steamy',
 'stfu',
 'stiff',
 'stiffy',
 'stoned',
 'strapon',
 'stringer',
 'strip',
 'stripclub',
 'stroke',
 'stroking',
 'stubby',
 'stupid',
 'stupidfuck',
 'stupidfucker',
 'submachine gun',
 'suck',
 'suckdick',
 'sucked',
 'sucker',
 'sucking',
 'suckme',
 'suckmyass',
 'suckmydick',
 'suckmytit',
 'suckoff',
 'suicide',
 'sumofabiatch',
 'superficial',
 'superior',
 'supernatural',
 'superolateral',
 'superonasal',
 'superotemporal',
 'superscript',
 'supine',
 'swallow',
 'swallower',
 'swalow',
 'swastika',
 'sweetness',
 'syphilis',
 't1t',
 'taboo',
 'taff',
 'taint',
 'tall',
 'tampon',
 'tang',
 'tantra',
 'tar-baby',
 'tarbaby',
 'tard',
 'tawdry',
 'teabagging',
 'teat',
 'teeth',
 'terd',
 'terrible',
 'terror',
 'terrorist',
 'terroristic',
 'teste',
 'testee',
 'testes',
 'testicle',
 'testicles',
 'thick',
 'thicklips',
 'thin',
 'thirdeye',
 'thirdleg',
 'thk',
 'threesome',
 'threeway',
 'thrust',
 'thug',
 'timbernigger',
 'tinkle',
 'tit',
 'titbitnipply',
 'titfuck',
 'titfucker',
 'titfuckin',
 'titi',
 'titjob',
 'titlicker',
 'titlover',
 'tits',
 'tittie',
 'tittiefucker',
 'titties',
 'titty',
 'tittyfuck',
 'tittyfucker',
 'tnt',
 'tobacco pipe',
 'tobelieve',
 'toilet',
 'toke',
 'tommy gun',
 'tongethruster',
 'tongue',
 'tonguethrust',
 'tonguetramp',
 'tonoise',
 'toots',
 'tortur',
 'torture',
 'tosser',
 'touch',
 'towel head',
 'towelhead',
 'toxic',
 'tragic',
 'trailertrash',
 'tramp',
 'trannie',
 'tranny',
 'transcendent',
 'transcendental',
 'transexual',
 'transsexual',
 'transvestite',
 'trashy',
 'traumatic',
 'trifling',
 'trinucleotide',
 'triplex',
 'triquetral',
 'trisexual',
 'trojan',
 'trots',
 'tubgirl',
 'tuckahoe',
 'tunneloflove',
 'turd',
 'turkey',
 'turkish',
 'turnon',
 'tush',
 'twat',
 'twats',
 'twink',
 'twinkie',
 'twit',
 'twobitwhore',
 'uck',
 'ugly',
 'uk',
 'unary',
 'unbiased',
 'undies',
 'unfuckable',
 'unwed',
 'upskirt',
 'uptheass',
 'upthebutt',
 'urethral',
 'urinal',
 'urinary',
 'urinate',
 'urine',
 'usama',
 'uterus',
 'uzi',
 'vag',
 'vagina',
 'vaginal',
 'valium',
 'vatican',
 'viagra',
 'vibr',
 'vibrater',
 'vibrator',
 'vietcong',
 'violence',
 'violent',
 'virgin',
 'virginbreaker',
 'vixen',
 'vodka',
 'vomit',
 'voyeur',
 'vulgar',
 'vulva',
 'w@ng',
 'wab',
 'wad',
 'wally',
 'wang',
 'wank',
 'wanker',
 'wanking',
 'warmonger',
 'waysted',
 'wazoo',
 'weapon',
 'wedgie',
 'weed',
 'weenie',
 'weewee',
 'weiner',
 'weirdo',
 'welcher',
 'welfare',
 'wench',
 'wet back',
 'wet dream',
 'wetb',
 'wetback',
 'wetspot',
 'wh0r3f@ce',
 'wh0re',
 'wh0ref@ce',
 'wh0reface',
 'whack off',
 'whacked off',
 'whacker',
 'whacking off',
 'whaddaya',
 'whash',
 'whigger',
 'whiskey',
 'whiskeydick',
 'whiskydick',
 'whit',
 'white trash',
 'whitenigger',
 'whites',
 'whitetrash',
 'whitey',
 'whiz',
 'whofe',
 'whop',
 'whoralicious',
 'whore',
 'whorealicious',
 'whoreface',
 'whorefucker',
 'whorehopper',
 'whorehouse',
 'whores',
 'wigger',
 'willie',
 'williewanker',
 'willy',
 'wn',
 'wog',
 'womb',
 "women's",
 'woody',
 'wop',
 'wtf',
 'wuss',
 'wuzzie',
 'x-rated',
 'xtc',
 'xxx',
 'yankee',
 'yeasty',
 'yellowman',
 'yobbo',
 'zigabo',
 'zipperhead',
 'zoophile'

];

bW = bW.map(word => word.toLowerCase())

export default bW;
