import React, { useState, useEffect, useRef, useMemo } from "react";
import tw, { css } from "twin.macro";
import "styled-components/macro";
import ContentPills from "./ContentPills";
import { Button } from "semantic-ui-react";
import SongSelectionAndTopics from "../../SongSelectionAndTopics/SongSelectionAndTopics";
import { useSubject } from "../../../context/TopicProvider";
import { useDevTool } from "../../../../lib/DevTool/DevTool";
import { compareStrings } from "../../../../lib/utils/sort";
import { useTopics } from "../../../context/TopicCatalog";

const ScrollBarStyle = css`
    ::-webkit-scrollbar {
        display: none;
    }
`;

/*
    The selection UI consists of a list of subjects, each subject contains a list of topics
 */

const SelectionUI = ({ onSkipTopics, onSelectTopic, subjects }) => {
    // handle selected topics
    const [selectedSubjectId, setSelectedSubjectId] = useState(null);
    const [selectedTopic, setTopic] = useState(null);

    // get the selected topic
    const selectedSubject = useSubject(selectedSubjectId);
    useDevTool("SelectionUI:selectedSubject", selectedSubject);

    // sort subjects by title
    const sortedSubjects = useMemo(() => {
        return Object.values(subjects ?? {}).sort((a, b) => compareStrings(a.title, b.title));
    }, [subjects]);
    useDevTool("SelectionUI:sortedSubjects", sortedSubjects);

    const subjectItems = useMemo(() => {
        return sortedSubjects?.map(subject => {
            const active = subject.id === selectedSubjectId;
            return (
                <div
                    tw="background[rgba(42, 38, 77, 0.5)] rounded-lg  mb-4 flex items-center height[65px] cursor-pointer hover:background[#632ACC]"
                    onClick={() => {
                        console.log("click", subject);
                        setSelectedSubjectId(subject.id);
                        setTopic(false);
                    }}
                    css={active && [tw`background[#1D215F] mt-0 relative`]}
                >
                    <div tw="text-white font-extrabold text-2xl ml-3.5">{subject.title}</div>
                </div>
            );

        });
    }, [subjects, selectedSubjectId]);


    // show the song selection
    // if (skipTopic) {
    //     return (
    //         <SongSelectionAndTopics
    //             topic={selectedTopic}
    //             goBackToTopic={() => {
    //                 setSkipTopic(false);
    //                 setTopic(null);
    //             }}
    //         />
    //     );
    // }

    const topics = useTopics(selectedSubjectId);

    // draw the topics for the selected subject
    const topicItems = useMemo(() => {
        return topics?.map(topic => {
            console.log(topic);
            return (
                <ContentPills
                    topic={topic}
                    name={topic.title}
                    isSelected={selectedTopic === topic.id}
                    setIsSelected={() => setTopic(topic.id)}
                />
            );
        });
    }, [topics, selectedTopic]);

    // select a topic
    return (
        <div tw="mx-auto w-full display[inline-flex] flex-row minHeight[100%] background[transparent]">


            {(subjects !== false) && <div tw="maxHeight[calc( 100vh - 170px)] overflow-y-auto flexBasis[33.333%] flex-grow" css={[ScrollBarStyle]}>
                {subjectItems}
            </div>}

            <div tw="relative flexBasis[66.6666667%] flex-grow flex flex-col overflow-y-auto">
                <div tw="background[#1D215F] flex-grow ml-6 px-9 rounded-xl py-5 overflow-y-scroll">
                    {topicItems}
                    {/*
                    <ContentPills name={"Mean, Median, and Mode"} isSelected={selectedTopic === "Mean, Median, and Mode"} setIsSelected={setTopic} />
                    <ContentPills name={"Central Tendencies 1"} isSelected={selectedTopic === "Central Tendencies 1"} setIsSelected={setTopic} />
                    */}
                </div>
                <div tw="mt-6 mb-3 flex justify-center">
{/*
                    <Button
                        tw="hover:opacity[75%] active:opacity[50%] py-2 px-8 bg-none rounded-full uppercase text-sm font-extrabold leading-normal border-2 border-white text-white mr-2"
                        onClick={onSkipTopics}
                    >
                        skip topic selection
                    </Button>
*/}

                    <Button
                        tw="py-2 px-8 bg-FrostBite rounded-full uppercase text-sm font-extrabold leading-normal text-white ml-2"
                        css={[
                            !selectedTopic &&
                            css`
                                opacity: 50%;
                                color: #dddddd;
                                cursor: auto;
                            `
                        ]}
                        onClick={() => {
                            if (selectedTopic) onSelectTopic(selectedTopic);
                        }}
                    >
                        select topic
                    </Button>
                </div>
            </div>

            <div tw="flexBasis[0px] flex flex-row mx-auto background[green]">
            </div>
        </div>
    );
};

export default SelectionUI;
