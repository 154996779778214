import React from 'react';

import styled from 'styled-components';
import PhraseList from './PhraseList';
import {CHORUS_COLOR, PHRASE_COLORS_1, PHRASE_COLORS_2, VERSE_COLOR} from '../song/style';
import {useProjectSection, useProjectSections, useProjectSongSections} from '../context/project/ProjectState';
import AudioSamplePlayer from "../context/AudioSamplePlayer";

const S = {
    Page: styled.div`
      width: 100%;
      background: linear-gradient(156.44deg, #2725aa 6.53%, #8149e8 80.2%);
      position: relative;
      flex-grow: 1;

      overflow-y: scroll;
      display: flex;
      justify-content: stretch;
      align-items: stretch;
    `,
    Container: styled.div`
      //width: 45px;
      //height: 45px;
      flex-grow: 1;
      margin: 50px;
      background: white;
      border-radius: 30px;
      position: relative;
      overflow: hidden;
    `,
    SectionListContainer: styled.div`
        //flex-grow: 1;
        //flex-shrink: 1;
        //background: green;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;

      .wrapper {
        position: relative;
        height: 100%;
        width: 100%;
        padding: 20px;
        overflow-y: scroll;
        overflow-x: hidden;
      }

      .page::after {
        content: '';
        position: absolute;
        z-index: -1;
        height: calc(100% - 20px);
        top: 10px;
        right: -1px;
        width: 5px;
        background: #666;
      }

      .wrapper::-webkit-scrollbar {
        display: block;
        width: 5px;
      }

      .wrapper::-webkit-scrollbar-track {
        background: transparent;
      }

      .wrapper::-webkit-scrollbar-thumb {
        background: #d5d5d5;
        border-radius: 999px;
        border-right: none;
        border-left: none;
      }

      .wrapper::-webkit-scrollbar-track-piece:end {
        background: transparent;
        margin-bottom: 10px;
      }

      .wrapper::-webkit-scrollbar-track-piece:start {
        background: transparent;
        margin-top: 10px;
      }

      input[type='checkbox'].switch + div span:nth-child(1) {
        margin-left: 15px;
      }

      input[type='checkbox'].switch + div span:nth-child(2) {
        margin-left: 55px;
      }

      input[type='checkbox'].switch:checked + div {
        width: 44px;
        background-position: 0 0;
        background-color: #3b89ec;
      }

      input[type='checkbox'].switch + div {
        width: 44px;
        height: 24px;
      }

      input[type='checkbox'].switch:checked + div {
        background-color: #00b1c9;
      }

      input[type='checkbox'].switch + div > div {
        float: left;
        width: 20px;
        height: 20px;
        border-radius: inherit;
        background: #ffffff;
      }

      &::-webkit-scrollbar-thumb {
      }

      .section-title {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        font-family: Avenir, serif;
        font-style: normal;
        font-weight: 900;
        text-align: start;
        font-size: 24px;
        line-height: 33px;
        text-transform: uppercase;
        color: #00b1c9;
        margin-left: 20px;
        margin-bottom: 8px;
      }

      .section-sub-text {
        font-family: Avenir, serif;
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        text-align: start;
        line-height: 12px;
        color: #00b1c9;
        margin-left: 20px;
        margin-bottom: 19px;
      }

      .switch-parent {
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 25px;

        .switch-label {
          font-weight: 800;
          font-size: 16px;
          color: #1d215f;
          margin-right: 90px;
        }

        .switch-container {
          position: absolute;
          top: 45%;
          right: 5%;
          -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
        }

        input[type='checkbox'].switch {
          position: absolute;
          opacity: 0;
        }

        input[type='checkbox'].switch + div {
          vertical-align: middle;
          width: 40px;
          height: 20px;
          border-radius: 999px;
          background-color: #8b8b8b;
          -webkit-transition-duration: 0.4s;
          transition-duration: 0.4s;
          -webkit-transition-property: background-color, box-shadow;
          transition-property: background-color, box-shadow;
          cursor: pointer;
        }

        input[type='checkbox'].switch + div span {
          position: absolute;
          font-size: 1.6rem;
          color: white;
          margin-top: 12px;
        }

        input[type='checkbox'].switch + div span:nth-child(1) {
          margin-left: 15px;
        }

        input[type='checkbox'].switch + div span:nth-child(2) {
          margin-left: 55px;
        }

        input[type='checkbox'].switch:checked + div {
          width: 44px;
          background-position: 0 0;
          background-color: #3b89ec;
        }

        input[type='checkbox'].switch + div {
          width: 44px;
          height: 24px;
        }

        input[type='checkbox'].switch:checked + div {
          background-color: #00b1c9;
          // =======
          //         //margin: 50px;
          //         //background: white;
          //         //border-radius: 30px;
          //
          //         //display: flex;
          //         //flex-direction: column;
          //         //align-items: stretch;
          //         //justify-content: flex-start;
          //
          //         //align-items: center;
          //         //justify-content: center;
          //         padding: 20px;
          //
          //         //overflow-y: scroll;
          //
          //         .section-title {
          //             font-family: Avenir, serif;
          //             font-style: normal;
          //             font-weight: 900;
          //             text-align: start;
          //             font-size: 24px;
          //             line-height: 33px;
          //
          //             text-transform: uppercase;
          //
          //             color: #00b1c9;
          //             margin-left: 20px;
          //             margin-bottom: 8px;
          // >>>>>>> 854fad4bbca30e648d80253f39404aa4591d4be3
        }

        input[type='checkbox'].switch + div > div {
          float: left;
          width: 20px;
          height: 20px;
          border-radius: inherit;
          background: #ffffff;
          -webkit-transition-timing-function: cubic-bezier(1, 0, 0, 1);
          transition-timing-function: cubic-bezier(1, 0, 0, 1);
          -webkit-transition-duration: 0.4s;
          transition-duration: 0.4s;
          -webkit-transition-property: transform, background-color;
          transition-property: transform, background-color;
          pointer-events: none;
          margin-top: 2px;
          margin-left: 2px;
        }

        input[type='checkbox'].switch:checked + div > div {
          -webkit-transform: translate3d(20px, 0, 0);
          transform: translate3d(20px, 0, 0);
          background-color: #ffffff;
        }

        input[type='checkbox'].bigswitch.switch + div > div {
          width: 44px;
          height: 44px;
          margin-top: 1px;
        }

        input[type='checkbox'].switch:checked + div > div {
          -webkit-transform: translate3d(46px, 0, 0);
          transform: translate3d(18px, 0, 0);
        }
      }
    `
};

const ChorusSwitch = ({sectionIndex, onChange, syncChorus}) => {
    const {setChorusSync} = useProjectSection(sectionIndex);

    return (
        <div className={'switch-parent'}>
            <div className={'switch-label'}>Same Chorus: {syncChorus ? 'On' : 'Off'}</div>
            <div className='switch-container'>
                <label>
                    <input checked={syncChorus} onChange={() => setChorusSync(!syncChorus)} className='switch' type='checkbox'/>
                    <div>
                        <div></div>
                    </div>
                </label>
            </div>
        </div>
    );
};

export const SectionEditorList = (props) => {
    const song_sections = useProjectSongSections();
    const proj_sections = useProjectSections();
    // console.log("XXX sections:", song_sections, proj_sections, props);

    // ensure we have song_sections
    if (!props.song || !song_sections) return null;

    // filter song section to match the selected tab - use all sections for tab '100'
    let sections = props.musicSection === 100 ? song_sections : song_sections.filter((section) => section.section_number === props.musicSection);

    // get the master chorus
    let choruses = song_sections.filter((s) => s.isChorus);
    let masterChorus = choruses.length >= 1 ? choruses[0] : null;

    // console.log('sections', sections)
    // console.log('choruses', choruses)
    // console.log('masterChorus', masterChorus)

    // generate the UI for each section we are editing
    let section_nodes = sections.map((section, i) => {
        let sectionColor = section.isChorus ? CHORUS_COLOR : VERSE_COLOR;
        let subText = `Write math lyrics for each phrase in ${section.song_section_name}.`;
        let isMasterChorus = masterChorus && section.section_number === masterChorus.section_number;
        let marginForFullSong = props.musicSection === 100 && '37px';

        // get the project section to check if this has been flagged to sync
        let sync_chorus = false;
        if (section.isChorus && !isMasterChorus) {
            let proj_section = proj_sections[section.section_number - 1] || {};
            // console.log("XXX: PROJ_SECTION", proj_section);
            sync_chorus = proj_section.sync_chorus;
            if (sync_chorus === undefined) sync_chorus = true;
            // console.log("XXX: sync_chorus", sync_chorus);
        }

        return (
            <div key={section.section_number} style={{marginTop: i === 0 ? '11px' : marginForFullSong}}>

                <div className={'section-title'} style={{color: sectionColor}}>
                    <div>{section.name || section.song_section_name}</div>
                    {((!props.isRecording && section.isChorus && !isMasterChorus) && (props.editMode !== 'record')) && <ChorusSwitch syncChorus={sync_chorus} sectionIndex={section.section_number - 1}/>}
                </div>

                {props.editMode === "lyrics" && (
                    <div className={'section-sub-text'} style={{ color: section.isChorus ? CHORUS_COLOR : VERSE_COLOR }}>
                        {props.musicSection !== 100 && subText}
                    </div>
                )}

                <PhraseList
                    activeSection={props.activeSection}
                    section={section}
                    section_index={section.section_number - 1}
                    colors={section.isChorus ? PHRASE_COLORS_2 : PHRASE_COLORS_1}
                    syncChorus={sync_chorus}
                    masterChorusSectionIndex={masterChorus?.section_number - 1}
                    editMode={props.editMode}
                    isRecording={props.isRecording}
                />
            </div>
        );
    });

    return (
        <S.SectionListContainer className={'sectionListContainer'}>
            <div className={'wrapper'}>{section_nodes}</div>
        </S.SectionListContainer>
    );
};

const SectionEditor = (props) => {
    return (
        <S.Page>
            <S.Container>
                <SectionEditorList song={props.song} editMode={props.editMode} activeSection={props.activeSection} />
            </S.Container>
        </S.Page>
    );
};

export default SectionEditor;
