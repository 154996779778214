import React from "react";
import styled from "styled-components";
import LyricsTab from "./LyricsTab";
import { MaxWidthContainer } from "../../common/MaxWidthLyricsPage";
const S = {
    Container: styled.div`
        width: 100%;
        padding-top: 10px;
        .tab-copy {
            font-weight: 800;
            font-size: 21.4348px;
            color: #fff;

            .img-container {
                padding-left: 10px;
                padding-top: 4px;
            }
        }
    `,

    TabsContainer: styled.div`
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid #C7C7FF;
        div:first-child {
            margin-left: 0;
        }
        div:last-child {
            margin-right: 0;
        }
    `
};

const LyricsTabContainer = (props) => {
    let numberOfTabs = props.tabs.length;
    return (
        <S.Container>
            <MaxWidthContainer>
                <S.TabsContainer>
                    {props.tabs.map((tab, index) => (
                        <LyricsTab
                            numberOfTabs={numberOfTabs}
                            key={index}
                            index={index}
                            color={tab.color}
                            hover={tab.hover}
                            tab_title={tab.title}
                            section_id={tab.section_id}
                            musicSection={props.musicSection}
                            setMusicSection={props.setMusicSection}
                        />
                    ))}
                </S.TabsContainer>
            </MaxWidthContainer>
        </S.Container>
    );
};

export default LyricsTabContainer;
