import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import tw, { css } from "twin.macro";
import "styled-components/macro";
import * as PropTypes from "prop-types";
import { AudioPlayerProvider } from "react-use-audio-player";
import { useHistory, useLocation } from "react-router-dom";

import LyricsTabContainer from "./tabs/LyricsTabContainer";
import LyricsSheet from "./LyricSheet";
import LightBulb from "../static/lightbulb.png";

import IdeasOverlay from "./rhymes-ideas-define/Ideas";
import TimelineSection from "./TimelineSection";
import { SampleSong } from "../song/SongEditorContainer";
import { sectionColor, sectionHover } from "./style";
import useSWR from "swr/esm";
import axios from "axios";
import ProjectProvider, {
    useProject,
    useProjectActions,
    useProjectCatalog,
    useProjectEditor,
    useProjectExitActions,
    useProjectSections,
    useProjectSong,
    useProjectSongSections
} from "../context/project/ProjectState";

import { MaxWidthContainer } from "../common/MaxWidthLyricsPage";
import TitleBar from "../common/TitleBar";
import TitleEdit from "../project/TitleEdit";
import PopUp from "../project/PopUp";
import { apiFetcher } from "../../api";
import ExitModal from "../project/ExitModal";
import RecordingTimelineSection from "./RecordingTimelineSection";
import SuperstarPowerBar from "../common/SuperstarPowerBar/SuperstarPowerBar";
import ModeButtons, { EditModes } from "./ModeButtons";

import AudioFilterMenu from "./RecordMode/AudioFilterMenu";
import PreviewMode from "./Preview/PreviewMode";
import { useQuery } from "../../lib/hooks/useQuery";
import FactSheet from "../common/FactSheet/FactSheet";

import VideoMode from "./VideoMode/VideoMode";
import { useTopic } from "../context/TopicCatalog";
import TopicView from "../common/TopicView/TopicView";

const BASE = {
    Button: styled.button`
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    `
};

const S = {
    Container: styled.div`
        position: relative;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        background: linear-gradient(156.44deg, #2725aa 6.53%, #8149e8 80.2%);
        user-select: none;
        overflow-y: auto;
    `,

    LyricsMainContent: styled.div`
        display: flex;
        flex-grow: 1;
        position: relative;
    `,
    LyricsEditorContainer: styled.div`
        width: 100%;
        height: 100%;
        max-width: 1086px;
        margin: 0 auto;
    `,
    LyricsSheetButtonContainer: styled.div`
        position: absolute;
        right: 0;
        //top: 100px;
        bottom: 0px;
        width: 100px;
        height: 50px;
        background: red;
    `,

    LyricSheetButton: styled.button`
        background: ${(props) =>
                props.backgroundColor
                        ? props.backgroundColor
                        : props.active
                                ? "linear-gradient(127.87deg, #E637A8 16.09%, #8149E8 83.8%)"
                                : "rgba(116, 5, 76, 1.0)"};
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        height: 45px;
        width: 45px;
        margin: auto;
        user-select: none;
        border: none;
        cursor: pointer;

        &:focus {
            outline: none;
        }

        &:active {
            opacity: 60%;
        }

        &:hover {
            background: ${(props) => props.hover};
        }
    `,

    LyricSheetButtonText: styled.div`
        color: white;
        font-weight: 600;
        font-size: 12px;
        margin-top: 2px;
    `,

    SectionContainer: styled.div`
        //position: absolute;
        width: 100%;
        //height: 100%;
        background: black;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: stretch;
    `,

    HideShowButton: styled.div`
        width: 100%;
        max-width: 1086px;
        margin: 0 auto;
        position: absolute;
        right: 35px;
        top: -33px;
        cursor: pointer;

        .flex {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;

            .button {
                background: #1d215f;
                border-radius: 5px 5px 0px 0px;
                padding: 10px;
                margin-right: 15px;
            }
        }
    `
};

const HideStarPower = css`
    @media only screen and (max-width: 1170px) {
        display: none;
    }
`;

function LyricsEditor(props) {
    return (
        <>
            <LyricsSheet
                song={props.song}
                musicSection={props.musicSection}
                editMode={props.editMode}
                activeSection={props.activeSection}
                isRecording={props.recording}
            />
            {props.showIdeasOpen && (
                <div tw="w-3/4 bg-white paddingTop[5.09rem]">
                    <IdeasOverlay open={props.showIdeasOpen} toggleIdeas={props.toggleIdeas} />
                </div>
            )}
            {props.showFactSheet && (
                <div tw="w-3/4 bg-white paddingTop[5.09rem]">
                    <FactSheet>
                        <TopicView
                            tw='border-radius[0] background[none]'
                            topic={props.topic}
                        />
                    </FactSheet>
                </div>
            )}

            <div
                tw="absolute right-0 top-0 background[#E2DDFF]  flex width[196px] py-2 px-8 justify-between"
                css={!props.showIdeasOpen && !props.showFactSheet && tw`borderRadius[0px 0px 0px 30px]`}
            >
                <div>
                    <S.LyricSheetButton
                        backgroundColor={props.showIdeasOpen ? "linear-gradient(127.87deg, #e9961a 16.09%, #dd6725 83.8%)" : "rgba(233, 150, 26, 0.5)"}
                        onClick={props.toggleIdeas}
                        hover={"linear-gradient(127.87deg, #e9961a 16.09%, #dd6725 83.8%)"}
                    >
                        <svg width="18" height="26" viewBox="0 0 18 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.99913 4.38167C6.45908 4.38167 4.39307 6.5267 4.39307 9.16389C4.39307 9.60426 4.73661 9.96093 5.16075 9.96093C5.58489 9.96093 5.92842 9.60426 5.92842 9.16389C5.92842 7.40543 7.3064 5.97574 8.99913 5.97574C9.42327 5.97574 9.76681 5.61907 9.76681 5.17871C9.76681 4.73834 9.42327 4.38167 8.99913 4.38167ZM5.16363 23.27C5.16363 23.4269 5.20825 23.5798 5.29221 23.7103L6.4682 25.5455C6.6107 25.7677 6.8506 25.9012 7.10729 25.9012H10.8905C11.1477 25.9012 11.3876 25.7677 11.5296 25.5455L12.7056 23.7103C12.7891 23.5798 12.8337 23.4264 12.8342 23.27L12.8366 21.119H5.16171L5.16363 23.27ZM8.99913 0.396484C4.09176 0.396484 0.554688 4.52962 0.554688 9.16389C0.554688 11.3742 1.34396 13.3907 2.64469 14.9315C3.44307 15.8774 4.69534 17.8606 5.15979 19.5224V19.5254H7.46282V19.5194C7.46234 19.2818 7.42827 19.0457 7.35966 18.8185C7.09145 17.9313 6.26476 15.592 4.37676 13.3553C3.39125 12.1882 2.86443 10.7077 2.86012 9.16389C2.85052 5.49553 5.72307 2.7876 8.99913 2.7876C12.3855 2.7876 15.1405 5.64796 15.1405 9.16389C15.1405 10.7067 14.6013 12.1951 13.622 13.3553C11.7455 15.5776 10.915 17.9119 10.6424 18.808C10.5721 19.0385 10.5362 19.2787 10.5359 19.5204V19.5254H12.839V19.5229C13.3034 17.8606 14.5557 15.8774 15.3541 14.932C16.6543 13.3907 17.4436 11.3742 17.4436 9.16389C17.4436 4.32189 13.6628 0.396484 8.99913 0.396484Z"
                                fill="white"
                                fillOpacity="1"
                            />
                        </svg>
                    </S.LyricSheetButton>
                    <S.LyricSheetButtonText color={"#fff"}>Ideas</S.LyricSheetButtonText>
                </div>
                <div>
                    <S.LyricSheetButton
                        backgroundColor={props.showFactSheet ? "linear-gradient(127.87deg, #e9961a 16.09%, #dd6725 83.8%)" : "rgba(233, 150, 26, 0.5)"}
                        onClick={props.toggleFactSheet}
                        hover={"linear-gradient(127.87deg, #e9961a 16.09%, #dd6725 83.8%)"}
                    >
                        <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M20.8887 0.205078H3.11089C1.88867 0.205078 0.888672 1.22982 0.888672 2.48228V18.4227C0.888672 19.6752 1.88867 20.6999 3.11089 20.6999H20.8887C22.1109 20.6999 23.1109 19.6752 23.1109 18.4227V2.48228C23.1109 1.22982 22.1109 0.205078 20.8887 0.205078ZM20.8887 18.4227H3.11089V2.48228H20.8887V18.4227Z"
                                fill="white"
                                fillOpacity="1"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M20.2338 8.65379L18.656 7.03697L15.1338 10.6463L13.5671 9.02953L12.0004 10.635L15.1338 13.8686L20.2338 8.65379ZM4.22266 4.75977H9.77821V7.03697H4.22266V4.75977ZM4.22266 9.31418H9.77821V11.5914H4.22266V9.31418ZM4.22266 13.8686H9.77821V16.1458H4.22266V13.8686Z"
                                fill="white"
                                fillOpacity="1"
                            />
                        </svg>
                    </S.LyricSheetButton>
                    <S.LyricSheetButtonText color={"#fff"}>Fact Sheet</S.LyricSheetButtonText>
                </div>
            </div>
        </>
    );
}

LyricsEditor.propTypes = {
    song: PropTypes.any,
    musicSection: PropTypes.number,
    editMode: PropTypes.string,
    activeSection: PropTypes.arrayOf(PropTypes.number),
    recording: PropTypes.bool,
    onClick: PropTypes.func,
    toggleIdeas: PropTypes.func,
    showIdeasOpen: PropTypes.bool
};

function LyricsRecordingEditor(props) {
    return (
        <>
            <LyricsSheet
                song={props.song}
                musicSection={props.musicSection}
                editMode={props.editMode}
                activeSection={props.activeSection}
                isRecording={props.recording}
            >
                {/*
                <AudioFilterMenu />
*/}
            </LyricsSheet>
        </>
    );
}

const LyricsContainer = (props) => {
    const history = useHistory();
    const query = useQuery();
    const [_, setSongEditMode] = useState(query.mode ?? EditModes.LYRICS);
    // useEffect(() => setSongEditMode(query.mode), [query]);
    const songEditMode = useMemo(() => query.mode ?? EditModes.LYRICS, [query]);

    const project = useProject();
    const topic = useTopic(project?.topic_id ?? null);
    const project_sections = useProjectSections();
    const { isProjectEmpty, isProjectModified, isProjectTemporary } = useProjectEditor();
    const song = useProjectSong();
    const sections = useProjectSongSections();
    const [projects, deleteProject] = useProjectCatalog();

    const [showExitModal, setShowExitModal] = useState(false);
    const [musicSection, setMusicSection] = useState(1);
    const [isTimeLineShown, setTimeLineShown] = useState(true);
    const [showIdeasOpen, setShowIdeas] = useState(false);
    const [showFactSheet, setFactSheet] = useState(false);

    const [isRecording, setIsRecording] = useState(false);

    // active section
    const [activeSection, setActiveSection] = useState([0, 1]);
    const changeActiveSection = useCallback((currentActiveSection) => {
        if (currentActiveSection[0] !== activeSection[0] || currentActiveSection[1] !== activeSection[1])
            setActiveSection(currentActiveSection);
    }, [activeSection]);

    // React.useEffect( () => {
    //     window.addEventListener("popstate", () => {
    //         history.go(1);
    //     });
    //
    // }, []);
    /*
    history.listen((newLocation, action) => {
        console.log("history action:", action, newLocation);
        if (action === "PUSH") {
            // if (
            //     newLocation.pathname !== this.currentPathname ||
            //     newLocation.search !== this.currentSearch
            // ) {
            //     // Save new location
            //     this.currentPathname = newLocation.pathname;
            //     this.currentSearch = newLocation.search;
            //
            //     // Clone location object and push it to history
            //     history.push({
            //         pathname: newLocation.pathname,
            //         search: newLocation.search
            //     });
            // }
            // if (locationChanges) {
                // Clone location object and push it to history
                // history.push({
                //     pathname: newLocation.pathname,
                //     search: newLocation.search
                // });
            // }
        } else {
            // If a "POP" action event occurs,
            // Send user back to the originating location
            history.go(1);
        }
    });
    */


    // const song = useSong();
    // if (!song) return null;
    // console.log('  Project:', project);
    // console.log('     Song:', song);

    const handleChangeHideShowTimeline = () => {
        setTimeLineShown((prevState) => !prevState);
    };

    const tabs = (sections) => {
        let tabs = sections.map((section, i) => {
            return {
                title: section.song_section_name,
                color: sectionColor(section.isChorus),
                hover: sectionHover(section.isChorus),
                section_id: section.section_number
            };
        });
        tabs.push({
            title: "FULL SONG",
            color: "#8149E8",
            hover: "linear-gradient(127.87deg, #8149E8 16.09%, #0F179A 83.8%)",
            section_id: 100
        });
        return tabs;
    };

    let toggleIdeas = () => {
        setShowIdeas(!showIdeasOpen);
        setFactSheet(false);
    };

    let toggleFactSheet = () => {
        setFactSheet(!showFactSheet);
        setShowIdeas(false);
    };

    const handleExit = () => {
        // check if this is an unmodified temp project

        if (isProjectTemporary() && !isProjectModified()) {
            // this is only a draft - destroy the project and go back
            deleteProject(project.id);
            history.goBack();
        } else {
            // ask what they want to do
            setShowExitModal(true);
        }
    };
    const [lastSection, setLastSection] = useState(1);
    const selectSection = (index) => {
        setMusicSection(index);
        setLastSection(index);
    };

    useEffect(() => {
        // show all sections when switching to recording mode
        if (songEditMode === EditModes.RECORD) {
            setMusicSection(100);
        } else if (songEditMode === EditModes.LYRICS) {
            setMusicSection(lastSection);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [songEditMode]);

    // a song has to be loaded
    if (!song)
        return null;

    return (
        <S.Container className={"lyrics-container"}>
            <TitleBar title={<TitleEdit text={project.name} />} closeButton={true} onClose={handleExit} lyricCreationPage={true} showCollab={false} />
            <ExitModal onClose={() => setShowExitModal(false)} isModalOpen={showExitModal} />

            {songEditMode === EditModes.LYRICS && (
                <>
                    <LyricsTabContainer tabs={tabs(sections)} musicSection={musicSection} setMusicSection={selectSection} />
                    <MaxWidthContainer style={{ display: "flex" }}>
                        <S.LyricsMainContent className={"lyrics-main-content"}>
                            <LyricsEditor
                                song={song}
                                topic={topic}
                                musicSection={musicSection}
                                editMode={songEditMode}
                                activeSection={activeSection}
                                recording={isRecording}
                                onClick={toggleIdeas}
                                showIdeasOpen={showIdeasOpen}
                                toggleIdeas={toggleIdeas}
                                showFactSheet={showFactSheet}
                                toggleFactSheet={toggleFactSheet}
                            />
                        </S.LyricsMainContent>

                        {/*
                    <S.LyricsSheetButtonContainer>
                        <S.HideShowButton isTimeLineShown={isTimeLineShown}>
                            <div className={"flex"}>
                                <div onClick={handleChangeHideShowTimeline} className={"button"}>
                                    <img src={isTimeLineShown ? WhiteChevronDown : WhiteChevronUp} alt="White Arrow Down" />
                                </div>
                            </div>
                        </S.HideShowButton>
                    </S.LyricsSheetButtonContainer>
                    */}
                    </MaxWidthContainer>
                </>
            )}

            {songEditMode === EditModes.RECORD && (
                <S.LyricsMainContent
                    className={"lyrics-main-content"}
                    tw="margin[0 100px 0 20px] sm:margin[0 120px 0 40px] lg:margin[0 157px 0 82px] borderTopRightRadius[30px] borderTopLeftRadius[30px] overflow[hidden]"
                >
                    <LyricsRecordingEditor
                        song={song}
                        musicSection={musicSection}
                        editMode={songEditMode}
                        activeSection={activeSection}
                        recording={isRecording}
                        onClick={toggleIdeas}
                    />
                </S.LyricsMainContent>
            )}

            {songEditMode === EditModes.VIDEO && (
                <S.LyricsMainContent className={"lyrics-main-content"}>
                    <VideoMode activeSection={activeSection} />
                </S.LyricsMainContent>
            )}

            {songEditMode === EditModes.PREVIEW && (
                <S.LyricsMainContent className={"lyrics-main-content"}>
                    {/* <div tw="w-full marginBottom[50px] marginLeft[36px] marginRight[108px]">
                        <img src={PreviewModeImage} width={"100%"} height={"auto"} />
                    </div> */}
                    <PreviewMode activeSection={activeSection} />
                </S.LyricsMainContent>
            )}

            <ModeButtons setSongEditMode={setSongEditMode} songEditMode={songEditMode} />

            {songEditMode !== EditModes.PREVIEW && songEditMode !== EditModes.RECORD && <></>}

            {(songEditMode !== EditModes.PREVIEW && songEditMode !== EditModes.VIDEO) && (
                <AudioPlayerProvider>
                    {(isTimeLineShown || songEditMode === EditModes.RECORD) &&
                        (songEditMode === EditModes.RECORD ? (
                            <RecordingTimelineSection
                                key={"recording"}
                                editMode={songEditMode}
                                className={"section-timeline"}
                                song={song}
                                musicSection={musicSection}
                                setActiveSection={changeActiveSection}
                                isRecording={isRecording}
                                setIsRecording={setIsRecording}
                            />
                        ) : (
                            <TimelineSection
                                key={"lyrics"}
                                editMode={songEditMode}
                                className={"section-timeline"}
                                song={song}
                                musicSection={musicSection}
                                setActiveSection={changeActiveSection}
                                isRecording={isRecording}
                                setIsRecording={setIsRecording}
                            />
                        ))}
                </AudioPlayerProvider>
            )}
            {songEditMode === EditModes.LYRICS && (
                <div tw="absolute left-5 lg:left[3.2rem] top[140px] " css={[HideStarPower]}>
                    <div
                        tw="text-white text-sm font-bold whitespace-nowrap absolute right[-10px] top[220px]"
                        css={[
                            css`
                                transform: rotate(-90deg);
                            `
                        ]}
                    >
                        Superstar Power
                    </div>
                    <SuperstarPowerBar />
                </div>
            )}

            {/*
            <IdeasOverlay open={showIdeasOpen} toggleIdeas={toggleIdeas} />
            */}
        </S.Container>
    );
};

export default LyricsContainer;
