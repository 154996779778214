import React, {useState, useEffect} from 'react';
import styled from "styled-components";
import {PHRASE_COLORS_1, PHRASE_COLORS_2, SECTION_COLORS, INACTIVE_COLORS} from "../../song/style";
import {useProjectSong, useProjectSongSections} from "../../context/project/ProjectState";
import {useSongAudio} from "../../context/SongAudio"
import {formatPercent} from "./SongTimeline";

const S = {
    Container: styled.div`
      width: 100%;
      height: 34px;
      background: white;

      display: flex;
      flex-direction: row;
      justify-content: stretch;
      align-items: stretch;
      margin-top: 6px;
    `,
    SectionContainer: styled.div`
      background: #00B1C9;
      flex-grow: 1;
      //background: orange;
    `,
    Section: styled.div`
      background: #00B1C9;
      flex-grow: 1;
      position: relative;

      display: flex;
      align-items: stretch;
      justify-content: stretch;
      flex-direction: column;

      .title {
        position: relative;
        top: 10px;
        color: #ffffff;
        text-transform: uppercase;
        font-family: Avenir, serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 900;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
      }

      .line {
        /* position: fixed; */
        position: absolute;
        height: 34px;
        width: 0px;
        border: 1px solid #1F2365;
        padding-top: -10px;
        left: 0;
        /* z-index: 100 */
      }
    `,
    Phrase: styled.div`
      background: #00B1C9;
      /* flex-grow: 1; */
      width: 23%
    `,

    PhrasesContainer: styled.div`
      flex-grow: 1;

      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: stretch;

      position: absolute;
      left: 0;
      top: 0;
      right: 0;

    `
};

const Section = (props) => {
    let phrases = [];
    let colors = props.isChorus ? PHRASE_COLORS_2 : PHRASE_COLORS_1;
    if (props.sectionIndex + 1 !== props.activeSection && props.activeSection !== 100) {
        colors = INACTIVE_COLORS;
    }

    if (props.section && props.splitPhraseLengths !== null) {
        // console.log(props.splitPhraseLengths)
        for (let i = 0; i < props.section.phrase_count; i++) {

            let sum = (props.splitPhraseLengths[props.sectionIndex].reduce((a, b) => a + b, 0));

            let width = props.splitPhraseLengths[props.sectionIndex][i] / sum;

            phrases.push(
                <S.Phrase key={i} style={{ background: colors[i % colors.length], height: 10, width: formatPercent(width) }} />
            );
        }
        // phrases = props.section.phrases.map((phrase, i) => {
        //     return (
        //         <S.Section style={{background: SECTION_COLORS[i % SECTION_COLORS.length], height: 10}}>
        //         </S.Section>
        //     )
        // })
    }
    return (
        <S.Section className={'section-container'} style={{ background: colors[2], width: formatPercent(props.width) }}>

            <S.PhrasesContainer>
                {phrases}
            </S.PhrasesContainer>

            <div className={'title'}>{props.name}</div>
            {
                props.sectionIndex !== 0 && <div className={'line'}></div>
            }

        </S.Section>
    );
};

const SectionTrack = (props) => {
    const song = useProjectSong();
    const song_sections = useProjectSongSections();

    /* const songAudio = useSongAudioFile("69C3AA15-426A-483D-9813-DF6853CD2AD5")  */
    /* const songAudio = useSongAudio() */

    /* const song = useSong("69C3AA15-426A-483D-9813-DF6853CD2AD5")
    const song_data = song ? song.data || {} : {};

    const base = "https://muzology-media.s3.amazonaws.com/media.muzology.com/ace/songs/"
    const file = song_data.wav
    const full = `${song ? song.media_url : base}${file}`


    const {duration} = useAudioPlayer({
        src: full,
        format: "mp3",
        autoplay: false,
        onend: () => console.log("sound has ended!") 
    }) */


    const [splitLists, setSplitLists] = useState(null);

    useEffect(() => {
        // console.log(song_sections)
        /* console.log(songDuration, "dude") */
        // console.log(props.duration, "dude")
        let timeStamps = [];
        let lengths = [];
        let timeStampsList = song_sections.map(sect => sect.phrase_timestamps);
        if (timeStampsList) {

            // get a list of all timestamps
            timeStampsList.forEach(list => {
                if (list) {
                    list.forEach(ts => {
                        timeStamps.push(ts);
                    });
                }
            });
            timeStamps.push(props.duration);
            // console.log(timeStamps, "dude")


            for (let i = 0; i < timeStamps.length; i++) {
                if (i != timeStamps.length - 1) {
                    let sum = timeStamps[i + 1] - timeStamps[i];
                    lengths.push(sum);
                }
            }

        }

        /* lengths.shift() */
        // console.log(lengths)


        let phraseCounts = song_sections.map(sect => sect.phrase_count);

        let splitLists = [];
        let starter = 0;

        for (let i = 0; i < phraseCounts.length; i++) {
            if (i === 0) {
                splitLists.push(lengths.slice(0, phraseCounts[i]));
                starter = phraseCounts[i];
            } else {
                splitLists.push(lengths.slice(starter , (phraseCounts[i] + starter )))
                starter = phraseCounts[i] + starter
            }
        }

         // console.log(splitLists, "hey")
        setSplitLists(splitLists)

    }, [props.duration]);


    let sections = null;
    if (song_sections) {
        // console.log(song);
        // calculate the total number of beats for the entire song
        const total_beats = song_sections.reduce((beats, section) => beats + section.song_section_length * 4, 0);
        const seconds_per_beat = 1.0 / (song.data.BPM / 60);
        // console.log("Song, total beats: ", total_beats, total_beats * seconds_per_beat, seconds_per_beat);
        sections = song_sections.map((section, i) => {
            const section_beats = section.song_section_length * 4;
            const section_scale = section_beats / total_beats;
            // console.log("   section", section_scale,  section.section_number, section.song_section_length, section.song_section_length * 4 * seconds_per_beat);
            return (
                <Section
                    key={section.section_number}
                    width={section_scale}
                    activeSection={props.musicSection}
                    section={section}
                    splitPhraseLengths={splitLists}
                    sectionIndex={i}
                    isChorus={section.isChorus}
                    name={section.song_section_name}
                    percentComplete={props.percentComplete}
                />
            );
        });
    }
    return (
        <S.Container className={'SectionTrackContainer'}>
            {sections}
            {/*
            <S.SectionContainer className={'SectionContainer'}>
            </S.SectionContainer>
            */}
        </S.Container>
    );
};

export default SectionTrack;