import React from 'react';
import styled from 'styled-components'
import Placeholder from "../common/Placeholder";

const S = {
    Container: styled.div``,
}

const Discover = (props) => {
    return (
        <Placeholder text={"Discover"}/>
    );
};


export default Discover;
