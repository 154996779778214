import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { buildPath } from "../../../api";
import ReactModal from "react-modal";
import { XIcon } from "../../home/images";
import styled from "styled-components";

const S = {
    ArtistListContainer: styled.div`
        background: #1f2365;
        width: 95%;
        padding: 45px;
        border-radius: 30px;
        /* min-height: 714px; */
        margin-bottom: 2rem;
    `,
    Container: styled.div`
        max-width: 800px;
        width: 100%;
        margin: 0 auto;
    `,
    StyledLink: styled(Link)`
        text-decoration: none;
        display: block;
        color: white;
    `,
    Title: styled.h2`
        color: white;
        font-size: 21px;
        margin: 2rem 0;
        text-align: center;
    `,
    IconContainer: styled.span`
        margin-left: 8px;
    `,
    XIconContainer: styled.div`
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(45%, -30%);
        z-index: 1000;
        cursor: pointer;
    `,
    ArtistCard: styled.div`
        border-radius: 30px;
        height: 110px;
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        background: linear-gradient(127.87deg, #00b1c9 16.09%, #8149e8 83.8%);

        &:hover {
            background: linear-gradient(1deg, #00b1c9 16.09%, #8149e8 83.8%);
        }
    `,
    ArtistImage: styled.img`
        height: 110px;
        width: 122px;
        object-fit: cover;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
    `,
    ArtistImageContainer: styled.div`
        height: 110px;
    `,
    ArtistTitle: styled.h1`
        color: white;
        font-size: 24px;
        margin-left: 30px;
    `,
    Song: styled.div`
        background: linear-gradient(127.87deg, #00b1c9 16.09%, #8149e8 83.8%);
        border-radius: 50px;
        display: flex;
        align-items: center;
        height: 54px;
        margin-bottom: 42px;

        ${({ isSelected }) =>
                isSelected &&
                `
    background: #E637A8;
  `}
        &:hover {
            cursor: pointer;
        }
    `,
    SongTitle: styled.h2`
        user-select: none;
        color: white;
        font-size: 24px;
        margin: 0;
        margin-left: 18px;
    `,
    SongIconContainer: styled.div`
        margin-left: 8px;
        margin-top: 4px;
    `,

    Button: styled.button`
        width: 246px;
        height: 48px;
        border: none;
        background: #8b8b8b;
        color: #d5d5d5;
        font-size: 24px;
        font-weight: 800;
        text-transform: uppercase;
        border-radius: 50px;
        margin-top: 24px;
        margin-bottom: 2rem;
        margin-left: 20px;
        margin-right: 20px;
        text-align: center;
        cursor: pointer;

        &.continue {
            background: #e637a8;
            color: #ffffff;
        }

        &.cancel {
            background: #00b1c9;
            color: #ffffff;
        }

        &.disabled {
            cursor: unset;
            background: #8b8b8b;
            color: #d5d5d5;
        }

        ${({ disabled }) =>
                disabled &&
                `
        cursor: unset;
        background: #8B8B8B;
        color: #D5D5D5;
      `}
        &:hover {
            background: linear-gradient(127.87deg, #00b1c9 16.09%, #8149e8 83.8%);
        }
    `,

    ModalTitle: styled.h1`
        color: white;
        font-size: 24px;
        text-align: center;
    `,
    ModalSubTitle: styled.p`
        color: white;
        font-size: 16px;
        max-width: 400px;
        margin: 0 auto;
        text-align: center;
    `,
    ModalInput: styled.input`
        width: 100%;
        border-radius: 5px;
        height: 59px;
        margin: 32px 0;
        font-size: 24px;
        color: #00b1c9;
        font-weight: 800;
        text-align: center;

        &:focus {
            border: 5px solid #00b1c9;
        }
    `,

    Form: styled.form`
        max-width: 700px;
        width: 600px;
        display: flex;
        flex-direction: column;
    `,

    ActionsContainer: styled.div`
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    `,
    CancelButton: styled.button`
        width: 182px;
        height: 40px;
        border: none;
        background: #00b1c9;
        color: white;
        font-size: 16px;
        font-weight: 800;
        text-transform: uppercase;
        border-radius: 50px;
        cursor: pointer;

        &:hover {
            background: linear-gradient(127.87deg, #00b1c9 16.09%, #8149e8 83.8%);
        }
    `,
    SaveButton: styled.button`
        width: 182px;
        height: 40px;
        border: none;
        background: #e637a8;
        color: white;
        font-size: 16px;
        font-weight: 800;
        text-transform: uppercase;
        border-radius: 50px;
        margin-left: 32px;
        cursor: pointer;

        &:hover {
            background: linear-gradient(127.87deg, #e637a8 16.09%, #8149e8 83.8%);
        }

        ${({ disabled }) =>
                disabled &&
                `
            cursor: unset;
            background: #8B8B8B;
            color: #D5D5D5;

            &:hover {
                background: #8B8B8B;
                cursor: default;
            }
      `}
    `,
    SelectButton: styled.button`
        background: #00b1c9;
        width: 103px;
        height: 40px;
        border: none;
        font-weight: 800;
        border-radius: 50px;
        margin-left: auto;
        margin-right: 14px;
        color: white;
        font-size: 16px;
        text-transform: uppercase;
        cursor: pointer;

        &:hover {
            border: 2px solid #ffffff;
        }
    `
};


const CreateProjectModal = (props) => {
    const history = useHistory();
    const [songName, setSongName] = useState(`New Song (${props.song ? props.song.name || "" : ""})`);

    console.log("New song", props);

    React.useEffect(() => {
        setSongName(`New Song (${props.song ? props.song.name || songName : songName})`);
    }, [props.song]);

    // const [song] = useSong(props.songId);
    // const createProject = useCreateProject();
    // const {project, error, creating} = useNewProject(songId);

    const handleChange = (e) => {
        if (e.key === "Enter") {
            history.push(createURL);
        }
        setSongName(e.target.value);
    };

    const closeModal = () => {
        // props.onClosed()
        props.onClose(false);
    };

    // generatePath("/create/:projectId", {projectId: song.id});
    const songId = props.song ? props.song.id || "" : "";
    let createURL = buildPath("/create", songId, "/", songName, "/", props.topic ?? "");
    // console.log('CreateProjectModal', props);
    // console.log('          song', props.song);
    // console.log('    create URL', createURL);

    function handleSubmit(e) {
        e.preventDefault();
        history.push(createURL);
    }

    return (
        <ReactModal
            ariaHideApp={false}
            isOpen={props.show}
            onRequestClose={closeModal}
            shouldCloseOnOverlayClick={false}
            style={{
                overlay: {
                    backgroundColor: "rgba(125, 38, 223, 0.5)",
                    overflow: "visible"
                },
                content: {
                    top: "50%",
                    left: "50%",
                    right: "auto",
                    bottom: "auto",
                    marginRight: "-50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "#1F2365",
                    border: "none",
                    borderRadius: "30px",
                    padding: "42px 56px",
                    overflow: "visible"
                }
            }}
        >
            <S.ModalTitle>NAME YOUR SONG</S.ModalTitle>
            <S.ModalSubTitle>No pressure. You can change your song title anytime.</S.ModalSubTitle>
            <S.Form onSubmit={(e) => handleSubmit(e)}>
                <S.ModalInput
                    value={songName}
                    onChange={handleChange}
                    placeholder={"Enter Song Name"}
                    autoFocus
                    onFocus={(e) => e.currentTarget.setSelectionRange(0, songName.length)}
                    type="text"
                />
                <S.ActionsContainer>
                    <S.CancelButton onClick={closeModal} type="button">
                        Cancel
                    </S.CancelButton>
                    <S.SaveButton disabled={!songName} type="submit">
                        Continue
                    </S.SaveButton>
                </S.ActionsContainer>
            </S.Form>
            <S.XIconContainer onClick={closeModal}>
                <XIcon />
            </S.XIconContainer>
        </ReactModal>
    );
};


export default CreateProjectModal;
