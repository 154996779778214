import React, { useState } from "react";
import styled from "styled-components";
import { useArtistCatalog } from "../context/ArtistCatalogProvider";
import MuzologyPlaceholderImage from "../../assets/muzology-placeholder-icon.png";
import { useRouteMatch, Link } from "react-router-dom";
import { useProjectCatalog, useStudentProjects } from "../context/project/ProjectState";
import PopUp from "../project/PopUp";
import BalanceText from "react-balance-text";
import { ScaleLoader, ClipLoader, FadeLoader, PuffLoader, BeatLoader } from "react-spinners";
import { compareStrings } from "../../lib/utils/sort";

const S = {
    MySongListContainer: styled.div`
        background: #1f2365;
        width: 100%;
        padding: 48px;
        padding-top: 64px;
        border-radius: 30px;
        /* min-height: 714px; */
        margin-bottom: 2rem;
        margin-top: 68px;
        position: relative;
    `,
    BubbleLabel: styled.div`
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(0, -50%);
        width: 400px;
        padding: 11px;
        border-radius: 30px 30px 30px 0px;
        background-color: white;
    `,
    BubbleText: styled.p`
        text-align: center;
        font-size: 28px;
        margin: 0;
        background: -webkit-linear-gradient(#e637a8, #8149e8);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 800;
    `,
    Container: styled.div`
        max-width: 668px;
        width: 100%;
        margin: 0 auto;
    `,
    StyledLink: styled(Link)`
        text-decoration: none;
        color: white;
        display: block;
        width: 100%;
        margin-bottom: 20px;
        margin-right: 16px;
    `,
    Title: styled.h2`
        color: white;
        font-size: 21px;
        margin: 2rem 0;
        text-align: center;
    `,
    IconContainer: styled.span`
        margin-left: 8px;
    `,
    MySongsCard: styled.div`
        border-radius: 30px;
        width: 100%;
        display: flex;
        align-items: center;
        margin-right: 16px;
        background: linear-gradient(127.87deg, #e637a8 16.09%, #8149e8 83.8%);

        &:hover {
            background: linear-gradient(1deg, #e637a8 16.09%, #8149e8 83.8%);
        }

        &:active {
            background: linear-gradient(1deg, #e637a8 16.09%, #8149e8 83.8%);
            opacity: 0.5;
        }

    `,
    MySongImage: styled.img`
        height: 110px;
        width: 122px;
        object-fit: cover;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
    `,
    MySongImageContainer: styled.div`
        height: 110px;
    `,
    MySongTitle: styled.h1`
        color: white;
        font-size: 24px;
        margin-left: 30px;
        text-align: left;
    `,
    Song: styled.div`
        background: linear-gradient(127.87deg, #00b1c9 16.09%, #8149e8 83.8%);
        border-radius: 50px;
        display: flex;
        align-items: center;
        padding: 8px;
        margin-bottom: 42px;

        ${({ isSelected }) =>
                isSelected &&
                `
          background: #E637A8;
        `}
        &:hover {
            cursor: pointer;
        }
    `,
    SongTitle: styled.h2`
        user-select: none;
        color: white;
        font-size: 24px;
        margin: 0;
        margin-left: 18px;
    `,
    MySongContainer: styled.div`
        display: flex;
        align-items: center;
    `,
    SongIconContainer: styled.div``,
    Button: styled.button`
        width: 292px;
        height: 57px;
        border: none;
        background: #8b8b8b;
        color: #d5d5d5;
        font-size: 24px;
        font-weight: 800;
        text-transform: uppercase;
        border-radius: 50px;
        margin-top: 24px;
        margin-bottom: 2rem;
        text-align: center;

        ${({ disabled }) =>
                !disabled &&
                `
        cursor: pointer;
        background: #E637A8;
  `}
    `,
    ModalTitle: styled.h1`
        color: white;
        font-size: 24px;
        text-align: center;
    `,
    ModalSubTitle: styled.p`
        color: white;
        font-size: 16px;
        max-width: 400px;
        margin: 0 auto;
        text-align: center;
    `,
    ModalInput: styled.input`
        width: 100%;
        border-radius: 5px;
        height: 59px;
        margin: 32px 0;
        font-size: 24px;
        color: #00b1c9;
        font-weight: 800;
    `,
    Actions: styled.div`
        display: flex;
        justify-content: space-between;
        max-width: 405px;
        margin: 0 auto;
    `,
    ActionTitle: styled.h1`
        font-weight: 800;
        font-size: 23px;
        color: white;
        margin-bottom: 40px;
        text-align: center;
    `,
    ProjectName: styled.p`
        font-weight: 800;
        font-size: 23px;
        color: white;
        margin-bottom: 42px;
        text-align: center;
    `,
    DeleteProjectButton: styled.button`
        background: #E637A8;
        border-radius: 999px;
        width: 182.26px;
        height: 40px;
        border: none;
        color: white;
        font-weight: 800;
        cursor: pointer;
    `,
    CancelDeleteButton: styled.button`
        background: #00B1C9;
        border-radius: 999px;
        width: 182.26px;
        height: 40px;
        border: none;
        color: white;
        font-weight: 800;
        cursor: pointer;
    `,
    DeleteButton: styled.div`
        cursor: pointer;
        color: #fff;

        &:hover {
            color: #ddd;
        }

        &:active {
            color: #aaa;
        }
    `
};

export const LoadingAnimation = ({ text }) => {
    return (
        <div style={{ height: 100, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", fontSize: 20, color: "#ffffff" }}>
            <BeatLoader color="#ffffff" />
            <div style={{ marginTop: 10 }}>{text || "Loading please wait..."}</div>
        </div>
    );
};


function Song({ project, handleDeleteConfirmation }) {
    // console.log('Song ', project);
    return (
        <S.MySongContainer>
            <S.StyledLink to={`/edit/${project.id}`} key={project.id}>
                <S.MySongsCard>
                    <S.MySongImageContainer>
                        <S.MySongImage src={MuzologyPlaceholderImage} />
                    </S.MySongImageContainer>
                    <S.MySongTitle>{project.name}</S.MySongTitle>
                </S.MySongsCard>
            </S.StyledLink>
            <S.DeleteButton onClick={() => handleDeleteConfirmation({ id: project.id, name: project.name })} style={{ cursor: "pointer" }}>
                <svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M25.4127 22.2352L4.76595 1.58846C3.8888 0.711312 2.46667 0.711312 1.58952 1.58846C0.712374 2.4656 0.712374 3.88774 1.58952 4.76489L22.2363 25.4117C23.1135 26.2888 24.5356 26.2888 25.4127 25.4117C26.2899 24.5345 26.2899 23.1124 25.4127 22.2352Z"
                        fill="currentColor"
                    />
                    <path
                        d="M22.235 1.58824L1.58821 22.235C0.711068 23.1122 0.711068 24.5343 1.58821 25.4115C2.46536 26.2886 3.8875 26.2886 4.76464 25.4115L25.4114 4.76467C26.2886 3.88753 26.2886 2.46539 25.4114 1.58825C24.5343 0.711099 23.1121 0.711098 22.235 1.58824Z"
                        fill="currentColor"
                    />
                </svg>
            </S.DeleteButton>
        </S.MySongContainer>
    );
}

function StudentsSongList() {
    const [isModalOpen, setModalOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const [selectedProject, setSelectedProject] = useState();
    const [projects, loading, error] = useStudentProjects();
    // if (projects) projects.sort((a, b) => a.name.localeCompare(b.name));

    // sort the projects
    if (projects)
        projects.sort((a, b) => compareStrings(a.name, b.name));

    let validProjects = projects;
    if (projects) validProjects = projects.filter(proj => !proj.draft);

    if (loading) {
        return <LoadingAnimation />;
    }

    if (error) {
        return (
            <div style={{ height: 50, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", fontSize: 20, color: "#ffffff", fontFamily: "Avenir" }}>
                <div>There was an error, please try again.</div>
                <div>{error.message}</div>
            </div>
        );
    }
    if (!validProjects || !validProjects.length) {
        return (
            <BalanceText style={{ height: 50, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", fontSize: 20, color: "#ffffff", fontFamily: "Avenir" }}>
                Browse artists to select a tune you like and start creating YOUR math song!
            </BalanceText>
        );
    }

    return (
        <>
            {validProjects && validProjects.map((project) => {
                return <Song project={project} handleDeleteConfirmation={false} key={project.id} />;
            })}
        </>
    );
}

const StudentSongs = () => {
    return (
        <S.Container>
            <S.MySongListContainer>
                <S.BubbleLabel>
                    <S.BubbleText>MY STUDENT'S SONGS</S.BubbleText>
                </S.BubbleLabel>
                <StudentsSongList />
            </S.MySongListContainer>
        </S.Container>
    );
};

export default StudentSongs;
