import React from "react";
import styled from "styled-components";

export const MaxWidthContainer = styled.div`
    font-family: Avenir, serif;
    width: 100%;
    height: 100%;
    max-width: 1086px;
    margin: 0 auto;

    @media only screen and (max-width: 1240px) {
        max-width: 986px;
    }
`;


