import React, { useMemo, createContext, useCallback, useContext, useEffect, useState } from 'react';
import 'twin.macro';
import { useHistory, useLocation } from 'react-router-dom';
// import { handleAPIError } from 'utils/api-client';
import axios from 'axios';
// import { useError } from '../ErrorProvider';
// import { formatNetworkError, handleNetworkError } from '../../lib/axios';
// import { MUZOLOGY_API_URL } from '../../config';
const MUZOLOGY_API_URL = process.env.REACT_APP_MUZOLOGY_API_URL;
export const AuthContext = createContext(null);

// configure client for API calls
export const apiClient = axios.create({
    baseURL: MUZOLOGY_API_URL,
    withCredentials: true,
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFToken',
    headers: { 'Content-Type': 'application/json' }
});

const AuthProvider = (props) => {
    const history = useHistory();
    // const { setErrorMessage } = useError();

    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [initialStore, setInitialStore] = useState({});
    // const [csrfToken, setCSRFToken] = useState(null);

    // get the session
    useEffect(() => {
        getSession()
            .then(response => {
                console.log('[AuthProvider] getSession success', response);
                if (!response) {
                    // user is not logged in
                    setIsLoggedIn(false);
                } else {
                    setIsLoggedIn(true);
                }
            })
            .catch(error => {
                console.log('[AuthProvider] getSession error', error);
                setIsLoggedIn(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const getCSRF = () => {
    //     return apiClient.get('/api/csrf/', { withCredentials: true })
    //         .then((res) => {
    //             // console.log('new csrf', res);
    //             // console.log('new csrf', res.headers);
    //             const token = res.headers['x-csrftoken'];
    //             // console.log('new csrfToken', token);
    //             setCSRFToken(token);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // };

    const getSession = () => {
        return apiClient
            .get('/api/session/', { withCredentials: true })
            .then(({ data }) => {
                console.log(data);
                if (data?.isAuthenticated) {
                    console.log('Auth Session: Currently logged in');
                    // return getCSRF().then(() => whoami().then((response) => {
                    //     console.log('whoami', response);
                    setIsLoggedIn(true);
                    return true;
                    // }));
                } else {
                    console.log('Auth Session: Not logged in');
                    setIsLoggedIn(false);
                    return false;
                }
            })
            .catch((err) => {
                console.log('Error getting session');
                console.log(err);
                // setErrorMessage(formatNetworkError(err));
                // handleNetworkError(err);
            });
    };

    // const whoami = () => {
    //     return apiClient
    //         .get('/api/whoami/', { withCredentials: true })
    //         .then((res) => res.data)
    //         .then((data) => {
    //             console.log(`Auth Session: You are logged in as: ${data.username}`);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // };

    // logout of the app
    const logout = useCallback(() => {
        console.log('[AuthProvider] logout');
        apiClient('/api/logout')
            .then(({ data }) => {
                // redirecting to login will reload the page
                // setIsLoggedIn(false);
                window.location.href = '/login';
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    // login to the app, async function returns the initial payload or false
    const login = useCallback( async (username, password) => {
        try {
            // console.log('Logging in', username);
            setIsLoggingIn(true);

            // clear the login flag during login,
            // to prevent the app from logging back out due to the URL '/login'
            // console.log('setLoggedIn: null');
            // setIsLoggedIn(null);

            // perform a login
            const response = await apiClient.post(
                '/api/login/',
                { username, password }
                // {
                //     withCredentials: true,
                //     headers: {
                //         'Content-Type': 'application/json'
                //         // 'X-CSRFToken': csrfToken
                //     }
                // }
            );

            // login successful, get the payload
            const { data: payload } = response;

            // store the initial payload - need to get the payload into the user provider
            setInitialStore(payload);

            // clear the login flag during login,
            // to prevent the app from logging back out due to the URL '/login'
            // console.log('setLoggedIn: null');
            setIsLoggedIn(null);

            // redirect to change the URL before changing isLoggedIn state
            // check for redirect
            let newPath = '/';
            if (window.location.search.startsWith('?next='))
                newPath = decodeURIComponent(window.location.search.slice(6));
            console.log('new path', newPath);
            history.push(newPath);
            // console.log('Redirecting to app', newPath);

            // login complete
            // console.log('setIsLoggingIn false');
            setIsLoggingIn(false);
            // console.log('setIsLoggedIn true');
            setIsLoggedIn(true);
            return true;
        } catch (error) {
            // login failure
            console.log('Login error', error);
            // handleAPIError(error);

            // set not logged in
            setIsLoggingIn(false);
            setIsLoggedIn(false);

            // if (error.response) {
            //     // The request was made and the server responded with a status code that falls out of the range of 2xx
            //     console.log(error.response.data);
            //     console.log(error.response.status);
            //     console.log(error.response.headers);
            // }

            throw error;
        }
    }, [history]);

    // build the context
    const context = useMemo(() => {
        return {
            login,
            logout,
            isLoggedIn,
            isLoggingIn, //: isLoggedIn === null,
            initialStore
        };
    }, [initialStore, isLoggedIn, isLoggingIn, login, logout]);

    // first render, don't mount anything until we check any existing credentials
    if (isLoggedIn === null) {
        // still checking if our credentials are valid
        return null;
        // return <div tw='background[green] h-full w-full' />;
    }

    console.log('[AuthContext]', context);
    return <AuthContext.Provider value={context}>{props.children}</AuthContext.Provider>;
};

export function useAuth() {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
}

// useAuth with auto login
export function useAuthLogin(username, password) {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuthLogin must be used within an AuthProvider');
    }

    useEffect(() => {
        if (username && password) {
            const { login } = context;
            login(username, password);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [username, password]);

    return context;
}

export default AuthProvider;
