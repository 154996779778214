import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";

const S = {
    Container: styled.div`
        width: 100%;
        height: 11px;
        background: #d5d5d5;
        position: relative;
    `,
    AudioPositionIndicator: styled.div`
        /* end of position */
        width: ${(props) => props.endOfRecording}%;
        /* start of position */
        left: ${(props) => props.startOfRecording}%;
        height: 11px;
        background: #d083ff;
        position: absolute;
    `
};

const AUDIO_DELAY = 0.15;

function checkBlobURL(url) {
    // console.log("CHECK URL:", url);
    return new Promise(function (resolve, reject) {
        let xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function () {
            if (this.readyState === 4) {
                //if (this.status === 200 || (this.response && this.response.type && this.response.type == 'image/jpeg')) {
                if (this.status === 200) {
                    // success(blobUrl);
                    // console.log("URL VALID");
                    resolve(url);
                } else {
                    // fail(blobUrl);
                    // console.log("URL INVALID");
                    reject(url);
                }
            }
        };
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.send();
    });
}

const AudioTimeline = ({ isRecording, recordings, songDuration, songPosition, isPlaying }) => {
    const [audioURL, setAudioURL] = useState(null);
    const [audio, setAudio] = useState(null);
    const [currentRecording, setCurrentRecording] = useState(null);

    const timeStampedRecording = useMemo(() => {
        return recordings.filter((recording) => recording.end >= songPosition);
    }, [songPosition]);

    // update audio
    useEffect(() => {
        console.log("Audio object changed", audioURL);
        if (audioURL) {
            if (audio) {
                console.log("  Replace audio object", audioURL);
                audio.setAttribute("src", audioURL);
            } else {
                console.log("Create audio object", audioURL);
                setAudio(new Audio(audioURL));
            }
        } else {
            if (audio) {
                audio.pause();
                setAudio(null);
            }
        }

        return () => {
            // console.log("Destroy audio object");
            // destroy the audio object
            if (audio) {
                audio.pause();
                setAudio(null);
            }
        };
    }, [audioURL]);

    // create an audio
    useEffect(() => {
        // console.log('*** recordings', recordings);
        if (recordings && recordings.length > 0) {
            const recording = recordings[0];
            if (recording.blob) {
                checkBlobURL(recording.blob)
                    .then((url) => {
                        // blob is valid
                        setAudioURL(url);
                    })
                    .catch((url) => {
                        // blob is invalid
                        setAudioURL(recording.url);
                    });
            } else {
                setAudioURL(recording.url);
            }

            setCurrentRecording(recording);
            /*
            const audioSrc = recording?.blob || recording?.url;
            if( audio ) {
                console.log("+++ updating audio")
                audio.setAttribute('src', audioSrc);
            } else {
                console.log("+++ creating audio")
                setAudio( new Audio(audioSrc) );
            }
            // setAudio(audio);

            console.log('** Creating audio for: ', audioSrc);
            setAudio(audio);
             */
        }
        return () => {
            if (audio) {
                audio.pause();
                setAudio(null);
            }
        };
    }, [recordings]);


    useEffect(() => {
        // console.log("*** isRecording", isRecording);
        // console.log("*** isPlaying", isPlaying);
        // console.log("*** currentRecording", currentRecording);

        if (isPlaying) {
        }
        if (audio) {
        }

        if (isPlaying) {
            if (!isRecording && audio && currentRecording) {
                if (songPosition <= currentRecording.end) {
                    // console.log("Play audio", songPosition);
                    if (songPosition >= currentRecording.start) {
                        audio.currentTime = songPosition - currentRecording.start + AUDIO_DELAY;
                        audio.play();
                    }
                } else {
                    //went past recordings
                    const nextRecording = recordings.find((rec) => rec.start >= songPosition);
                    if (!nextRecording) {
                        audio.pause();
                        setAudio(null);
                    } else if (nextRecording.blob) {
                        checkBlobURL(nextRecording.blob)
                            .then((url) => {
                                // blob is valid
                                setAudioURL(url);
                            })
                            .catch((url) => {
                                // blob is invalid
                                setAudioURL(nextRecording.url);
                            });
                    } else {
                        setAudioURL(nextRecording.url);
                    }

                    setCurrentRecording(nextRecording);
                }
            }
        } else {
            // console.log("Pause audio");
            audio?.pause();
        }
    }, [isPlaying, isRecording, currentRecording, songPosition]);

    // if( audio )
    //     console.log( "**** audio position", audio.currentTime, songPosition )

    if (audio) {
        // console.log('* audio.paused', audio.paused);
        // console.log('* audio.ended', audio.ended);
        // console.log('* audio.currentTime', audio.currentTime);
        if (currentRecording && songPosition < currentRecording.end && audio && !audio.paused && !audio.ended) {
            const drift = Math.abs(audio.currentTime - songPosition);
            if (drift > AUDIO_DELAY) {
                // console.log('**** audio position', audio.currentTime, songPosition, drift );
                audio.currentTime = songPosition + AUDIO_DELAY;
            }
        }
    }

    return (
        <S.Container>
            {recordings &&
                recordings.map(({ start, end }) => {
                    return (
                        <S.AudioPositionIndicator
                            key={`${start}:${end}`}
                            startOfRecording={(start / songDuration) * 100}
                            endOfRecording={((end - start) / songDuration) * 100}
                        />
                    );
                })}
        </S.Container>
    );
};

export default AudioTimeline;
