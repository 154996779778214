import React, { useState } from "react";
import styled from "styled-components";
import PopUp from "./PopUp";
import ReactModal from "react-modal";
import { Link, Route, Switch, useHistory, useParams, useRouteMatch } from "react-router-dom";
import { useProject, useProjectCatalog, useProjectEditor, useProjectExitActions } from "../context/project/ProjectState";
import { LoadingAnimation } from "../home/MySongs";

const S = {
    Container: styled.div`
        width: 746px;
        height: 348px;
        background: #1f2365;
        border-radius: 30px;
        color: white;
        font-family: Avenir, serif;
        font-size: 23px;
        font-style: normal;
        font-weight: 800;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        align-items: center;
    `,

    CopyContainer: styled.div`
        display: flex;
        flex-direction: column;
        color: #fff;
        text-align: center;
        margin-top: 56px;
        .title {
            font-weight: 800;
            font-size: 23px;
        }

        .sub-text {
            font-weight: 800;
            font-size: 16px;
            margin-top: 28px;
        }

        .italic-sub-text {
            font-weight: 800;
            font-size: 16px;
            font-style: italic;
        }
    `,

    ActionsContainer: styled.form`
        display: flex;
        flex-direction: column;
        align-items: center;
    `,
    CheckBoxContainer: styled.div`
        display: flex;
        align-items: center;
        margin-top: 31px;

        input[type="checkbox"] {
            width: 25px;
            height: 25px;
        }

        .checkbox-copy {
            font-weight: 800;
            font-size: 14px;
            margin-left: 11px;
        }
    `,
    ButtonsContainer: styled.div`
        display: flex;
        align-items: center;
        margin-top: 42px;
    `,
    CancelButton: styled.button`
        width: 250px;
        height: 40px;
        border: none;
        //background: white;
        //color: #bbbbbb;
        font-size: 16px;
        font-weight: 800;
        text-transform: uppercase;
        border-radius: 50px;
        cursor: pointer;
        //margin-right: 16px;
        color: #fff;
        background: #00b1c9;
        margin-left: 17px;

        &:hover {
            background: linear-gradient(127.87deg, #00b1c9 16.09%, #8149e8 83.8%);
        }
    `,
    ReRecordButton: styled.button`
        width: 250px;
        height: 40px;
        border: none;
        background: #e637a8;
        color: white;
        font-size: 16px;
        font-weight: 800;
        text-transform: uppercase;
        border-radius: 50px;
        //margin-left: 16px;
        cursor: pointer;
        margin-right: 17px;
        &:hover {
            background: linear-gradient(127.87deg, #e637a8 16.09%, #8149e8 83.8%);
        }
    `
};

const ReRecordModal = ({ onClose, isModalOpen, setHideReRecordModal }) => {
    const [checked, setChecked] = useState(false)
    const handleClick = () => setChecked(!checked)

    return (
        <PopUp onClose={onClose} isModalOpen={isModalOpen}>
            <S.Container>
                <S.CopyContainer>
                    <div className={"title"}>RE-RECORD YOUR SONG?</div>
                    <div className={"sub-text"}> Recording again will replace your current vocals.</div>
                    <div className={"italic-sub-text"}> Are you sure you want to continue?</div>
                </S.CopyContainer>
                <S.ActionsContainer>
                    <S.CheckBoxContainer>
                        <input type="checkbox" onClick={handleClick} checked={checked}/>
                        <div className={"checkbox-copy"}>Don't show this message again</div>
                    </S.CheckBoxContainer>
                    <S.ButtonsContainer>
                        <S.ReRecordButton onClick={() => (onClose(true), setHideReRecordModal(checked))}>RE-RECORD</S.ReRecordButton>
                        <S.CancelButton onClick={() => (onClose(false), setHideReRecordModal(checked))}>CANCEL</S.CancelButton>
                    </S.ButtonsContainer>
                </S.ActionsContainer>
            </S.Container>
        </PopUp>
    );
};

export default ReRecordModal;
