import React from 'react';
import styled from 'styled-components'
import ReactJson from "react-json-view";

const S = {
    Container: styled.div`
      height: 100%;
      width: 100%;
      position: relative;

      display: flex;
      flex-direction: row;
      justify-content: stretch;
      align-items: stretch;

      background: green;
    `,
    Data: styled.div`
      text-align: left;
      position: relative;
      //flex-basis: 400px;
      flex-basis: 25%;
      flex-grow: 1;
      background: white;

      overflow-y: scroll;
      overflow-x: hidden;
    `,
    Client: styled.div`
      position: relative;
      flex-basis: 100%;
      flex-grow: 1;
      background: yellow;
      display: flex;
      justify-content: stretch;
      align-items: stretch;
      user-select: none;
    `,
}

const DebugData = (props) => {
    return (
        <S.Container className={"debug-container"}>
            <S.Data className={"debug-data"}>
                <ReactJson src={{...props.data}} collapsed={1}/>
            </S.Data>
            <S.Client className={"debug-client"}>
                {props.children}
            </S.Client>
        </S.Container>
    );
};


export default DebugData;
