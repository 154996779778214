import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import tw, { css } from "twin.macro";
import styled from "styled-components/macro";
import { useProjectActions } from "../../context/project/ProjectState";
import Draggable from "react-draggable";
import { Img } from "remotion";
import { useMeasure } from "react-use";

const DecalContainer = styled.div`
    position: absolute;

    .element {
        //background: yellow;
        transform: scale(${(props) => props.scale ?? "1"});

        border: 2px solid ${(props) => (props.selected ? "white" : "transparent")};

        &:hover {
            border: 2px solid white;
        }
    }
`;

const EditableComponent = (props) => {
    const { element, bounds, scale, selected, setHideDragging } = props;
    const { UpdateDecalCoordinates } = useProjectActions();
    const [isDragging, setIsDragging] = useState(false);

    // convert between decal dimensions and screen dimensions, and vice-versa
    const decalToScreen = (value) => {
        return (value * scale);
    };

    const screenToDecal = (value) => {
        return value / scale;
    };

    // get the decal size & convert to screen dimensions
    const width = element.width ?? 180;
    const height = element.height ?? 180;
    const screenWidth = decalToScreen(width);
    const screenHeight = decalToScreen(height);

    // const nodeRef = React.useRef(null);
    const onStartDragging = (e) => {
        e.preventDefault();
        if (setHideDragging)
            setHideDragging(element?.id);
        setIsDragging(true);
    };

    const onStopDragging = (event, data) => {
        const { x, y } = data;
        console.log("Update:", x, y, screenToDecal(x), screenToDecal(y));
        UpdateDecalCoordinates({ id: element.id, url: element.url, x: screenToDecal(x), y: screenToDecal(y) });
        if (setHideDragging)
            setHideDragging(null);
        setIsDragging(false);
    };

    return (
        <Draggable
            onMouseDown={onStartDragging}
            onStop={onStopDragging}
            bounds={bounds}
            defaultPosition={{ x: decalToScreen(element.x), y: decalToScreen(element.y) }}
            scale={1}
        >
            <DecalContainer selected={selected} scale={1}>
                <div
                    className={"element decal"}
                    tw="absolute top[0] left[0]"
                    style={{ width: screenWidth, height: screenHeight }}
                >
                    {isDragging && <img src={element.url} width="100%" />}
                </div>
            </DecalContainer>
        </Draggable>
    );
};

const DecalEditor = (props) => {
    const { hideDragging, setHideDragging, decals } = props;
    console.log("🚀 ~ file: DecalEditor.js ~ line 48 ~ DecalEditor ~ hideDragging", hideDragging);
    const [ref, { width, height }] = useMeasure();
    const screenScale = useMemo(() => width / 1920.0, [width]);

    /*
    // console.log("measure:", width, height, "scale:", screenScale);
    const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
    const [show, setShow] = useState(false);

    const handleContextMenu = useCallback((event) => {
        console.log("ref", ref);
        const { target } = event;
        console.log("target", target);
        if (target !== window) { // or !!target.contains to check for the presence of the method
            let x = event.pageX;
            let y = event.pageY;
            let containsTarget = true;

            if (event.currentTarget) {
                // const currentTargetRect = event.currentTarget?.getBoundingClientRect();
                // x = event.pageX - currentTargetRect.left;
                // y = event.pageY - currentTargetRect.top;
                // containsTarget = ref?.current?.contains(target);
            } else {

            }

            console.log("ref?.current.contains", ref?.current, containsTarget, x, y);

            if (containsTarget) {
                event.preventDefault();
                setAnchorPoint({ x, y });
                setShow(true);
            }
        }


    }, [ref]);

    useEffect(() => {
        document.addEventListener("contextmenu", handleContextMenu);
        // effect cleanup
        return () => {
            document.removeEventListener("contextmenu", handleContextMenu);
        };
    }, []);
    */

    const decalElements = useMemo(() => {
        const elements = decals ?? [];
        return elements.map((item, index) => {
            const itemWidth = (item.width ?? 180) * screenScale;
            const itemHeight = (item.width ?? 180) * screenScale;
            return (
                <EditableComponent
                    key={`${item.id}-${screenScale}`}
                    element={item}
                    scale={screenScale}
                    bounds={{ left: 0, top: 0, right: width - itemWidth, bottom: height - itemHeight }}
                    setHideDragging={setHideDragging}
                />
            );
        });
    }, [props.decals, screenScale]);

    return (
        <div ref={ref} tw="absolute inset-0 bottom[60px] background[none] flex ">
            {decalElements}
            {/* <ContextMenu show={show} close={() => setShow(false)} position={anchorPoint} /> */}
        </div>
    );
};

export default DecalEditor;
