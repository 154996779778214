import React, { useMemo } from "react";
import { Route, Switch } from "react-router-dom";

import { Redirect } from "react-router";
import styled from "styled-components";
import TitleBar from "../common/TitleBar";
import Home from "./Home";
import SongSelectionAndTopics from "../common/SongSelectionAndTopics/SongSelectionAndTopics";
import Topics from "../common/Topics/Topics";
import Inbox from "./Inbox";
import Discover from "./Discover";
import MySongs from "./MySongs";
import Lyrics from "../lyrics/LyricsContainer";
import SongEditorContainer from "../song/SongEditorContainer";
import { DISABLE_NAVBAR_ACTIONS, DISABLE_NAVBAR } from "../../config";
// import UserNameCreation from '../common/UserNameCreation/UserNameCreation'
import StudentSongs from "./StudentSongs";
import useSWR from "swr/esm";
const S = {
    Container: styled.div`
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        display: flex;
        flex-direction: column;
    `,

    ClientContainer: styled.div`
        background: linear-gradient(164.79deg, rgba(20, 126, 223, 0.85) 0%, rgba(125, 38, 179, 0.85) 100%);
        flex-grow: 1;

        display: flex;
        flex-direction: column;

        align-items: stretch;
        justify-content: stretch;

        height: calc(100% - 70px);

        // make the children fill the space
        > div {
            flex-grow: 1;
        }
    `
};

const HomeContainer = (props) => {
    const {data, error} = useSWR('/api/me/');
    const is_teacher = useMemo( () => data?.user?.is_teacher, [data]);

    return (
        <S.Container>
            {!DISABLE_NAVBAR && <TitleBar teacher={is_teacher} showLinks={!DISABLE_NAVBAR_ACTIONS} />}
            <S.ClientContainer>
                <Switch>
                    <Route path={"/my-songs"}>
                        <MySongs />
                    </Route>

                    <Route path={"/student-songs"}>
                        <StudentSongs />
                    </Route>

                    <Route path={"/old"}>
                        <Home />
                    </Route>

                    <Route path={"/home"}>
                        <Topics />
                    </Route>

                    <Route path={"/discover"}>
                        <Discover />
                    </Route>

                    <Route path={"/editor"}>
                        <SongEditorContainer />
                    </Route>

                    <Route path={"/inbox"}>
                        <Inbox />
                    </Route>

                    <Route path={"/lyrics"}>
                        <Lyrics />
                    </Route>

                    <Route path={"/"} exact={true}>
                        <Redirect to={"/home"} />
                    </Route>
                </Switch>
            </S.ClientContainer>
            {/* <TabBar tabs={tabs}/> */}
        </S.Container>
    );
};

export default HomeContainer;
