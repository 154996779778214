import React from "react";
import tw, { css } from "twin.macro";
import styled from "styled-components/macro";
import { useProjectActions } from "../../context/project/ProjectState";

const FontsPalette = (props) => {
    const { setVideoFont } = useProjectActions();

    return (
        <div tw="pt-10 text-3xl font-extrabold h-full grid gap-x-2 gap-y-1 grid-cols-3">
            <div tw="font-sans cursor-pointer"  onClick={() => setVideoFont("Sans Serif")}>
                Sans Serif
            </div>
            <div tw="font-mono cursor-pointer" onClick={() => setVideoFont("Monospace")}>
                Monospace
            </div>
            <div tw="font-poppins cursor-pointer" onClick={() => setVideoFont("Poppins")}>
                Poppins
            </div>
            <div tw="font-roboto cursor-pointer" onClick={() => setVideoFont("Roboto")}>
                Roboto
            </div>
            <div tw="font-dancing-script cursor-pointer" onClick={() => setVideoFont("Dancing Script")}>
                Dancing Script
            </div>
            <div tw="font-edu-tas cursor-pointer" onClick={() => setVideoFont("Edu TAS Beginner")}>
                Edu Tas
            </div>
            <div tw="font-orbitron cursor-pointer" onClick={() => setVideoFont("orbitron")}>
                Orbitron
            </div>
            <div tw="font-shadows-into-light cursor-pointer">Shadows Into Light</div>
            <div tw="font-indie-flower cursor-pointer">Indie Flower</div>
            <div tw="font-lui-jian cursor-pointer">Lui Jian</div>
            <div tw="font-permanent-marker cursor-pointer">Permanent Marker</div>
            <div tw="font-sacramento cursor-pointer">Sacramento</div>
        </div>
    );
};

export default FontsPalette;
