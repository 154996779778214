import React, { useEffect, useRef } from "react";
import tw, { css } from "twin.macro";
import styled from "styled-components/macro";
import { useProject, useSong } from "../../context/project/ProjectState";
import { Player } from "@remotion/player";
import { LyricsComposition, useProjectPhraseList } from "../../../remotion/LyricsVideo/LyricsComposition";
import { getSongFileURL } from "../../context/SongAudio";
import { Freeze } from "remotion";

const VideoPreview = (props) => {
    // get the project & song
    const project = useProject();
    const phrases = useProjectPhraseList();
    const songDuration = project?.song?.duration;

    // get the video style
    const video_style = project?.data?.draft?.video_style ?? {};
    const background = video_style?.color || "#E9961A";
    const font = video_style?.font;
    const decals = video_style.decals || [];

    // wrap the lyrics video in <Freeze>
    const FrozenLyricsComposition = (props) => {
        return (
            <Freeze frame={props.position}>
                <LyricsComposition {...props} />
            </Freeze>
        );
    };

    return (
        <div tw="flex flex-grow relative">
            <Player
                id="video-player"
                component={FrozenLyricsComposition}
                fps={30}
                durationInFrames={Math.ceil(30 * songDuration)}
                compositionWidth={1920}
                compositionHeight={1080}
                inputProps={{ background, font, decals, phrases, songDuration, position: props.position }}
                clickToPlay={false}
                doubleClickToFullscreen={false}
                controls={false}
                style={{
                    // background: "black",
                    width: "100%"
                }}
            />
        </div>
    );
};

export default VideoPreview;
