import React, { useState } from 'react';
import Countdown from '../project/Countdown';
import styled from 'styled-components';

const S = {
    CounterContainer: styled.div`
      position: absolute;
      top: 50%;
      left: 50%;
      right: auto;
      bottom: auto;
      margin-right: -50%;
      transform: translate(-50%, -75%);
    `
};

export function useRecordingCountdown(bpm, count = 4, onFinished = null) {
    // countdown
    const [countdownFinished, setCountdownFinished] = useState(false);
    const [countdownStarted, setCountdownStarted] = useState(false);

    const startCountdown = () => {
        // start the countdown
        setCountdownStarted(true);
        setCountdownFinished(false);
    };

    const cancelCountdown = () => {
        // stop the countdown
        setCountdownStarted(false);
        setCountdownFinished(false);
    };

    const handleFinishedCountdown = () => {
        // the countdown is completed
        setCountdownFinished(true);
        if (onFinished)
            onFinished();
    };

    const CountdownTimer = () => {
        return (
            <S.CounterContainer>
                <Countdown
                    // countLength={count+1}
                    bpm={bpm}
                    onFinished={handleFinishedCountdown}
                    countdownStarted={countdownStarted}
                />
            </S.CounterContainer>
        );
    };

    return [CountdownTimer, { countdownStarted, countdownFinished, cancelCountdown, startCountdown }];
}
