import React, { useState } from 'react';
import { useProjectActions } from '../context/project/ProjectState';

import styled from 'styled-components';

const S = {
    Container: styled.div`
        background: #2a2e77;
        border-radius: 5px;
        display: flex;
        justify-items: center;
        align-items: center;
        cursor: pointer;
        user-select: none;
        min-width: 500px;
        height: 45px;
    `,
    Text: styled.input`
        width: 100%;
        height: 100%;
        font-family: Avenir, serif;
        font-style: normal;
        font-weight: 800;
        font-size: 22px;
        line-height: 33px;
        text-align: center;
        background: none;
        border: none;
        //width: 100%;
        //width: 600px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #14d1df;
        //padding: 6px 100px;
        ::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: #14d1df;
        }
        &:focus {
            outline: none;
            border: 2px solid #14d1df;
            background: #2a2e77;
            color: #14d1df !important;
            opacity: 1;
        }
        &:hover {
            background: #2a2e77;
        }
        &:active {
            color: #14d1df;
        }
    `
};

const TitleEdit = (props) => {
    
    const { renameProject } = useProjectActions();

    const onChangeHandler = (event) => {
        event.preventDefault();
        if(event.target.value === ''){
            // alert('please enter a song name')
            return;
        }
        renameProject(event.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let el = document.querySelector(':focus');
        if (el) el.blur();
    };

    return (
        <S.Container>
            <S.Text
                type='text'
                value={props.text}
                className={'title'}
                onKeyPress={(event) => event.key === 'Enter' && handleSubmit(event)}
                onChange={onChangeHandler}
            />
        </S.Container>
    );
};

export default TitleEdit;
