import React from "react";
import useSWR from "swr/esm";
import { useDevTool } from "../../lib/DevTool/DevTool";
// import { SAMPLE_CATEGORIES, SAMPLE_TOPICS } from "./SampleTopics";

const Context = React.createContext();

// subjects
//     topics
//         definitions

// Green Street Academy
    // multiplying decimals
    // dividing decimals

const TopicCatalog = (props) => {

    // const { error, isValidating: loading } = useSWR("/api/ace/subjects/", {
    //     onSuccess: (results) => {
    //         console.log("results:", results);
    //         setTopics(results);
    //     }
    // });

    // load the subjects
    const { data: subjects, error: subjectsError, isValidating: subjectsValidating } = useSWR("/api/ace/subjects/");

    // load the topics
    const { data: topics, error, isValidating: loading } = useSWR("/api/ace/topics/");

    // load the definitions
    const { data: definitions, error: definitionsError, isValidating: loadingDefinitions } = useSWR("/api/ace/definitions/");

    const context = { subjects, topics, definitions };
    useDevTool("TopicCatalog", context);

    // const getTopic = (topicId) => {
    //     // find the topic
    //     const topic = topics.find(t => t.id === topicId);
    //
    //     // load the definitions
    //     return topic;
    // }

    return (
        <Context.Provider value={context}>
            {props.children}
        </Context.Provider>
    );
};


export function useSubjects() {
    const context = React.useContext(Context);
    return context.subjects ?? [];
    // const { data, error, isValidating } = useSWR("/api/ace/subjects/");
    // return { subjects: data, error, loading: isValidating };
}

export function useTopics(subjectId = null) {
    const context = React.useContext(Context);

    if (subjectId) {
        // get the subject and the topics
        const subject = context.subjects.find(s => s.id === subjectId);
        return subject?.topics?.map(t => context.topics.find(t2 => t2.id === t)) ?? [];
    } else {
        // get all the topics
        return context.topics ?? [];
    }
    // const { data, error, isValidating } = useSWR(subjectId ? `/api/ace/subjects/${subjectId}` : null);
    // return { topics: data, error, loading: isValidating };
}

export function useTopic(topicId) {
    // get the topic
    const context = React.useContext(Context);
    if( !topicId ) {
        return null;
    }
    const topic = context.topics?.find(t => t.id === topicId);
    return { ...topic, definitions: context.definitions?.filter(d => d.topicId === topicId) };
}

export function useDefinitions(topicId) {
    const context = React.useContext(Context);
    if (topicId) {
        // get the topic and the definitions
        const topic = context.topics.find(t => t.id === topicId);
        return topic?.definitions?.map(d => context?.definitions?.find(d2 => d2.id === d)) ?? [];
    } else {
        // get all the definitions
        return context.definitions ?? [];
    }

    // const { data, error, isValidating } = useSWR("/api/ace/topics/");
    // return { topics: data, error, loading: isValidating };
}


export default TopicCatalog;
