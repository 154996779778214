import React, { useState, useEffect, useRef } from "react";
import { useLocalStorage } from "react-use";

import styled from "styled-components";
import PlayButton from "../song/PlayButton";
import RecordButton from "../song/RecordButton";
import SongTimeline from "./timeline/SongTimeline";
import { useSongAudio } from "../context/SongAudio";
import { useAudioPosition } from "react-use-audio-player";
import { useReactMediaRecorder } from "react-media-recorder";
import Countdown from "../project/Countdown";
import { useProjectSongSections } from "../context/project/ProjectState";
import ReRecordModal from "../project/ReRecordModal";
import { useRecordingCountdown } from './RecordingCountdown';
import { useProjectRecordings } from "../context/project/Recordings";

const S = {
    Container: styled.div`
        width: 100%;
        //height: 233px;

        padding-top: 25px;
        padding-bottom: 25px;

        //flex-basis: 100%;
        //flex-basis: 233px;
        //flex-grow: 1;

        background: #1f2365;

        display: flex;
        flex-direction: column;
        //align-items: stretch;
        //justify-content: stretch;
    `,
    ControlsContainer: styled.div`
        height: 90px;
        //margin-top:25px;
        margin-left: 50px;
        margin-right: 50px;

        display: flex;
        flex-direction: row;

        align-items: center;
        justify-content: stretch;
    `,

    TrackContainer: styled.div`
        height: 100px;
        background: #1f2365;

        display: flex;
        flex-grow: 1;
        flex-direction: column;
        align-content: stretch;
        justify-content: space-evenly;
    `,
    CounterContainer: styled.div`
        position: absolute;
        top: 50%;
        left: 50%;
        right: auto;
        bottom: auto;
        margin-right: -50%;
        transform: translate(-50%, -75%);
    `
};

const getBPMAsSecond = (BPM) => {
    return (60 / BPM) * 1000.0;
};

// const CountdownProvider = (props) => {
//     return (
//     <Metronome
//         tempo={bpm}
//
//         render={({
//                      tempo,
//                      beatsPerMeasure,
//                      playing,
//                      beat,
//                      subdivision,
//                      onPlay,
//                      onBpmChange
//                  }) => (
//
//             <S.Countdown>
//                 <S.CounterContainer>
//                     <S.CountdownNumber>{beat}</S.CountdownNumber>
//                 </S.CounterContainer>
//             </S.Countdown>
//         )} />
//     );
// }



const RecordingTimelineSection = (props) => {
    const { seek, duration, position } = useAudioPosition({ highRefreshRate: false });

    // when playback ends, save recording from start to duration
    const onSongPlaybackEnd = React.useCallback( () => {
        // stop recording
        finishRecording(duration);
    }, [props, duration]);

    // song does not loop in recording mode
    const { isPlaying, play, pause } = useSongAudio(props.musicSection === 100 ? null : props.musicSection - 1, onSongPlaybackEnd, false);

    const [recordings, addRecording] = useProjectRecordings();

    // create a callback that takes a percentage and sets song position
    const setPosition = React.useCallback(
        (percentage) => {
            // console.log("setPosition", percentage, duration, percentage * duration)
            seek(duration * percentage);
        },
        [duration, seek]
    );

    const handlePlayClicked = () => {
        if (countdownStarted || props.isRecording) {
            // console.log("do nothing");
        } else {
            if (isPlaying) {
                pause();
            } else {
                play();
            }
        }
    };

    const { status, startRecording, stopRecording, mediaBlobUrl } = useReactMediaRecorder({ audio: true });

    const [CountdownTimer, { countdownStarted, countdownFinished, cancelCountdown, startCountdown }] = useRecordingCountdown(props.song?.data?.BPM);
    // const [isRecording, setIsRecording] = useState(false);
    const song_sections = useProjectSongSections();
    const [splitLists, setSplitLists] = useState([]);

    // const idle = status === 'idle'
    // const recording = status === 'recording'
    // const stopped = status === 'stopped'


    // recording state
    const [recordingStartPosition, setRecordingStartPosition] = useState(0);
    const [recordingIsFinished, setRecordingIsFinished] = useState(false);
    const [recordingEndPosition, setRecordingEndPosition] = useState(0);
    const [recordingMediaBlob, setRecordingMediaBlob] = useState(null);

    // state for rerecord modal
    const [showReRecordModal, setShowReRecordModal] = useState(false);
    const [hideReRecordModal, setHideReRecordModal] = useLocalStorage("hide-rerecord-modal", false);

    // phrase timestamps
    const [timeStamps, setTimeStamps] = useState([]);

    // start recording when the countdown ends
    function handleFinishedCountdown() {
        // setCountDownFinished will switch boolean countdownStarted to true
        // this will remove the Countdown from the dom
        // setCountdownFinished(true);

        // started recording audio after the given amount of time(4 seconds)
        startRecording();
        play();
        setRecordingStartPosition(position);
        props.setIsRecording(true);
    }

    // this boolean gets passed to the timer
    // if true it will start the timer
    // when the timer is done it will call handleFinishedCountdown()

    function handleStopRecording() {
        finishRecording(position);

        // move position to beginning
        setPosition(0);
    }

    const finishRecording = (endPosition) => {
        // stop recording
        stopRecording();

        // recording finished
        props.setIsRecording(false);

        // pause playback
        pause();

        // reset countdown
        cancelCountdown();

        // this will cause the recording to be added to the project once the blob url appears
        setRecordingEndPosition(endPosition);
        setRecordingIsFinished(true);
    }

    function toggleRecording() {
        if (countdownStarted && !countdownFinished) {
            // just stop the countdown
            cancelCountdown();
            // setCountdownStarted(false);
        } else if (props.isRecording) {
            handleStopRecording();
        } else {
            // start recording

            // show the "re-record" message
            // if (recordings.length > 0 && !hideReRecordModal) {
            //     // setCountdownFinished(true);
            //     // setRecordingIsFinished(true);
            //     setShowReRecordModal(true);
            // } else {
            startRecordingCountdown();
            // }
        }

        /*

        // this is for the case when a user clicks the button again
        // countdown will have been finished by then and this will be called
        if (countdownFinished) {
            stopRecording();
            setIsRecording(false);
            handlePlayClicked();
            // this will reset the counter and thus rendering the Countdown component back into the dom
            setCountdownFinished(false);

            // this will cause the recording to be added to the project once the blob url appears
            setRecordingIsFinished(true);

            setCountdownStarted(false);

        } else {
            // stop the countdown
            setCountdownStarted(!countdownStarted);
        }
        */
        // each time a user clicks the countdownStarted boolean will switch
        // first click: starts countdown then starts the recording
        // second click: resets and pauses countdown and stops recording
    }

    const startRecordingCountdown = () => {
        // pause the song
        pause();

        // move to the beginning
        // setPosition(0);

        // start the countdown
        startCountdown();
    }

    // begin recording when countdownFinished becomes true
    useEffect( () => {
        if( countdownStarted && countdownFinished ) {
            handleFinishedCountdown();
        }
    }, [countdownFinished]);

    // reduce phrase timestamps to a single array
    useEffect(() => {
        const phrase_timestamps = song_sections.reduce((timestamps, section, section_index) => {
            const phrases = section.phrase_timestamps.map( (ts, phrase_index) => {
                return {
                    timestamp: ts,
                    phrase: phrase_index,
                    section: section_index
                }
            })
            return [...timestamps, ...phrases];
        }, []);

        // add the final duration at the end
        // const allTimestamps = [...phrase_timestamps, duration];
        // allTimestamps.reverse();
        phrase_timestamps.reverse();
        setTimeStamps(phrase_timestamps);
            // console.log("created timestamps:", phrase_timestamps);
    }, [song_sections]);

    useEffect( () => {
        const phrase = timeStamps.find( (phrase) => position >= phrase.timestamp);
        if( phrase ) {
            // console.log(`${position} = `, phrase.timestamp, phrase.section, phrase.phrase)
            props.setActiveSection([phrase.section, phrase.phrase]);
        }
    }, [position, timeStamps]);

    // set the recordingMediaBlob when mediaBlobUrl changes
    useEffect(() => {
        setRecordingMediaBlob(mediaBlobUrl);
    }, [mediaBlobUrl]);

    // add the recording to the project when it is finished
    useEffect(() => {
        // console.log("finished:", recordingIsFinished, mediaBlobUrl, recordingStartPosition, recordingEndPosition);
        if (recordingIsFinished && recordingMediaBlob) {
            // save the recording in the project
            addRecording({ blob: recordingMediaBlob, start: recordingStartPosition, end: recordingEndPosition });
            // ensure this only happens once
            setRecordingIsFinished(false);
            setRecordingMediaBlob(null);
        }
    }, [recordingMediaBlob, recordingIsFinished]);

    return (
        <S.Container>
            <S.ControlsContainer>
                <PlayButton
                    className={"white"}
                    onClick={handlePlayClicked}
                    playing={isPlaying}
                    style={{opacity:(props.isRecording ? 0 : 1) }}
                />

                <SongTimeline
                    song={props.song}
                    position={position}
                    setPosition={setPosition}
                    musicSection={100}
                    duration={duration}
                    percentComplete={position / duration}
                    pause={pause}
                    play={play}
                    isPlaying={isPlaying}
                    isRecording={props.isRecording}
                    recordings={recordings}
                    showRecordButton={props.showRecordButton}
                    disableSeek = {props.isRecording || countdownStarted}
                />
            </S.ControlsContainer>

            {(countdownStarted && !countdownFinished) && <CountdownTimer/>}

            <RecordButton onClick={toggleRecording} status={status} />

{/*
            {props.editMode === EditModes.RECORD &&
                <AudioRecorder
                    song={props.song}
                    playAudio={play}
                    pauseAudio={pause}
                    audioPosition={position}
                    audioDuration={duration}
                    setAudioPosition={setPosition}
                    isRecording={props.isRecording}
                    setIsRecording={props.setIsRecording}
                />
            }
*/}

            {!hideReRecordModal && <ReRecordModal onClose={(beginRecording) => {
                setShowReRecordModal(false);
                if( beginRecording ) {
                    startRecordingCountdown();
                }
            }} isModalOpen={showReRecordModal} setHideReRecordModal={setHideReRecordModal} />}

        </S.Container>
    );
};

export default RecordingTimelineSection;
