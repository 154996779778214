import React, {useEffect, useRef, useState} from 'react';

const MediaContext = React.createContext();

const MediaProvider = (props) => {
    // const [songs, setSongs] = useState(Songs)
    const [isPlaying, setIsPlaying] = useState(false)
    const [position, setPosition] = useState(0)

    const play = () => {
        if (isPlaying)
            return;
        setIsPlaying(true);
    }

    const pause = () => {
        if (!isPlaying)
            return;
        setIsPlaying(false);
    }

    const media = {
        isPlaying,
        position,
        play,
        pause,
        setPosition
    }

    return (
        <MediaContext.Provider value={media}>
            {props.children}
        </MediaContext.Provider>
    );
};

export function useMedia() {
    return React.useContext(MediaContext);
}

// export function useSetSong() {
//     const dispatch = React.useContext(DispatchContext);
//     return (song) => {
//         dispatch({ type: 'SET_SONG', payload: song });
//     };
// }

export default MediaProvider;

