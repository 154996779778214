import React from "react";
import tw, { css } from "twin.macro";
import "styled-components/macro";


const BorderGradient = css`
    color: #313149;
    /* display: inline-block; */
    position: relative;
    z-index: 0;

    &:before {
        content: "";
        position: absolute;
        z-index: -1;
        inset: 0;
        padding: 3px;
        border-radius: 30px 0px 5px 5px;
        background: linear-gradient(93.49deg, #8149e8 3.49%, #887be3 100%);
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
    }
`;

const TextGradient = css`
    background: linear-gradient(127.87deg, #e637a8 16.09%, #8149e8 83.8%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: 900;
`;

const FactSheet = ({children}) => {
    return (
            <div tw="background[#EDEDED] border-radius[30px 0 0 0] " css={BorderGradient}>
                {children}
                {/*
                <div tw="text-black text-xl font-black pt-5">Central Tendencies - Fact Sheet 1</div>
                <div tw="background[linear-gradient(93.49deg, #8149e8 3.49%, #887be3 100%)] mt-2.5 mb-5 h-0.5" />
                <div tw="text-justify">
                    <span css={TextGradient}>Mean</span> - The Average
                </div>
                <div tw="text-justify">
                    How to find the Mean: Add all the numbers, and then divide by the number of items in that data set
                    <div>Example: 2, 5, 9, 3, 5, 4, 7</div>
                    <div>2 + 5 + 9 + 3 + 5 + 4 + 7 = 35</div>
                    <div>There are 7 items (numbers in the list) in the data set, </div>
                    <div> SO 35 / 7 = 5</div>
                    The Mean of this set of data is 5
                </div>
                <div tw="background[#D5D5D5] mt-2.5 mb-5 h-0.5" />

                <div tw="text-justify">
                    <span css={TextGradient}>Median</span> -
                </div>
                <div tw="text-justify">
                    The number(s) in the middle of a data set when all the numbers in the data set are listed in order from least to greatest (ascending order)
                </div>
                */}
            </div>
    );
};

export default FactSheet;