import React, { useState, useRef } from "react";
import tw, { css } from "twin.macro";
import "styled-components/macro";
import { Button } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import axios from "../../../api";
import { useProject } from "../../context/project/ProjectState";
import VideoPreview from "./VideoPreview";
import ThumbnailSelect from "./ThumbnailSelectModal";
import ReactModal from "react-modal";

const PreviewMode = (props) => {
    const [activeThumbnail, setActiveThumbnail] = useState(1);
    const [framePosition1, setFrame1] = useState(10);
    const [framePosition2, setFrame2] = useState(210);
    const [framePosition3, setFrame3] = useState(400);
    const [defaultFrame, setDefaultFrame] = useState(framePosition1);
    const [isOpen, setIsOpen] = useState(false);

    const { register, handleSubmit, errors: formErrors } = useForm();
    const project = useProject();

    const onSubmit = (data) => {
        let commaReplace = data.tags.replaceAll(",", "");
        let tagArr = commaReplace.split(/\s+/);
        let formattedData = { title: data.title, description: data.description, tags: tagArr, defaultFrame: defaultFrame };
        axios
            .post(`api/ace/projects/${project.id}/submit/`, formattedData)
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <div tw="relative maxHeight[675px] flex background[white] flex-grow my-5 ml-9 mr-32 overflow-hidden rounded-3xl">
            <div tw={"flex-shrink-0 flex-grow flex items-center flexBasis[60%] h-full background[#00000000] px-7"}>
                <VideoPreview position={defaultFrame} />
                <div tw="height[10px]" />
            </div>
            <form tw={"flex-shrink-0 flex-grow flexBasis[40%] height[100%] flex flex-col borderLeft[1px solid #000] "} onSubmit={handleSubmit(onSubmit)}>
                <div tw="color[#A9A9A9] text-2xl font-bold pt-4">INFO</div>
                <div tw="flex flex-col items-start px-9">
                    <div tw="text-black text-base font-medium pt-4">Song Title *</div>
                    <input
                        tw="w-full border[1px solid #8149E8] mt-3 minHeight[35px]"
                        name="title"
                        ref={register({
                            required: true
                        })}
                    />
                    <div tw="text-red-600 pt-1">{formErrors.title ? "video title is required" : ""}</div>
                    <div tw="text-black text-base font-medium pt-4">Description</div>
                    <textarea tw="w-full border[1px solid #8149E8] mt-3 minHeight[70px]" name="description" ref={register({})} />
                    <div tw="text-black text-base font-medium pt-4">Tags (Separate with commas)</div>
                    <textarea tw="w-full border[1px solid #8149E8] mt-3 minHeight[70px]" name="tags" ref={register({})} />
                </div>
                <div tw="color[#A9A9A9] text-2xl font-bold pt-4">PICK A THUMBNAIL</div>
                <div tw="flex justify-center  px-7 pt-3.5 h-full">
                    <div
                        tw="relative w-1/3 mx-1 maxHeight[84px] cursor-pointer"
                        onClick={() => {
                            setActiveThumbnail(1);
                            setDefaultFrame(framePosition1);
                        }}
                        css={activeThumbnail === 1 && [tw`border[4px solid #8149E8]`]}
                    >
                        <VideoPreview position={framePosition1} />
                    </div>
                    <div
                        tw="relative w-1/3 mx-1 maxHeight[84px] cursor-pointer"
                        onClick={() => {
                            setActiveThumbnail(2);
                            setDefaultFrame(framePosition2);
                        }}
                        css={activeThumbnail === 2 && [tw`border[4px solid #8149E8]`]}
                    >
                        <VideoPreview position={framePosition2} />
                    </div>
                    <div
                        tw="relative w-1/3 mx-1 maxHeight[84px] cursor-pointer"
                        onClick={() => {
                            setActiveThumbnail(3);
                            setDefaultFrame(framePosition3);
                        }}
                        css={activeThumbnail === 3 && [tw`border[4px solid #8149E8]`]}
                    >
                        <VideoPreview position={framePosition3} />
                    </div>
                </div>
                {/* 
                // disable this for now 
                <div tw="color[#4D4D4D] text-base font-medium pt-3.5 underline cursor-pointer" onClick={() => setIsOpen(true)}>
                    Pick from video
                </div> 
                */}
                <div tw="flex justify-center py-9">
                    <Button
                        // as="button"
                        type="submit"
                        tw="hover:opacity[75%] active:opacity[50%] py-2 px-8 background[#E637A8] rounded-full uppercase text-sm font-extrabold leading-normal text-white maxWidth[275px]"
                    >
                        SUBMIT FOR REVIEW
                    </Button>
                </div>
            </form>
            <ThumbnailSelect setIsOpen={setIsOpen} isOpen={isOpen} />
        </div>
    );
};

export default PreviewMode;
