import React, { useState } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const S = {
    StyledLink: styled(Link)`
        text-decoration: none;
        display: block;
        color: white;
    `,
    ModalTitle: styled.h1`
        color: white;
        font-size: 24px;
        text-align: center;
    `,

    SongTitle: styled.h3`
        color: white;
        font-size: 24px;
        text-align: center;
    `,

    ActionsContainer: styled.div`
        position: relative;
    `,
    CancelButton: styled.button`
        width: 182px;
        height: 40px;
        border: none;
        background: white;
        color: #bbbbbb;
        font-size: 16px;
        font-weight: 800;
        text-transform: uppercase;
        border-radius: 50px;
        cursor: pointer;
    `,
    SaveButton: styled.button`
        width: 182px;
        height: 40px;
        border: none;
        background: #e637a8;
        color: white;
        font-size: 16px;
        font-weight: 800;
        text-transform: uppercase;
        border-radius: 50px;
        margin-left: 32px;
        cursor: pointer;
    `
};

const PopUp = (props) => {

    return (
        <ReactModal
            ariaHideApp={false}
            isOpen={props.isModalOpen}
            style={{
                overlay: {
                    backgroundColor: 'rgba(125, 38, 223, 0.5)'
                },
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: '#1F2365',
                    border: 'none',
                    borderRadius: '30px',
                    width: props.width || '',
                    height: props.height || '',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                }
            }}
        >
            <S.ActionsContainer>
                {props.children}
            </S.ActionsContainer>
        </ReactModal>
    );
};

export default PopUp;
