import React, { useEffect, useRef, useState } from "react";
import tw, { css } from "twin.macro";
import styled from "styled-components/macro";
import { useProject, useProjectPhrase } from "../../context/project/ProjectState";

const Container = styled.div`
    background: ${(props) => props.background};
    font-family: ${(props) => props.font};
`;

const VideoScreen = (props) => {
    const project = useProject();
    let { activeSection, fontSize } = props;
    console.log("🚀 ~ file: VideoScreen.js ~ line 17 ~ VideoScreen ~ activeSection", activeSection)
    const [phrase, setPhrase] = useProjectPhrase(activeSection[0], activeSection[1]);
    const video_style = project.data?.draft?.video_style ?? {};
    const background = video_style?.color || "#E9961A";
    const font = video_style?.font;
    let fontsize = fontSize ?? '2.5rem'
    
    return (
        <Container background={background} font={font} tw="flexShrink[1] flexGrow[1] height[100%] maxHeight[388px]">
            <div tw="flex items-center justify-center h-full p-3">
                <div tw="text-center text-white font-black animate-fade transition-all duration-1000 delay-700 " style={{fontSize: fontsize}}>{phrase}</div>
            </div>
        </Container>
    );
};

export default VideoScreen;
