import React, {useState} from 'react';

import styled from "styled-components";

const S = {
    Button: styled.button`
      padding: 6px;
      //margin-top: 32px;
      align-self: center;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 3px solid white;
      background: none;
      cursor: pointer;

      &:focus {
        outline: none;
      }

      ${({ isRecording }) => isRecording && `
        border: 3px solid red;
        padding: 6px;
      `}
    `,
    ButtonCenter: styled.div`
      height: 54px;
      width: 54px;
      border-radius: 100%;
      background: white;

      ${({ isRecording }) => isRecording && `
          height: 32px;
          width: 32px;
          border-radius: 0;
          background: red;
          border-radius: 5px;
          margin: 14px;
      `}
    `
}


const RecordButton = ({ onClick, status }) => {
  const isRecording = status === 'recording'
    return (
        <>
            <S.Button isRecording={isRecording} onClick={onClick}>
                <S.ButtonCenter isRecording={isRecording}></S.ButtonCenter>
            </S.Button>
        </>
    );
};

export default RecordButton;