import React from "react";

export const ModeLyricsIcon = (props) => <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M4.77734 5.77825C4.77734 5.50321 4.8866 5.23943 5.08109 5.04495C5.27557 4.85047 5.53934 4.74121 5.81438 4.74121H16.1847C16.4598 4.74121 16.7236 4.85047 16.918 5.04495C17.1125 5.23943 17.2218 5.50321 17.2218 5.77825C17.2218 6.05329 17.1125 6.31706 16.918 6.51154C16.7236 6.70603 16.4598 6.81528 16.1847 6.81528H5.81438C5.53934 6.81528 5.27557 6.70603 5.08109 6.51154C4.8866 6.31706 4.77734 6.05329 4.77734 5.77825Z"
        fill="white" />
    <path
        d="M4.77734 9.92571C4.77734 9.65067 4.8866 9.3869 5.08109 9.19241C5.27557 8.99793 5.53934 8.88867 5.81438 8.88867H16.1847C16.4598 8.88867 16.7236 8.99793 16.918 9.19241C17.1125 9.3869 17.2218 9.65067 17.2218 9.92571C17.2218 10.2007 17.1125 10.4645 16.918 10.659C16.7236 10.8535 16.4598 10.9627 16.1847 10.9627H5.81438C5.53934 10.9627 5.27557 10.8535 5.08109 10.659C4.8866 10.4645 4.77734 10.2007 4.77734 9.92571Z"
        fill="white" />
    <path
        d="M5.81438 13.0371C5.53934 13.0371 5.27557 13.1464 5.08109 13.3409C4.8866 13.5353 4.77734 13.7991 4.77734 14.0741C4.77734 14.3492 4.8866 14.613 5.08109 14.8074C5.27557 15.0019 5.53934 15.1112 5.81438 15.1112H16.1847C16.4598 15.1112 16.7236 15.0019 16.918 14.8074C17.1125 14.613 17.2218 14.3492 17.2218 14.0741C17.2218 13.7991 17.1125 13.5353 16.918 13.3409C16.7236 13.1464 16.4598 13.0371 16.1847 13.0371H5.81438Z"
        fill="white" />
    <path
        d="M4.77734 18.2226C4.77734 17.9475 4.8866 17.6838 5.08109 17.4893C5.27557 17.2948 5.53934 17.1855 5.81438 17.1855H9.96253C10.2376 17.1855 10.5013 17.2948 10.6958 17.4893C10.8903 17.6838 10.9996 17.9475 10.9996 18.2226C10.9996 18.4976 10.8903 18.7614 10.6958 18.9559C10.5013 19.1504 10.2376 19.2596 9.96253 19.2596H5.81438C5.53934 19.2596 5.27557 19.1504 5.08109 18.9559C4.8866 18.7614 4.77734 18.4976 4.77734 18.2226Z"
        fill="white" />
    <path fillRule="evenodd" clipRule="evenodd"
          d="M0.628906 3.70388C0.628906 2.87877 0.956683 2.08744 1.54013 1.504C2.12358 0.92055 2.9149 0.592773 3.74002 0.592773H18.2585C19.0837 0.592773 19.875 0.92055 20.4584 1.504C21.0419 2.08744 21.3696 2.87877 21.3696 3.70388V20.2965C21.3696 21.1216 21.0419 21.9129 20.4584 22.4964C19.875 23.0798 19.0837 23.4076 18.2585 23.4076H3.74002C2.9149 23.4076 2.12358 23.0798 1.54013 22.4964C0.956683 21.9129 0.628906 21.1216 0.628906 20.2965V3.70388ZM3.74002 2.66685H18.2585C18.5336 2.66685 18.7973 2.77611 18.9918 2.97059C19.1863 3.16507 19.2956 3.42885 19.2956 3.70388V20.2965C19.2956 20.5715 19.1863 20.8353 18.9918 21.0298C18.7973 21.2243 18.5336 21.3335 18.2585 21.3335H3.74002C3.46498 21.3335 3.2012 21.2243 3.00672 21.0298C2.81224 20.8353 2.70298 20.5715 2.70298 20.2965V3.70388C2.70298 3.42885 2.81224 3.16507 3.00672 2.97059C3.2012 2.77611 3.46498 2.66685 3.74002 2.66685Z"
          fill="white" />
</svg>;
export const ModeRecordIcon = (props) => <svg width="18" height="28" viewBox="0 0 18 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M13.6242 5.25092C13.6242 2.71929 11.5721 0.666992 9.04063 0.666992C6.50918 0.666992 4.45703 2.71929 4.45703 5.25092V12.0407C4.45703 14.5723 6.50918 16.6246 9.04063 16.6246C11.5721 16.6246 13.6242 14.5723 13.6242 12.0407V5.25092Z"
        fill="white" fillOpacity="1" />
    <path
        d="M17.9711 12.5063C17.9711 12.1568 17.8323 11.8216 17.5852 11.5745C17.338 11.3273 17.0028 11.1885 16.6533 11.1885C16.3038 11.1885 15.9686 11.3273 15.7214 11.5745C15.4743 11.8216 15.3354 12.1568 15.3354 12.5063C15.3335 14.1751 14.6698 15.7749 13.4898 16.9549C12.3098 18.1349 10.71 18.7986 9.04124 18.8005C7.3725 18.7986 5.77266 18.1349 4.59269 16.9549C3.41272 15.7749 2.74896 14.1751 2.74705 12.5063C2.74705 12.1568 2.6082 11.8216 2.36106 11.5745C2.11391 11.3273 1.77871 11.1885 1.42919 11.1885C1.07967 11.1885 0.744467 11.3273 0.49732 11.5745C0.250173 11.8216 0.111328 12.1568 0.111328 12.5063C0.1145 14.6452 0.884078 16.7121 2.2805 18.3322C3.67692 19.9523 5.60771 21.0184 7.72272 21.337V24.6977H5.79423C5.44472 24.6977 5.10951 24.8366 4.86236 25.0837C4.61521 25.3309 4.47637 25.666 4.47637 26.0156C4.47637 26.3651 4.61521 26.7003 4.86236 26.9475C5.10951 27.1946 5.44472 27.3334 5.79423 27.3334H12.2876C12.6371 27.3334 12.9723 27.1946 13.2194 26.9475C13.4666 26.7003 13.6054 26.3651 13.6054 26.0156C13.6054 25.666 13.4666 25.3309 13.2194 25.0837C12.9723 24.8366 12.6371 24.6977 12.2876 24.6977H10.3591V21.337C12.4742 21.0185 14.4052 19.9526 15.8017 18.3324C17.1983 16.7123 17.9679 14.6453 17.9711 12.5063Z"
        fill="white" fillOpacity="1" />
</svg>;
export const ModeVideoIcon = (props) => <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M22 15.4286V2.57143C22 1.15714 20.8353 0 19.4118 0H2.58824C1.16471 0 0 1.15714 0 2.57143V15.4286C0 16.8429 1.16471 18 2.58824 18H19.4118C20.8353 18 22 16.8429 22 15.4286ZM7.76471 14.1429V3.85714L15.5294 9L7.76471 14.1429Z"
        fill="white" fillOpacity="1" />
</svg>;
export const ModePreviewIcon = (props) => <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M18.3043 4.24716L7.11003 15.8288C6.89301 16.0536 6.57352 16.1598 6.15155 16.1473C5.76575 16.1348 5.47037 16.0287 5.26541 15.8288L0.436849 10.8251C0.219835 10.6002 0.111328 10.3253 0.111328 10.0005C0.111328 9.67564 0.219835 9.40078 0.436849 9.1759L2.04637 7.508C2.26338 7.28311 2.52862 7.17067 2.84209 7.17067C3.15555 7.17067 3.42079 7.28311 3.6378 7.508L6.18772 10.1504L15.1034 0.930101C15.3204 0.705216 15.5856 0.592773 15.8991 0.592773C16.2126 0.592773 16.4778 0.705216 16.6948 0.930101L18.3043 2.57926C18.5213 2.81664 18.6298 3.09462 18.6298 3.41321C18.6298 3.7318 18.5213 4.00978 18.3043 4.24716Z"
        fill="white" fillOpacity="1" />
</svg>;
