import React from 'react';
import styled from 'styled-components'

const S = {
    Container: styled.div`
        background: linear-gradient(164.79deg, rgba(20, 126, 223, 0.85) 0%, rgba(125, 38, 179, 0.85) 100%); 

        font-family: Avenir;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #FFFFFF;
    `,
}

const Background = (props) => {
    return (
        <S.Container className={props.className}>
            {props.children}
        </S.Container>
    );
};


export default Background;
