import React, { useEffect, useRef } from "react";
import { useDevTool } from "../../lib/DevTool/DevTool";


const StoreContext = React.createContext();
const DispatchContext = React.createContext();


const initialState = {  };

const SET_SONG = "SET_SONG";
const NEW_SONG = "NEW_SONG";

const reducer = (state, action) => {
    switch (action.type) {
        case SET_SONG:
            console.log("set song:", action.payload);
            return action.payload;
        case NEW_SONG:
            console.log("new song:", action.payload);
            // the payload should contain the song_id of the song we are loading from
            return {
                name: "New Song"
                // song_id: action.payload['song_id']
            };
        default:
            return state;
    }
};

const SongState = (props) => {
    const [store, dispatch] = React.useReducer(reducer, initialState);
    useDevTool("Song State", store);
    return (
        <DispatchContext.Provider value={dispatch}>
            <StoreContext.Provider value={store}>{props.children}</StoreContext.Provider>
        </DispatchContext.Provider>
    );
};

export function useSong() {
    return React.useContext(StoreContext);
}

export function useSongDispatch() {
    return React.useContext(DispatchContext);
}

export function useSetSong() {
    const dispatch = React.useContext(DispatchContext);
    return (song) => {
        dispatch({ type: "SET_SONG", payload: song });
    };
}

export function useLoadSong() {
    const dispatch = React.useContext(DispatchContext);

    const load = (songId) => {
        const song = { id: songId };
        store(song);
    };

    const store = (song) => dispatch({ type: "SET_SONG", payload: song });

    return load;
}

export default SongState;

