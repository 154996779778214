import React, { useContext, useEffect, useMemo, useState } from "react";
import useAxios from "axios-hooks";
import useSWR from "swr/esm";
import { useHistory } from "react-router-dom";

import Banner from "../../common/Banner";
import ActivityIndicator from "../../common/ActivityIndicator";

import { parse_cookies, silenceError } from "../../../api";

import { deepEqual } from "../../../lib/utils/util";
import { useDevTool } from "../../../lib/DevTool/DevTool";
import DebugData from "../../../lib/debug/DebugData";

export const StoreContext = React.createContext();
export const DispatchContext = React.createContext();
const ExitContext = React.createContext();

export const SET_PROJECT = "SET_PROJECT";
export const SET_PHRASE = "SET_PHRASE";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const SET_NAME = "SET_NAME";
export const DELETE_DRAFT = "DELETE_DRAFT";
export const SET_SECTION_SYNC_CHORUS = "SET_SECTION_SYNC_CHORUS";
export const ADD_RECORDING = "ADD_RECORDING";
export const REMOVE_RECORDING = "REMOVE_RECORDING";
export const REPLACE_RECORDING = "REPLACE_RECORDING";
export const SET_VIDEO_COLOR = "SET_VIDEO_COLOR";
export const SET_VIDEO_FONT = "SET_VIDEO_FONT";
export const SET_VIDEO_DECAL = "SET_VIDEO_DECAL";
export const UPDATE_DECAL_COORDINATES = "UPDATE_DECAL_COORDINATES";

// get the working data from the store
export const getDraftData = (store) => {
    const data = store.data || {};
    return data.draft || {};
};

/*
// get the working sections from the store
export const getDraftSections = (store) => {
    const draft = getDraftData(store);
    return draft.sections || {};
};

// get a working section from the store
export const getDraftSection = (state, sectionIndex) => {
    const sections = getDraftSections(state);
    return sections[sectionIndex] || {};
};
*/

function doRecordingsOverlap(A, B) {
    if (B.start <= A.start) {
        return B.end >= A.start;
    } else {
        return B.start <= A.end;
    }
}

const projectReducer = (state, action) => {
    switch (action.type) {
        case SET_PROJECT:
            // console.log('set song:', action.payload);
            return action.payload;

        case UPDATE_PROJECT:
            // console.log('update song:', action.payload);
            return { ...state, ...action.payload };

        case SET_NAME:
            // console.log('set name:', action.payload);
            // if( action.payload === null || action.payload === "" || action.payload === undefined )
            //     return state;
            return { ...state, name: action.payload };

        case SET_SECTION_SYNC_CHORUS: {
            // console.log('update song section sync:', action.payload);
            const { sectionIndex, syncChorus } = action.payload;

            // get the section from draft data
            let data = state.data || {};
            let draft = data.draft || {};
            let sections = draft.sections || {};
            let section = sections[sectionIndex] || {};

            // update the section
            section = { ...section, sync_chorus: syncChorus };
            sections = { ...sections, [sectionIndex]: section };
            return { ...state, data: { ...state.data, draft: { ...draft, sections: sections } } };
        }

        case SET_PHRASE: {
            // console.log('set phrase:', action.payload);
            const { phrase, sectionIndex, phraseIndex } = action.payload;

            // get the section from draft data
            let data = state.data || {};
            let draft = data.draft || {};
            let sections = draft.sections || {};
            let section = sections[sectionIndex] || {};

            // update the section
            section = { ...section, phrases: { ...section.phrases, [phraseIndex]: phrase } };
            sections = { ...sections, [sectionIndex]: section };
            return { ...state, data: { ...state.data, draft: { ...draft, sections: sections } } };
        }

        case DELETE_DRAFT: {
            // delete the draft
            const data = state.data || {};
            if (data.draft) delete data.draft;
            return { ...state, data: data };
        }

        case ADD_RECORDING: {
            // add a recording item
            console.log("Add recording: ", action.payload);
            let data = state.data || {};
            let draft = data.draft || {};
            let recordings = draft.recordings || [];

            const new_state = {
                ...state,
                data: { ...state.data, draft: { ...draft, recordings: [...recordings.filter((r) => !doRecordingsOverlap(r, action.payload)), action.payload] } }
                // data: { ...state.data, draft: { ...draft, recordings: [...recordings, action.payload] } }

            };
            console.log("Add recording new state:", new_state);
            return new_state;
        }

        case REMOVE_RECORDING: {
            // add a recording item
            let data = state.data || {};
            let draft = data.draft || {};
            let recordings = draft.recordings || [];
            recordings.splice(action.payload, 1);
            return { ...state, data: { ...state.data, draft: { ...draft, recordings: recordings } } };
        }

        case REPLACE_RECORDING: {
            // add a recording item
            let data = state.data || {};
            let draft = data.draft || {};
            let recordings = draft.recordings || [];
            recordings = recordings.map((recording) => {
                if (recording.blob === action.payload.blob) {
                    return action.payload.recording;
                }
                return recording;
            });
            return { ...state, data: { ...state.data, draft: { ...draft, recordings: recordings } } };
        }

        case SET_VIDEO_COLOR:
            return {
                ...state,
                data: { ...state.data, draft: { ...state.data.draft, video_style: { ...state.data.draft.video_style, color: action.payload } } }
            };
        // return { ...state, video_style: { ...state.data.draft.video_style, color: action.payload } };

        case SET_VIDEO_FONT:
            return {
                ...state,
                data: { ...state.data, draft: { ...state.data.draft, video_style: { ...state.data.draft.video_style, font: action.payload } } }
            };
        // return { ...state, video_style: { ...state.video_style, font: action.payload } };
        case SET_VIDEO_DECAL:
            return {
                ...state,
                data: {
                    ...state.data,
                    draft: {
                        ...state.data.draft,
                        video_style: { ...state.data.draft.video_style, decals: [...(state.data?.draft?.video_style?.decals ?? []), action.payload] }
                    }
                }
            };
        case UPDATE_DECAL_COORDINATES:
            return {
                ...state,
                data: {
                    ...state.data,
                    draft: {
                        ...state.data.draft,
                        video_style: {
                            ...state.data.draft.video_style,
                            decals: state.data?.draft?.video_style?.decals.map((decal) => (decal.id === action.payload.id ? action.payload : decal))
                        }
                    }
                }
            };
        default:
            return state;
    }
};

export const getPhrase = (state, sectionIndex, phraseIndex) => {
    // console.log("getPhrase", state);
    // get the phrase from the draft version
    let data = state.data || {};
    let draft = data.draft || {};
    let sections = draft["sections"] || {};
    let section = sections[sectionIndex] || {};
    let phrases = section["phrases"] || {};
    let phrase = phrases[phraseIndex] || "";
    // console.log('getPhrase data', draft);
    // console.log('getPhrase draft', draft);
    // console.log('getPhrase phrase', phrase);
    return phrase;

    // return state.get('data', {}).get('sections', {}).get(sectionIndex).get('phrases', {}).get(phraseIndex, '');
    // if (state && state.sections) {
    //     const section = state.sections[sectionIndex];
    //     if (section) {
    //         return section.phrases[phraseIndex];
    //     }
    // }
};

export const actionCreators = {
    setProject: (project) => ({
        type: SET_PROJECT,
        payload: project
    }),
    updateProject: (project) => ({
        type: UPDATE_PROJECT,
        payload: project
    }),
    setSectionPhrase: (sectionIndex, phraseIndex, phrase) => ({
        type: SET_PHRASE,
        payload: { sectionIndex, phraseIndex, phrase }
    })
};

const ErrorBanner = (props) => {
    if (props.hide) return false;
    return <Banner>{props.message}</Banner>;
};

// calculate song duration
export const calcSongDuration = (song) => {
    // get the start and length
    const section = song.data.song_sections[song.data.song_sections.length - 1];
    const beatsPerMeasure = 4.0;
    const measure_start = section["song_section_measure_start"];
    const beats_long = section["song_section_length"] * beatsPerMeasure;

    if (!measure_start || !beats_long) {
        console.log("Looping section can't find start and length:", section);
        return 0;
    }
    // console.log(measure_start, beats_long);

    // if (song && songSections && props.musicSection < songSections.length) {
    // const section = songSections.filter(props.musicSection];
    // console.log('loop song', song);

    // get the song bpm
    const bpm = song.data["BPM"] || 100;
    const seconds_per_beat = 1.0 / (bpm / 60.0);
    // calculate the start and time in seconds
    const beat_start = (measure_start - 1) * beatsPerMeasure; // we start at 0
    const start = beat_start * seconds_per_beat;
    const length = beats_long * seconds_per_beat;
    return length + start;
};

const ProjectProvider = (props) => {
    const history = useHistory();
    const [showDebug, setShowDebug] = useState(false);
    // const [project, setProject] = useState(null)
    const [store, dispatch] = React.useReducer(projectReducer, {});
    useDevTool("Project State", store);

    // load the project from the projectId property
    const [{ data, loading, error: loadingError }, loadProject] = useAxios(
        {
            url: `/api/ace/projects/${props.projectId}/`,
            method: "GET",
            withCredentials: true,
            baseURL: process.env.REACT_APP_MUZOLOGY_API_URL,
            headers: { "X-CSRFToken": parse_cookies().csrftoken }
        },
        { manual: true }
    );

    // save data
    const [{ error: savingError, loading: isSaving }, saveProject] = useAxios(
        {
            url: `/api/ace/projects/${props.projectId}/`,
            method: "PATCH",
            withCredentials: true,
            baseURL: process.env.REACT_APP_MUZOLOGY_API_URL,
            headers: { "X-CSRFToken": parse_cookies().csrftoken }
        },
        { manual: true }
    );

    // load the project once
    useEffect(() => {
        console.log("[ProjectState] load project");
        loadProject()
            .then((response) => {
                const { data: project } = response;
                console.log("[ProjectState] Project loaded:", project);
                if (project) {
                    // check for a working draft, if it doesn't exist create it
                    const data = project.data ?? {};
                    if (!data.draft) {
                        project.data = { ...data, draft: { ...data } };
                    }

                    // calculate the song duration
                    if (project.song) {
                        project.songDuration = calcSongDuration(project.song);
                        project.song.duration = project.songDuration;
                        project.song.data.duration = project.songDuration;
                    }

                    // save the data into the store
                    dispatch(actionCreators.setProject(project));
                } else {
                    dispatch(actionCreators.setProject({}));
                }
            })
            .catch((error) => {
                // TODO: error message?
                // handleAPIError(error)
            });
    }, []);

    // process the project after its loaded
    // useEffect(() => {
    //     console.log("[ProjectState] process data after load");
    //     if (data) {
    //         // when we get the data, check for a draft, if it doesn't exist, create it
    //
    //         // get the project data
    //         let proj_data = data["data"] || {};
    //
    //         // check for a draft, if it doesn't exist create it
    //         let draft = proj_data.draft;
    //         if (!draft) {
    //             data.data = { ...proj_data, draft: { ...proj_data } };
    //         }
    //
    //         dispatch({ type: SET_PROJECT, payload: data });
    //     } else {
    //         dispatch({ type: SET_PROJECT, payload: {} });
    //     }
    // }, [data]);

    // save the project when it changes
    useEffect(() => {
        // TODO: throttle this
        console.log("[ProjectState] save project");

        let data = { ...store };
        if (store.name === null || store.name === "" || store.name === undefined) return;
        //     data = {...data, name: "[none]"}
        silenceError(saveProject({ data }));
    }, [store]);

    // console.log("Loading", data, loading, loadingError);
    // console.log("Saving", savedData, isSaving, savingError);
    // console.dir(loadingError);

    // error loading project
    if (loadingError) {
        let errorMessage = loadingError.message;
        if (loadingError.isAxiosError && loadingError.request) {
            errorMessage = "Error: " + loadingError.request.statusText;
        }
        return <ActivityIndicator text={errorMessage} style={{}} />;
    }

    // error saving project
    let errorMessage = null;
    if (savingError) {
        errorMessage = savingError.message;
        if (savingError.isAxiosError && savingError.request) {
            errorMessage = "There was an error saving your song: " + savingError.request.statusText;
        }
        // return (<ActivityIndicator text={errorMessage} style={{background: 'red'}}/>);
    }

    // loading indicator
    if (loading) return <ActivityIndicator text={"Loading project, please wait..."} />;

    // no data & not loading = failure to load project
    if (!data) return <ActivityIndicator text={"Project not found..."} />;

    const showDebugger = false;

    // commit this draft as new version
    const commitDraft = () => {
        // make the draft the new data
        let data = store.data || {};
        const draft = data.draft;
        if (data.draft) {
            data = { ...data.draft };
        }
        const project = { ...store, data: data, draft: false, history: [store.history, draft] };
        console.log("Saving project", project);
        return saveProject({ data: { ...project } });
    };

    // destroy this draft and go back to the previous version
    const destroyDraft = () => {
        // delete the draft from the store
        const data = store.data || {};
        if (data.draft) delete data.draft;
        let project = { ...store, data: data };
        return saveProject({ data: { ...project } });
    };

    const exitContext = {
        commitDraft: commitDraft,
        destroyDraft: destroyDraft,
        isSaving: isSaving
    };

    return (
        <DispatchContext.Provider value={dispatch}>
            <StoreContext.Provider value={store}>
                <ExitContext.Provider value={exitContext}>
                    <ErrorBanner hide={!errorMessage} message={errorMessage} />
                    {showDebugger ? <DebugData data={store}>{props.children}</DebugData> : props.children}
                </ExitContext.Provider>
            </StoreContext.Provider>
        </DispatchContext.Provider>
    );
};

/*
    exit actions
        const { commitDraft, destroyDraft, isSaving } = useProjectExitActions();
 */

export function useProjectExitActions() {
    return useContext(ExitContext);
}

// access the project
export function useProject() {
    // console.log('** useProject');
    return useContext(StoreContext);
}

// returns methods useful for manipulating the project
// - yes this is hacky as hell, need to refactor all of this at some point...
export function useProjectEditor() {
    const state = useContext(StoreContext);

    const isProjectModified = () => {
        // checks if the draft data matches the un-draft data
        let data = state["data"] || {};
        let draft = data["draft"] || {};
        const a = { sections: data["sections"] || {}, recordings: data["recordings"] || [] };
        const b = { sections: draft["sections"] || {}, recordings: draft["recordings"] || [] };
        return !deepEqual(a, b);
    };

    const isProjectTemporary = () => {
        // checks if this project is marked "draft" and has no modified sections - if so its a temp project with no edits
        return state && state.draft;
    };

    // returns true if this project is empty
    const isProjectEmpty = () => {
        // checks if the project data is empty
        let data = state["data"] || {};
        let origSections = data["sections"] || {};
        return deepEqual(origSections, {});
    };

    return { isProjectModified, isProjectTemporary, isProjectEmpty };
}

export function useProjectSong() {
    let project = useContext(StoreContext);
    if (project) {
        return project.song;
    }
    return null;
}

export function useProjectSongSections() {
    let song = useProjectSong();
    if (song && song.data) {
        // old
        return song.data.song_sections || [];
        // new
        return song.data.sections || [];
        // return song.data.sections || song.data.song_sections || [];
    }
    return [];
}

export function useProjectSections() {
    const store = useContext(StoreContext);
    let data = store.data || {};
    // return data['sections']
    let draft = data.draft || {};
    return draft["sections"] || {};
}

// access a specific project section
export function useProjectSection(sectionIndex) {
    // console.log('** useProjectSection', sectionIndex);
    // const {getSection} = useContext(ProjectContext);
    // return getSection(sectionIndex);
    const dispatch = useContext(DispatchContext);
    const store = useContext(StoreContext);
    let data = store.data || {};
    let draft = data.draft || {};
    let sections = draft["sections"] || {};
    let section = sections[sectionIndex] || {};

    const setChorusSync = (syncChorus) => {
        // console.log("sync chorus section", sectionIndex, syncChorus);
        // store.phrase = newPhrase;
        dispatch({ type: SET_SECTION_SYNC_CHORUS, payload: { sectionIndex, syncChorus } });
        // console.log("", section, section['sync_chorus']);
    };

    // const {section, setChorusSync} = useProjectSection(sectionIndex);

    return { section, setChorusSync };
}

// access a specific phrase - returns a value and a setter
export function useProjectPhrase(sectionIndex, phraseIndex) {
    // console.log('** useProjectPhrase', sectionIndex, phraseIndex);
    const dispatch = useContext(DispatchContext);
    const store = useContext(StoreContext);
    const phrase = getPhrase(store, sectionIndex, phraseIndex);
    // console.log(store);

    const setPhrase = (newPhrase) => {
        // console.log("save new phrase", newPhrase, phrase);
        // store.phrase = newPhrase;
        dispatch({ type: SET_PHRASE, payload: { sectionIndex, phraseIndex, phrase: newPhrase } });
    };

    return [phrase, setPhrase];
}

// access project actions
export function useProjectActions() {
    const dispatch = useContext(DispatchContext);
    // const store = useContext(StoreContext);
    return {
        setProject: (p) => dispatch({ type: SET_PROJECT, payload: p }),
        renameProject: (name) => dispatch({ type: SET_NAME, payload: name }),
        deleteProjectDraft: (p) => dispatch({ type: DELETE_DRAFT }),
        addRecording: (recording) => dispatch({ type: ADD_RECORDING, payload: recording }),
        removeRecording: (index) => dispatch({ type: REMOVE_RECORDING, payload: index }),
        setVideoColor: (color) => dispatch({ type: SET_VIDEO_COLOR, payload: color }),
        setVideoFont: (font) => dispatch({ type: SET_VIDEO_FONT, payload: font }),
        setVideoDecal: (decal) => dispatch({ type: SET_VIDEO_DECAL, payload: decal }),
        UpdateDecalCoordinates: (coordinates) => dispatch({ type: UPDATE_DECAL_COORDINATES, payload: coordinates })
    };
}

// access the base song
export function useSong() {
    const project = useProject();
    return project.song;
}

// this returns a list of all of my projects
export function useProjectCatalog() {
    const [projects, setProjects] = useState([]);

    const { data, error, isValidating } = useSWR("/api/ace/projects/");
    const loading = useMemo(() => !data && isValidating, [data, isValidating]);

    // const [{ data, loading, error }, getProjects] = useAxios({
    //     url: '/api/ace/projects/',
    //     method: 'GET',
    //     withCredentials: true,
    //     baseURL: process.env.REACT_APP_MUZOLOGY_API_URL,
    //     headers: { 'X-CSRFToken': parse_cookies().csrftoken }
    // }, { manual: true });
    // load the projects one time
    // React.useEffect(() => {
    //     silenceError(getProjects());
    // }, []);

    // console.log(apiURL(`api/ace/projects/`), data);
    // delete project
    const [{ data: delData, loading: deleting, error: delError }, delProject] = useAxios(
        {
            url: "/api/ace/projects/",
            method: "DELETE",
            withCredentials: true,
            baseURL: process.env.REACT_APP_MUZOLOGY_API_URL,
            headers: { "X-CSRFToken": parse_cookies().csrftoken }
        },
        { manual: true }
    );

    const deleteProject = (projectId) => {
        // delete the project
        return delProject({
            url: `/api/ace/projects/${projectId}/`,
            data: {
                id: projectId
            }
        }).then((response) => {
            // remove the project
            setProjects(projects ? projects.filter(({ id }) => id !== projectId) : []);
            console.log("project deleted", response.data);
            return response.data;
        });
    };

    // add a project if it doesnt exist
    const addProject = (project) => {
        if (projects) {
            if (!projects.find((p) => p.id === project.id)) {
                setProjects([...projects, project]);
            }
        }
    };

    // save the projects
    React.useEffect(() => {
        if (error) {
            console.error(error);
        } else if (data) {
            setProjects(data);
        } else {
            setProjects([]);
        }
    }, [data, error]);

    return [projects, deleteProject, loading, error, addProject];
}

export function useStudentProjects() {
    const [projects, setProjects] = useState([]);

    const { data, error, isValidating } = useSWR("/api/ace/projects/students/");
    const loading = useMemo(() => !data && isValidating, [data, isValidating]);
    useDevTool('student projects', projects);

    // save the projects
    React.useEffect(() => {
        if (error) {
            console.error(error);
        } else if (data) {
            setProjects(data);
        } else {
            setProjects([]);
        }
    }, [data, error]);

    return [projects, loading, error];
}

export default ProjectProvider;
