import React, { useEffect, useState } from "react";
import tw, { css } from "twin.macro";
import "styled-components/macro";
import { PauseIcon, PlayIcon } from "../../../home/images";
import useSound from "use-sound";
import { DISABLE_SONG_CREATION } from "../../../../config";
import { mediaURL } from "../../../../api";

const ContentPills = ({ item, isPlaying, setIsPlaying, toggleSelection, isSelected, setIsSelected }) => {
    const songURL = mediaURL(`songs/${item.path}/${item.reference_wav}`);
    const [playSound, { stop }] = useSound(songURL, {
        onend: () => {
            // toggleSelection(false);
            // setIsPlaying(false);
        }
    });

    useEffect(() => {
        if (isPlaying) playSound();
        else stop();
        return stop; // return the stop function as a cleanup function
    }, [isPlaying]);

    return (
        <div
            tw="background[rgba(129, 73, 232, 0.5)] rounded-3xl maxWidth[446px] flex items-center mt-5 py-1.5 pl-2 pr-3 cursor-pointer"
            onClick={setIsSelected}
        >
            <button onClick={() => toggleSelection(item.id)}>{isPlaying ? <PauseIcon /> : <PlayIcon />}</button>
            <div
                tw="text-white text-2xl font-extrabold ml-3 cursor-pointer"
                // onClick={setIsSelected}
            >
                {item.name}
            </div>
            <div tw="ml-auto cursor-pointer">
                {isSelected ? (
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M15 1.25C7.40625 1.25 1.25 7.40625 1.25 15C1.25 22.5937 7.40625 28.75 15 28.75C22.5937 28.75 28.75 22.5937 28.75 15C28.75 7.40625 22.5937 1.25 15 1.25ZM20.96 12.675C21.0697 12.5496 21.1533 12.4034 21.2057 12.2452C21.2582 12.087 21.2784 11.9199 21.2653 11.7537C21.2522 11.5876 21.206 11.4257 21.1295 11.2777C21.0529 11.1296 20.9475 10.9984 20.8194 10.8917C20.6914 10.785 20.5433 10.705 20.3839 10.6563C20.2244 10.6077 20.0569 10.5915 19.8911 10.6085C19.7253 10.6256 19.5646 10.6757 19.4184 10.7558C19.2723 10.8359 19.1436 10.9444 19.04 11.075L13.665 17.5237L10.8837 14.7412C10.648 14.5136 10.3322 14.3876 10.0045 14.3904C9.67675 14.3933 9.36324 14.5247 9.13148 14.7565C8.89972 14.9882 8.76825 15.3018 8.76541 15.6295C8.76256 15.9572 8.88855 16.273 9.11625 16.5087L12.8662 20.2587C12.9891 20.3815 13.1361 20.4773 13.298 20.5401C13.4599 20.6028 13.6331 20.6312 13.8066 20.6233C13.98 20.6154 14.15 20.5715 14.3055 20.4943C14.4611 20.4171 14.5988 20.3084 14.71 20.175L20.96 12.675Z"
                            fill="white"
                        />
                    </svg>
                ) : (
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="15" cy="15" r="13" stroke="white" strokeWidth="2" />
                    </svg>
                )}
            </div>
        </div>
    );
};

/*
function Song({ song, isPlaying, toggleSelection, isSelected, songURL, setIsModalOpen, setIsPlaying, isModalOpen }) {
    // play the song
    const [playSound, { stop }] = useSound(songURL, {
        onend: () => {
            toggleSelection(false);
            setIsPlaying(false);
        }
    });
    React.useEffect(() => {
        // console.log('Playing changed: useEffect', isPlaying, songURL);
        if (isModalOpen) setIsPlaying(false);
        else if (isPlaying) playSound();
        else stop();
        return stop;
    }, [isPlaying]);

    return (
        <>
            <S.Song onClick={toggleSelection} isSelected={isSelected && isPlaying}>
                <S.SongIconContainer>{isPlaying ? <PauseIcon /> : <PlayIcon color={"#ffffff"} />}</S.SongIconContainer>
                <S.SongTitle>{song.name}</S.SongTitle>
                {!DISABLE_SONG_CREATION && <S.SelectButton onClick={() => setIsModalOpen(true)}>select</S.SelectButton>}
            </S.Song>
        </>
    );
}
*/

export default ContentPills;
