import { useMemo, useRef } from "react";
import { useProject, useSong } from "../../ace/context/project/ProjectState";
import { useMeasure } from "react-use";

import { Audio, AbsoluteFill, interpolate, Sequence, useCurrentFrame, useVideoConfig, Img } from "remotion";
import styled from "styled-components/macro";
import Text from "./Text";
import Draggable from "react-draggable";
import { useProjectActions } from "../../ace/context/project/ProjectState";

const Container = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
`;

const StyledComponent = styled.div`
    .element {
        transform: scale(${(props) => props.scale || "1"});

        border: 2px solid ${(props) => (props.selected ? "white" : "transparent")};

        &:hover {
            border: 2px solid white;
        }
    }
`;

const getSongTimestamps = (song) => {
    const { song_sections, BPM } = song.data;
    if (!song_sections) return [];

    const bpm = BPM ?? 100;
    const seconds_per_beat = 1.0 / (bpm / 60.0);

    const beatsPerMeasure = 4.0;

    return song_sections.reduce((timestamps, section, section_index) => {
        const { phrase_timestamps, song_section_measure_start, song_section_length } = section;
        // const beats_long = song_section_length * beatsPerMeasure;
        // const section_time = beats_long * seconds_per_beat
        const phrases = phrase_timestamps.map((ts, phrase_index) => {
            return {
                timestamp: ts,
                phrase: phrase_index,
                section: section_index
            };
        });
        return [...timestamps, ...phrases];
    }, []);
};

const flattenTimestamps = (timestamps) => {
    return timestamps.map((ts) => ts.timestamp);
};
/*
    // get the start and length
    const beatsPerMeasure = 4.0;
    const measure_start = section['song_section_measure_start'];
    const beats_long = section['song_section_length'] * beatsPerMeasure;
    if (!measure_start || !beats_long) {
        console.log("Looping section can't find start and length:", section);
        return;
    }


    // if (song && songSections && props.musicSection < songSections.length) {
    // const section = songSections.filter(props.musicSection];
    // console.log('loop song', song);

    // get the song bpm
    const bpm = song_data['BPM'] || 100
    const seconds_per_beat = 1.0 / (bpm / 60.0);
    // console.log("BPM:", bpm, seconds_per_beat);
    // calculate the start and time in seconds
    const beat_start = (measure_start - 1) * beatsPerMeasure; // we start at 0
    const start = beat_start * seconds_per_beat;
    const length = beats_long * seconds_per_beat;

 */

const getPhrase = (project, section, phrase) => {
    // console.log("draft", project.data?.draft);
    // console.log("section", section, project.data?.draft?.sections[section]);
    // console.log("phrases", phrase, project.data?.draft?.sections[section]?.phrases);
    return project.data?.draft?.sections[section]?.phrases[phrase] ?? "";
};

// return a list of phrases
export function useProjectPhraseList() {
    const project = useProject();
    // console.log("project", project);
    const times = getSongTimestamps(project.song);

    // attach the phrase to each timestamp item
    return times.map((item) => {
        const { phrase, section } = item;

        const p = getPhrase(project, section, phrase);

        return { ...item, text: p };
    });

    // console.log("times", times);
    // const timestamps = flattenTimestamps(times);
    // return project.song;
}

export const LyricsComposition = (props) => {
    // const project = useProject();
    // console.log(props, project);
    const audioRef = useRef();
    const videoConfig = useVideoConfig();
    const frame = useCurrentFrame();
    // console.log(videoConfig, frame);

    const { durationInFrames, fps } = videoConfig;

    // Fade out the animation at the end
    const opacity = interpolate(
        frame,
        // [durationInFrames - 25, durationInFrames - 15],
        [0, 25],
        [0, 1],
        {
            extrapolateLeft: "clamp",
            extrapolateRight: "clamp"
        }
    );

    // const song = useSong();
    // const audioFileMusic = getSongFileURL(song, song.data?.wav);
    // const phrases = useProjectPhraseList();
    const phrases = props.phrases;

    // console.log("phrases", phrases);

    // const song_data = song?.data ?? {};
    const sections = [];

    // const song = useSong();
    // console.log("song", song);
    // const times = getSongTimestamps(song);
    // console.log("times", times);
    // const timestamps = flattenTimestamps(times);
    // console.log("ts", timestamps);
    // const phraseList = useProjectPhraseList();
    const times = useProjectPhraseList();
    // console.log("times", times);

    const style = { opacity: 1, display: "flex", alignItems: "center", justifyContent: "center" };
    for (let i = 0; i <= phrases.length - 1; i++) {
        let item = phrases[i];

        // get the beginning (cur) and end of this phrase
        // const song_data = song?.data ?? {};
        // const sections = [];
        // let cur = 0;
        //
        // for (let i = 0; i < times.length - 1; i++) {
        //     let item = times[i];
        //     let item1 = times[i + 1];

        let cur = item.timestamp;
        let end = props.songDuration;
        if (i === phrases.length - 1) {
            // use the end of the song as the end
            end = props.songDuration;
        } else {
            // use the next phrase timestamp as the end
            let item1 = phrases[i + 1];
            end = item1.timestamp;
        }

        // let end = timestamps[i];
        // calculate the duration & frames
        let dur = end - cur;
        let start = Math.ceil(fps * cur);
        let frames = Math.ceil(fps * dur);

        // console.log(cur, end, start, frames);
        cur = end;
        // console.log("item", item);
        sections.push(
            <Sequence key={i} from={start} durationInFrames={frames} style={style}>
                <Text>{item.text}</Text>
            </Sequence>
        );
    }

    // const sequences = sections.map()
    // return (
    //     <>
    //         <AbsoluteFill style={{ backgroundColor: props.background, fontFamily: props.font }}>
    //             <AbsoluteFill style={{ opacity: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
    //                 {sections}
    //             </AbsoluteFill>
    //
    //             {/*
    //             {props.audioTrackURL && <Audio
    //                 ref={audioRef}
    //                 src={props.audioTrackURL}
    //                 // startFrom={0}
    //                 // endAt={120}
    //             />}
    //             */}
    //
    //         </AbsoluteFill>
    //     </>

    const decalElements = useMemo(() => {
        const elements = props.decals || [];
        return elements.map((element) => {
            if (element.id === props.hideDragging) return null;
            return (
                <Img
                    style={{ left: element.x, top: element.y, width: element.width ?? 180, height: element.height ?? 180, position: "absolute" }}
                    src={element.url}
                    // width={'180px'}
                    // height={'180px'}
                    // scale={(width / 1920.0)}
                    // scale={1}
                    
                />
            );
        });
    }, [props.decals, props.hideDragging]);

    return (
        <AbsoluteFill style={{ backgroundColor: props.background, fontFamily: props.font }}>
            <AbsoluteFill style={{ opacity: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>{sections}</AbsoluteFill>

            <AbsoluteFill>
                <Container>
                    <div className={"elements"}>{decalElements}</div>
                </Container>
            </AbsoluteFill>
        </AbsoluteFill>
    );
};
