import React from "react";
import muzologyLogo from "../../../assets/muzology-logo.png";
import SelectionUI from "./SelectionUI/SelectionUI";
import "twin.macro";
import "styled-components/macro";


const SongSelectionAndTopics = (props) => {
    const topics = false;
    const topic = props.topic;
    console.log("Topic", topic);
    return (
        <div tw="background[linear-gradient(156.44deg, #2725AA 6.53%, #8149E8 80.2%)] h-full">
            {/*
            <div tw="flex justify-between items-center px-7 mt-4">
                <div>{topics ? <div tw="text-white font-bold text-xl">EdutainMe!</div> : <img src={muzologyLogo} />}</div>
                <div>
                    <svg width="26" height="27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M24.108 22.235 4.5222 1.5882c-.832-.8771-2.1811-.8771-3.0132 0-.832.8772-.832 2.2993 0 3.1764l19.5858 20.6468c.8321.8772 2.1811.8772 3.0132 0 .8321-.8771.8321-2.2993 0-3.1764Z"
                            fill="#fff"
                        />
                        <path
                            d="M21.0924 1.5882 1.5066 22.235c-.832.8772-.832 2.2993 0 3.1765.832.8771 2.1811.8771 3.0132 0L24.1056 4.7647c.832-.8772.832-2.2993 0-3.1765-.8321-.8771-2.1812-.8771-3.0132 0Z"
                            fill="#fff"
                        />
                    </svg>
                </div>
            </div>
            */}

            <div tw="mb-5 marginTop[30px] text-center text-white font-bold text-xl">
                Pick a song to make your music video
            </div>

            {/*
            {topics && (
                <div tw="width[350px] flex justify-center margin[auto]">
                    <div tw="w-full relative minWidth[300px]">
                        <input tw="p-2 pl-10 bg-white rounded-md w-full placeholder-DarkLiver" type="search" placeholder={"Search Topics"} />
                        <svg tw="w-4 h-4 absolute left-3 top-1/4" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M16.6539 15.5239L11.328 10.198C12.1545 9.12959 12.6016 7.82324 12.6016 6.44922C12.6016 4.80449 11.9597 3.2623 10.7989 2.09951C9.63818 0.936719 8.09189 0.296875 6.44922 0.296875C4.80654 0.296875 3.26025 0.938769 2.09951 2.09951C0.936719 3.26025 0.296875 4.80449 0.296875 6.44922C0.296875 8.09189 0.938769 9.63818 2.09951 10.7989C3.26025 11.9617 4.80449 12.6016 6.44922 12.6016C7.82324 12.6016 9.12754 12.1545 10.196 11.3301L15.5219 16.6539C15.5375 16.6695 15.556 16.6819 15.5764 16.6904C15.5969 16.6988 15.6187 16.7032 15.6408 16.7032C15.6629 16.7032 15.6848 16.6988 15.7052 16.6904C15.7256 16.6819 15.7441 16.6695 15.7598 16.6539L16.6539 15.7618C16.6695 15.7462 16.6819 15.7277 16.6904 15.7072C16.6988 15.6868 16.7032 15.665 16.7032 15.6429C16.7032 15.6208 16.6988 15.5989 16.6904 15.5785C16.6819 15.5581 16.6695 15.5395 16.6539 15.5239ZM9.69766 9.69766C8.82812 10.5651 7.67559 11.043 6.44922 11.043C5.22285 11.043 4.07031 10.5651 3.20078 9.69766C2.3333 8.82812 1.85547 7.67559 1.85547 6.44922C1.85547 5.22285 2.3333 4.06826 3.20078 3.20078C4.07031 2.3333 5.22285 1.85547 6.44922 1.85547C7.67559 1.85547 8.83018 2.33125 9.69766 3.20078C10.5651 4.07031 11.043 5.22285 11.043 6.44922C11.043 7.67559 10.5651 8.83018 9.69766 9.69766Z"
                                fill="#6A7277"
                            />
                        </svg>
                    </div>
                </div>
            )}
            */}

            <div tw="maxWidth[877px] mx-auto mt-5">
                <SelectionUI
                    topic={topic}
                    goBackToTopic={props.goBackToTopic}
                />
            </div>

        </div>
    );
};

export default SongSelectionAndTopics;
