import React from "react";
import tw, { css } from "twin.macro";
import styled from "styled-components/macro";
import WhiteChevronDown from "../static/white_chevron_down.png";
import WhiteChevronUp from "../static/white_chevron_up.png";
import { ModeLyricsIcon, ModePreviewIcon, ModeRecordIcon, ModeVideoIcon } from "./ModeIcons";
import { useHistory } from "react-router-dom";
import { replaceQueryParam } from "../../lib/utils/urls";

const S = {

    LyricSheetButton: styled.button`
        background: ${(props) => props.backgroundColor ? props.backgroundColor : props.active ? "linear-gradient(127.87deg, #E637A8 16.09%, #8149E8 83.8%)" : "rgba(116, 5, 76, 0.5)"};
        //display: flex;
        //align-items: center;
        //justify-content: center;
        //border-radius: 50%;
        //height: 45px;
        //width: 45px;
        //margin: auto;
        //user-select: none;
        //border: none;
        //cursor: pointer;

        &:focus {
            outline: none;
        }

        &:active {
            opacity: 60%;
        }

        &:hover {
            background: ${(props) => props.hover};

            img {
                opacity: 60%;
            }
        }
    `,

    LyricSheetButtonText: styled.div`
        color: white;
        font-weight: 600;
        font-size: 12px;
        margin-top: 2px;
    `

};

export const EditModes = {
    LYRICS: "lyrics",
    RECORD: "record",
    VIDEO: "video",
    PREVIEW: "preview"
};

const EDIT_MODES = [
    {
        mode: EditModes.LYRICS,
        title: "Lyrics",
        icon: <ModeLyricsIcon />
    },
    {
        mode: EditModes.RECORD,
        title: "Vocals",
        icon: <ModeRecordIcon />
    },
    {
        mode: EditModes.VIDEO,
        title: "Video",
        icon: <ModeVideoIcon />
    },
    {
        mode: EditModes.PREVIEW,
        title: "Preview",
        icon: <ModePreviewIcon />
    }
];

const ModeButtons = ({ songEditMode, setSongEditMode }) => {
    const history = useHistory();

    const setModeParam = (mode) => {
        replaceQueryParam(history, "mode", mode);
        // const queryParams = new URLSearchParams(window.location.search);
        // queryParams.set("mode", mode);
        // history.replace({
        //     // pathname: '/app/search',
        //     search: queryParams.toString()
        // });
    };

    const handleSelection = (mode) => {
        if (setSongEditMode)
            setSongEditMode(mode);
        replaceQueryParam(history, "mode", mode);
    };

    return (
        <div tw="absolute right[0] top[80px] zIndex[1000]">
            <div
                className={"stacked-buttons-container"}
                tw={"flex flex-col items-center padding[22px 17px] background[rgba(250, 250, 255, 0.4)] borderTopLeftRadius[16px] borderBottomLeftRadius[15px]"}
            >
                {EDIT_MODES.map(mode => (
                    <div
                        key={mode.mode}
                        tw="pt-4"
                        css={[(songEditMode !== mode.mode) && css`opacity: 50%;`]}
                    >
                        <div
                            onClick={() => handleSelection(mode.mode)}
                            // active={songEditMode === mode.mode}
                            // hover={"linear-gradient(127.87deg, #E637A8 16.09%, #8149E8 83.8%)"}
                            tw="flex items-center justify-center height[45px] width[45px] margin[auto] borderRadius[50%] border-none select-none cursor-pointer active:opacity[60%] background[rgba(116, 5, 76, 0.5)] hover:background[linear-gradient(127.87deg, #E637A8 16.09%, #8149E8 83.8%)] active:background[linear-gradient(127.87deg, #E637A8 16.09%, #8149E8 83.8%)]"
                            // selected mode background
                            css={[songEditMode === mode.mode && css`background: linear-gradient(127.87deg, #E637A8 16.09%, #8149E8 83.8%);`]}
                        >
                            {mode.icon}
                        </div>
                        <div tw="text-white font-semibold text-xs marginTop[2px]">
                            {mode.title}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};


export default ModeButtons;
