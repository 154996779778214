import React, { useState } from "react";
import tw, { css } from "twin.macro";
import "styled-components/macro";
import VideoPlayerImage from "./Video Player.png";
import VideoScreen from "./VideoScreen";
import DecalPalette from "./DecalPalette";
import PaletteButtons from "./PaletteButtons";
import ColorsPalette from "./ColorsPalette";
import FontsPalette from "./FontsPalette";

import { Player } from "@remotion/player";
import { MyComposition } from "../../../remotion/Composition";
import { LyricsComposition } from "../../../remotion/LyricsVideo/LyricsComposition";
import VideoEditor from "./VideoEditor";

const VideoMode = (props) => {
    let { activeSection } = props;
    const [paletteSelected, setPaletteSelected] = useState("Stickers");
    return (
        <div tw="relative flex background[white] flex-grow marginBottom[0] marginTop[20px] marginLeft[36px] marginRight[108px] borderTopLeftRadius[30px] borderTopRightRadius[30px] overflow-hidden">
            {/*
            <div tw={'width[500px] height[100%] background[blue] flexShrink[0]'}/>
            */}
            <div
                tw={
                    "flex flex-col flexShrink[1] flexGrow[1] flexBasis[60%] height[100%] background[#00000000] paddingLeft[26px] paddingTop[40px] paddingRight[26px] paddingBottom[0]"
                }
            >
                <div tw="left[0] top[0] relative marginBottom[20px] fontSize[21.4348px] lineHeight[29px] fontWeight[800] fontFamily[Arial]">
                    Create your lyric video with colors, fonts, and stickers!
                </div>

                <div tw='h-full pt-10'>
                    <VideoEditor />
                    {/* <VideoScreen activeSection={activeSection} /> */}
                </div>

                <div tw="height[10px]" />
            </div>

            <div tw={"flexShrink[0] flexGrow[1] flexBasis[40%] height[100%] flex flex-col"}>
                <PaletteButtons selected={paletteSelected} setSelected={setPaletteSelected} />
                <div tw={"background[#EDEDED] flex-grow borderColor[#D749E8] borderWidth[2px] borderTopLeftRadius[30px]"}>
                    {paletteSelected === "Stickers" && <DecalPalette />}
                    {paletteSelected === "Colors" && <ColorsPalette />}
                    {paletteSelected === "Fonts" && <FontsPalette />}
                </div>
            </div>
        </div>
    );
};

export default VideoMode;
