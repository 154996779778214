import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import clickPath from '../static/click.wav';
import Metronome from '../lyrics/metronome/Metronome';

const S = {
    Countdown: styled.div`
      background: conic-gradient(from 0deg, #e637a8, #14d1df);
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-self: center;
      padding: 16px;
    `,
    CounterContainer: styled.div`
      width: 300px;
      height: 300px;
      background: #1f2365;
      border-radius: 100%;
    `,
    CountdownNumber: styled.h1`
      margin-top: 1rem;
      margin-right: 0.5rem;
      color: white;
      font-weight: 800;
      font-size: 208px;
    `
};


const Countdown = ({ value, playing, togglePlaying, handleFinishedCountdown, countdownStarted }) => {

    useEffect(() => {
        if (playing) {
            if (value === 0) {
                togglePlaying();
                handleFinishedCountdown();
            }
        }
    }, [playing, value]);

    useEffect(() => {
        if (countdownStarted && !playing) {
            console.log('Start ticks');
            // start the metronome
            togglePlaying();
        }
    }, [countdownStarted, playing]);

    // start on the second beat, end on the 5th beat
    if (value === 0 || value >= 5)
        return null;

    return (
        <S.Countdown>
            <S.CounterContainer>
                <S.CountdownNumber>{value}</S.CountdownNumber>
            </S.CounterContainer>
        </S.Countdown>
    );
};


export default function CountdownContainer({ countdownStarted, onFinished, bpm }) {
    const [num, setNum] = useState(6);
    const click = new Audio(clickPath);

    const tick = (x) => {
        setNum((n) => n - 1);
    };

    useEffect(() => {
        if (num > 0 && num <= 4) {
            click.play();
        }
    }, [num]);

    return (
        <Metronome
            onTick={tick}
            autoplay={false}
            tempo={bpm}
            render={({ playing, onPlay }) => (
                <Countdown
                    value={num}
                    playing={playing}
                    togglePlaying={onPlay}
                    handleFinishedCountdown={onFinished}
                    countdownStarted={countdownStarted}
                />
            )}
        />
    );
}

