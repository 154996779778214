/**
 * gets the current timestamp in seconds
 * @returns {number} the current time in seconds with millisecond resolution
 */

import moment from 'moment';

export function getCurrentTimestamp() {
    return Date.now() / 1000.0;
}

/**
 * gets the current timestamp in milliseconds
 * @returns {number} the current time in milliseconds
 */

export function getCurrentTimestampMS() {
    return Date.now();
}

/**
 * format a timestamp to 'mm:ss'
 * @param {number} secs - the timestamp in seconds
 * @returns {string} formatted string
 */
export const toMMSS = (secs) => {
    let sec_num = parseInt(secs, 10); // don't forget the second param
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - hours * 3600) / 60);
    let seconds = sec_num - hours * 3600 - minutes * 60;
    if (seconds < 10) {
        seconds = `0${  seconds}`;
    }
    return `${minutes  }:${  seconds}`;
};

/**
 * format a timestamp to 'hh:mm:ss'
 * @param {number} secs - the timestamp in seconds
 * @returns {string} formatted string
 */

export const toHHMMSS = (secs) => {
    let sec_num = parseInt(secs, 10); // don't forget the second param
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - hours * 3600) / 60);
    let seconds = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
        hours = `0${  hours}`;
    }
    if (minutes < 10) {
        minutes = `0${  minutes}`;
    }
    if (seconds < 10) {
        seconds = `0${  seconds}`;
    }
    return `${hours  }:${  minutes  }:${  seconds}`;
};

export const toHHMM = (secs) => {
    let sec_num = parseInt(secs, 10); // don't forget the second param
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - hours * 3600) / 60);
    let seconds = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
        // hours = '0' + hours;
    }
    if (minutes < 10) {
        minutes = `0${  minutes}`;
    }
    if (seconds < 10) {
        seconds = `0${  seconds}`;
    }

    return hours === 0 ? `${minutes} min` : `${hours}hr ${minutes}min`;
};

export const toMM = (secs) => {
    let sec_num = parseInt(secs, 10); // don't forget the second param
    let minutes = Math.floor((sec_num * 3600) / 60);
    return `${minutes  }min.`;
};

export const toDDHHMM = (secs) => {
    let seconds = parseInt(secs, 10);
    let days = Math.floor(seconds / (3600 * 24));
    seconds -= days * 3600 * 24;
    let hrs = Math.floor(seconds / 3600);
    return `${days} days ${hrs} hrs`;
};

// time since a UTC time
export const fromNow = (date) => moment.utc(date).local().fromNow();
