import React from 'react';
import styled from 'styled-components'
import Placeholder from "../common/Placeholder";

const S = {
    Container: styled.div``,
}

const Inbox = (props) => {
    return (
        <Placeholder text={"Inbox"}/>
    );
};


export default Inbox;
