// upload a file
import useAxios from "axios-hooks";
import { parse_cookies } from "../../../api";
import { useProject } from "./ProjectState";

export function useProjectFileUpload() {

    const project = useProject();

    // save data
    const [{ error: uploadError, loading: isUploading }, putFile] = useAxios(
        {
            url: `/ace/files/`,
            method: "PUT",
            withCredentials: true,
            baseURL: process.env.REACT_APP_MUZOLOGY_API_URL,
            headers: { "X-CSRFToken": parse_cookies().csrftoken }
        },
        { manual: true }
    );

    // convert a blob to a file object
    async function convertToFile(url) {
        let response = await fetch(url);
        let blob = await response.blob();
        return new File([blob], "test.wav", {
            type: "audio/wav"
        });
    }

    const uploadFile = async (blobUrl) => {
        // convert the blob to a file
        console.log("Uploading blob URL: ", blobUrl);
        const file = await convertToFile(blobUrl); // usage of function above
        console.log("Blob File:", file);

        // // read the file
        // const reader = new FileReader();
        // const data = reader.readAsDataURL(file);
        // console.log(data);

        // construct form data to upload
        const formData = new FormData();
        formData.append("file", file);
        formData.append("project_id", project.id);
        // formData.append("tags", `codeinfuse, medium, gist`);
        // formData.append("upload_preset", "pvhilzh7"); // Replace the preset name with your own
        // formData.append("api_key", "1234567"); // Replace API key with your own Cloudinary key
        // formData.append("timestamp", (Date.now() / 1000) | 0);

        // console.log('Uploading media file');
        return putFile({ data: formData }).then((response) => response.data);
        // return api.put("/api/project_file/?project_id=" + props.project_id, formData, {
        //     headers: {
        //         'Content-Type': 'multipart/form-data',
        //         "X-Requested-With": "XMLHttpRequest",
        //         "Content-Disposition": `attachment; filename="${file.name}"`
        //     },
        // }).then(response => {
        //     const data = response.data;
        //     // const fileURL = data.secure_url // You should store this URL for future references in your app
        //     console.log(data);
        //     items.push(data);
        // })
        //
    };

    return { uploadFile, uploadError, isUploading };
}