import React from "react";
import tw, { css } from "twin.macro";
import styled from "styled-components/macro";
import StickersIcon from "./stickers-icon.png";
import ColorsIcon from "./colors-icon.png";
import FontsIcon from "./fonts-icon.png";


const PaletteButton = ({ icon, name, setSelected, selected }) => {
    return (
        <div
            tw={"marginTop[auto] marginBottom[auto] flex flex-col items-center justify-center width[60px] cursor-pointer"}
            css={[
                css`
                `,
                (!selected) && css`
                    opacity: 50%;
                    &:hover {
                        opacity:75%;
                        //background: red;
                    }
                    &:active {
                        opacity:25%;
                        //background: red;
                    }
                    
                `,
            ]}
            onClick={setSelected}
        >
            <img src={icon} />
            {name}
        </div>
    );
};

const PaletteButtons = ({ setSelected, selected }) => {

    const buttons = [
        {
            name: "Stickers",
            icon: StickersIcon
        },
        {
            name: "Colors",
            icon: ColorsIcon
        },
        {
            name: "Fonts",
            icon: FontsIcon
        }
    ];
    return (
        <div tw={"width[235px] flex flex-row items-center justify-evenly height[80px] background[#E2DDFF] alignSelf[flex-end] "}>
            {buttons.map(item => (
                <PaletteButton key={item.name} {...item} selected={item.name === selected} setSelected={() => setSelected(item.name)} />
            ))}
        </div>
    );
};

export default PaletteButtons;
