import React, { useState } from "react";
import styled from "styled-components";
import muzologyLogo from "../../assets/muzology-logo.png";
import { Link, NavLink, useHistory } from "react-router-dom";
import { CloseIcon } from "../home/images";
import Wheel from "../static/wheel.png";
import "twin.macro";
import "styled-components/macro";
import { Popup } from "semantic-ui-react";
// import { Button } from "semantic-ui-react";
import Person1 from "../../static/images/ace-characters_character-1.png";
import Person2 from "../../static/images/ace-characters_character-2.png";

const S = {
    Container: styled.div`
        background: ${(props) => (props.noBackground ? "" : "linear-gradient(180deg, #1d215f 0%, #06093a 100%)")};
        position: relative;
        z-index: 1000000;

        height: 70px;
        min-height: 70px;
        width: 100%;

        color: white;
        font-size: 60px;
        line-height: 70px;
        text-align: center;

        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 60px;
    `,

    LogoImage: styled.div`
        color: white;
        font-size: 60px;
        text-align: center;
        padding-top: ${(props) => (props.paddingTop ? "0px" : "10px")};
    `,

    LinkList: styled.div`
        font-family: Avenir, serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: center;

        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        &.selected {
            border: 2px solid #ffffff;
        }
    `,

    Link: styled(NavLink)`
        text-decoration: none;
        display: block;
        color: white;

        font-family: Avenir, serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: center;

        margin-left: 30px;

        &.selected {
            border-bottom: 2px solid #ffffff;
        }
    `,

    CloseButton: styled.button`
        color: white;
        background: none;

        border: none;
        cursor: pointer;

        &:focus {
            outline: none;
        }

        &:hover {
            opacity: 75%;
        }

        &:active {
            opacity: 60%;
        }
    `,

    Title: styled.div`
        font-family: Avenir, serif;
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        line-height: 33px;
        text-align: center;

        /* Aqua */
        color: #14d1df;

        // position in the middle of the parent
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        // center the contents
        display: flex;
        justify-content: center;
        align-items: center;
    `,

    StyledPopUp: styled(Popup)({
        marginTop: "30px!important",
        marginLeft: "100px!important"
    }),

    LogoutWheel: styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-left: 12px;
        padding: 0px 10px;
        height: 100%;

        &.active {
            background: rgba(255, 255, 255, 0.2);
        }
    `,

    LogoutContainer: styled.div`
        position: relative;
        width: 215px;
        height: 105px;
        background: #1f2365;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        .logout-x {
            position: absolute;
            top: -10px;
            right: -10px;
            height: 34px;
            width: 34px;
            color: #fff;
            background: linear-gradient(180deg, #1d215f 0%, #06093a 100%);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 22px;
            cursor: pointer;
        }
    `,

    LogoutButton: styled.button`
        width: 180px;
        height: 40px;
        border: none;
        font-size: 16px;
        font-weight: 800;
        text-transform: uppercase;
        border-radius: 50px;
        cursor: pointer;
        color: #fff;
        background: #00b1c9;

        &:hover {
            background: linear-gradient(127.87deg, #00b1c9 16.09%, #8149e8 83.8%);
        }
    `
};

const TitleBar = ({ closeButton, teacher, title, showLinks, onClose, lyricCreationPage, showCollab }) => {
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);

    return (
        <S.Container noBackground={lyricCreationPage}>
            <S.LogoImage paddingTop={closeButton}>
                <img src={muzologyLogo} alt={"home"} />
                {/*
                <Link to={"/"}>
                    <img src={muzologyLogo} alt={"home"} />
                </Link>
                */}
            </S.LogoImage>

            {title && <S.Title>{title}</S.Title>}

            {showLinks && (
                <S.LinkList>
                    <S.Link key="create" to="/home" activeClassName="selected">
                        TOPIC / SONG SELECTION
                    </S.Link>
                    <S.Link key="songs" to="/my-songs" activeClassName="selected">
                        MY SONGS
                    </S.Link>
                    {teacher && <S.Link key="student-songs" to="/student-songs" activeClassName="selected">
                        MY STUDENT'S SONGS
                    </S.Link>}  
                    <S.StyledPopUp
                        on="click"
                        open={isOpen}
                        onOpen={() => setIsOpen(!isOpen)}
                        position="bottom right"
                        trigger={
                            <S.LogoutWheel onClick={() => setIsOpen(!isOpen)} className={isOpen === true ? "active" : ""}>
                                <img src={Wheel} />
                            </S.LogoutWheel>
                        }
                    >
                        <Popup.Content>
                            <S.LogoutContainer>
                                <S.LogoutButton onClick={() => history.push("/logout")}>LOG OUT</S.LogoutButton>
                                <div className={"logout-x"} onClick={() => setIsOpen(false)}>
                                    X
                                </div>
                            </S.LogoutContainer>
                        </Popup.Content>
                    </S.StyledPopUp>
                </S.LinkList>
            )}

            {showCollab && (
                <div tw="w-4/5 xl:w-3/5 flex justify-end">
                    {/*
                    <Button tw="hover:opacity[75%] active:opacity[50%] py-2 px-8 background[#14D1DF] rounded-full uppercase text-sm font-extrabold leading-normal text-white mr-2">
                        COLLAB
                    </Button>
                    */}
                    <img tw="zIndex[100]" src={Person1} />
                    <img tw="marginLeft[-16px]" src={Person2} />
                </div>
            )}

            {closeButton && (
                <S.CloseButton onClick={onClose}>
                    <CloseIcon />
                </S.CloseButton>
            )}
        </S.Container>
    );
};

export default TitleBar;
