import React from 'react';
import { css } from "twin.macro";
import "styled-components/macro";

const BorderGradient = css`
    color: #313149;
    /* display: inline-block; */
    position: relative;
    z-index: 0;

    &:before {
        content: '';
        position: absolute;
        z-index: -1;
        inset: 0;
        padding: 3px;
        border-radius: 99px;
        background: linear-gradient(93.18deg, #1CC8E1 1.16%, #D022ED 98.48%);
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
    }
`;

const SuperstarPowerBar = () => {
    return (
        <div>
            <div tw='height[440px] width[29px] background[rgba(42, 38, 77, 0.5)] rounded-3xl relative' css={[BorderGradient]}>
                <div tw='absolute top[-22px] left[-15px] right-0'>
                    <svg width="61" height="58" viewBox="0 0 61 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_2996_38028)">
                            <path d="M31.9508 1.09591L37.8248 14.4934C38.0457 14.9976 38.398 15.4333 38.8447 15.7549C39.2915 16.0766 39.8164 16.2724 40.3646 16.322L54.6742 17.605C56.2933 17.8406 56.9392 19.8263 55.7652 20.9696L44.9861 30.025C44.1133 30.7581 43.7162 31.9102 43.9562 33.023L47.0896 47.6905C47.3645 49.3008 45.6756 50.5315 44.2268 49.7678L31.737 42.4537C31.2659 42.1772 30.7296 42.0314 30.1834 42.0314C29.6372 42.0314 29.1009 42.1772 28.6298 42.4537L16.14 49.7634C14.6955 50.5227 13.0023 49.2964 13.2772 47.6861L16.4106 33.0187C16.6462 31.9059 16.2534 30.7538 15.3806 30.0206L4.59716 20.974C3.42761 19.835 4.07348 17.845 5.68817 17.6093L19.9978 16.3263C20.546 16.2768 21.0709 16.0809 21.5177 15.7593C21.9644 15.4377 22.3167 15.002 22.5376 14.4978L28.4116 1.10028C29.1404 -0.366031 31.2264 -0.366031 31.9508 1.09591V1.09591Z" fill="#FDD835" />
                            <path d="M31.2703 14.1151L30.3606 4.45982C30.3247 3.92199 30.221 3 31.0269 3C31.6653 3 32.0124 4.4214 32.0124 4.4214L34.7413 12.173C35.7707 15.1225 35.3478 16.1341 34.3543 16.7317C33.2133 17.4146 31.5296 16.8811 31.2703 14.1151V14.1151Z" fill="#FFFF8D" />
                            <path d="M44.5322 28.2872L53.4943 21.7099C53.9374 21.3619 54.7368 20.8077 54.0973 20.1761C53.5902 19.6778 52.2199 20.3952 52.2199 20.3952L44.3769 23.2779C42.0382 24.0384 40.4851 25.1639 40.3481 26.5817C40.1699 28.472 41.9742 29.9284 44.5322 28.2872Z" fill="#F4B400" />
                        </g>
                        <path d="M30.7475 10.9394L30.0197 4.04273C29.991 3.65856 29.908 3 30.5528 3C31.0635 3 31.3411 4.01529 31.3411 4.01529L33.5243 9.55211C34.3478 11.6589 34.0095 12.3815 33.2147 12.8083C32.3019 13.2962 30.9549 12.9151 30.7475 10.9394V10.9394Z" fill="#FFFF8D" />
                        <path d="M46.2971 26.4456L53.3387 21.3299C53.6868 21.0592 54.3149 20.6282 53.8125 20.137C53.4141 19.7494 52.3374 20.3074 52.3374 20.3074L46.175 22.5495C44.3375 23.1409 43.1172 24.0164 43.0095 25.1191C42.8696 26.5893 44.2872 27.7221 46.2971 26.4456Z" fill="#F4B400" />
                        <defs>
                            <filter id="filter0_d_2996_38028" x="0" y="0" width="60.3652" height="58" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="4" />
                                <feGaussianBlur stdDeviation="2" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2996_38028" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2996_38028" result="shape" />
                            </filter>
                        </defs>
                    </svg>

                </div>
            </div>

        </div>


    );
};

export default SuperstarPowerBar;