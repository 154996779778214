import axios from "axios";
import {configure} from 'axios-hooks'

export const mediaURL = (path) => `${process.env.REACT_APP_MUZOLOGY_MEDIA_URL}${path}`
export const apiURL = (path) => `${process.env.REACT_APP_MUZOLOGY_API_URL}${path}`

export function parse_cookies() {
    let cookies = {};
    if (document.cookie && document.cookie !== '') {
        document.cookie.split(';').forEach(function (c) {
            let m = c.trim().match(/(\w+)=(.*)/);
            if (m !== undefined) {
                cookies[m[1]] = decodeURIComponent(m[2]);
            }
        });
    }
    return cookies;
}


export const apiFetcherAnonymous = url => axios.get(url, {withCredentials: false}).then(res => res.data)

export const apiFetcher = url => axios.get(url, {
    withCredentials: true,
    baseURL: process.env.REACT_APP_MUZOLOGY_API_URL,
    headers: {'X-CSRFToken': parse_cookies().csrftoken},
}).then(res => res.data)

// combine a list of paths without double slashes
export const buildPath = (...args) => {
    return args.map((part, i) => {
        if (i === 0) {
            return part.trim().replace(/[\/]*$/g, '')
        } else {
            return part.trim().replace(/(^[\/]*|[\/]*$)/g, '')
        }
    }).filter(x => x.length).join('/')
}

export const onErrorRetry = (error, key, config, revalidate, {retryCount}) => {
    console.log('onErrorRetry', retryCount, key, error.message)

    // Never retry on 404.
    if (error.status === 404) {
        console.log("API Error: 404 NOT FOUND: ", key);
        return
    }

    // Never retry for a specific key.
    // if (key === '/api/user')
    //     return

    // Only retry up to 10 times.
    if (retryCount >= 2)
        return

    // Retry after 5 seconds.
    setTimeout(() => revalidate({retryCount: retryCount + 1}), 5000)
}

// configure axios for useAxios hook
const instance = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_MUZOLOGY_API_URL,
    headers: {'X-CSRFToken': parse_cookies().csrftoken}
})

configure({instance})

export const silenceError = (promise) => promise.catch(() => {})

// export a default axios instance
export default instance;
