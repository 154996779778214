import React, { useContext, useEffect, useMemo, useReducer } from "react";
import { useImmer } from "use-immer";
import axios, { apiURL } from "../../api";
import useSWR from "swr/esm";
import { useDevTool } from "../../lib/DevTool/DevTool";

export const ACTION = "ACTION";

const StoreContext = React.createContext();

// const initialState = {
//     items: {}
// };
//
// const reducer = (state, action) => {
//     switch (action.type) {
//         case ACTION:
//             return { ...state, items: action.payload };
//         default:
//             return state;
//     }
// };

const TopicProvider = (props) => {
    // const [store, dispatch] = useReducer(reducer, initialState, null);
    const [store, updateStore] = useImmer({
        topics: {},
        subjects: {},
        definitions: {}
    });
    useDevTool("Topic Provider", store);

    const addSubject = (subject) => updateStore(draft => {
        draft.subjects[subject.id] = subject;
    });
    const addSubjects = (subjects) => updateStore(draft => {
        draft.subjects = subjects.reduce((all, subject) => ({ ...all, [subject.id]: subject }), draft.subjects);
    });
    const addTopic = (topic) => updateStore(draft => {
        draft.topics[topic.id] = topic;
    });
    const addTopics = (topics) => updateStore(draft => {
        topics?.forEach(topic => {
            draft.topics[topic.id] = topic;
        });
    });
    const addDefinition = (definition) => updateStore(draft => {
        draft.definitions[definition.id] = definition;
    });


    useEffect(() => {
        axios.get(apiURL("/api/ace/subjects/"))
            .then(({ data }) => addSubjects(data));
        axios.get(apiURL("/api/ace/topics/"))
            .then(({ data }) => addTopics(data));
        axios.get(apiURL("/api/ace/definitions/"))
            .then(({ data }) => {
                if (data) {
                    data.forEach(definition => addDefinition(definition));
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const performAction = (arg) => {
        // dispatch({ type: ACTION, payload: arg });
        // // .then(r => {
        // //     console.log(r);
        // // });
    };

    return (
        <StoreContext.Provider value={store}>
            {props.children}
        </StoreContext.Provider>
    );
};


export function useSubjects() {
    const store = useContext(StoreContext);
    if( !store )
        return [];
    return store.subjects;
}

export function useSubject(subjectId) {
    const store = useContext(StoreContext);

    // attach the topics and memoize
    return useMemo(() => {

        // create a topic with definitions
        function getTopicWithDefinitions(topicId) {
            const topic = store.topics[topicId];
            const definitions = topic.definitions.map(definitionId => store.definitions[definitionId]);
            return { ...topic, definitions };
        }

        // get the subject
        if( !store )
            return null;

        const subject = store.subjects[subjectId];
        if (!subject) {
            return null;
        }

        // convert topics list to actual topics
        return { ...subject, topics: subject.topics?.map(getTopicWithDefinitions) };

    }, [store, subjectId]);
}

export default TopicProvider;
